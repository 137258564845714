/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { Auth } from "aws-amplify";


export default function EnableDataPageSuccessDEMO(props) {
  const { overrides, ...rest } = props;
  let navigate = useNavigate();
  const location = useLocation();
  const [localDateTime, setLocalDateTime] = React.useState("N.A.")

  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='dataPagePending'){
      //Did not come from dataPagePending prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  React.useEffect(() => {

    async function getLocalDateTimeString(){
      try{
        const localDate = new Date().toLocaleDateString()
        const localTime = new Date().toLocaleTimeString()
        const localDateTimeString = localDate + " " + localTime
        setLocalDateTime(localDateTimeString)
      }
      catch(err){
        console.log(err.stack)
        throw err
      }
    }

    async function getAuthorizationCodesForGroup(){
      try{
        const jwtData = await Auth.currentSession();
  
        //Get groupName based on org
        const org = jwtData.idToken.payload['custom:organization']
  
        await getLocalDateTimeString()
        //Get 
  
        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;
  
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getAuthorizationCodesForGroup"
        const data = {payloadSub: payloadSub, groupName: org}
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const authorizationCodesList = await response.json()
        // console.log(authorizationCodesList)
        return authorizationCodesList
      }
      catch(err){
        throw err
      }
    }

    async function getDemoUserStatus(){
      try{
        const jwtData = await Auth.currentSession();
  
        //Get groupName based on org
        const org = jwtData.idToken.payload['custom:organization']
  
        //Get token attributes
  
        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;

        //Get Authorizations
        // console.log("Preparing getAuthorizationCodesForGroup")
        const authorizationCodesList = await getAuthorizationCodesForGroup()
        if(!Array.isArray(authorizationCodesList)){
          console.log(authorizationCodesList) //Print error message
          return
        }
        // console.log(authorizationCodesList)
  
        let demoUserStatus = true
        //As long one true demoUser is found, return that status
        for(let i=0;i<authorizationCodesList.length;i++){
          const authorization = authorizationCodesList[i]
          // console.log("authorization: " + authorization)
          const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDemoUserStatusInGroupAuthorizations"
          const data = {authorization: authorization ,payloadSub: payloadSub, groupName: org}
          // console.log(JSON.stringify(data))
          // console.log("Printing url...")
          // console.log(url)
          const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
              "Authorization": "Bearer " + jwtToken,
              "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
          const demoUserResultStr = await response.json()
          // console.log(demoUserResultStr)

          if(!demoUserResultStr.includes("demoUser status is demoUser:")){
            console.log(demoUserResultStr)
          }
          else{
            //Update result by spliting first :
            const strArray = demoUserResultStr.split(":")
            const forIndex = strArray[1].indexOf("for")
            const result = strArray[1].substring(0,forIndex).trim()
            // console.log(demoUserResultStr)
            // console.log(result)
            demoUserStatus = result
          }
        }

        
        // console.log("demoUserStatus: " + demoUserStatus)
        //If demoUser is false, it means user has been upgraded. Therefore, redirect to dashboard
        if(demoUserStatus==='false'){
          navigate('/dashboard',{state: {prevPage: 'dataPageSuccessDemo'}})
          return;
        }

        return demoUserStatus
      }
      catch(err){
        throw err
      }
    }

    getDemoUserStatus()


  }, [])

  return (
    <Flex
      gap="71px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="38px 0px 38px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "EnableDataPageSuccessDEMO")}
      {...rest}
    >
      <Image
        width="270px"
        height="170px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src = "../../eaIcon.png"
        {...getOverrideProps(overrides, "EA LOGO 1")}
      ></Image>
      <View
        width="640px"
        height="140px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 427")}
      >
        <Text
          fontFamily="Inter"
          fontSize="19px"
          fontWeight="700"
          color="rgba(42,63,131,1)"
          lineHeight="22.99431800842285px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Your energy usage data is connected! "
          {...getOverrideProps(
            overrides,
            "Your energy usage data is connected!"
          )}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="19px"
          fontWeight="400"
          color="rgba(42,63,131,1)"
          lineHeight="22.99431800842285px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="50px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Please contact your Energy Audit Representative about enabling your software subscription and getting insights on your energy usage."
          {...getOverrideProps(
            overrides,
            "Please contact your Energy Audit Representative about enabling your software subscription and getting insights on your energy usage."
          )}
        ></Text>
      </View>
      <Button
        width="222px"
        height="unset"
        shrink="0"
        size="default"
        isDisabled={true}
        variation="primary"
        children="Connected!"
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
