/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, View, Text, Button } from "@aws-amplify/ui-react";
import '../App.css';
import '../my-components-css/AuthForm.css';
import { useEffect, useState, useRef } from 'react';
import axios from "axios";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { Auth } from "aws-amplify";

export default function AuthForm(props) {
  const { overrides, ...rest } = props;
  // const [style, setStyle] = useState("iframeContainer")
  const [style, setStyle] = useState("iframeContainer")
  const [messagestyle, setMessageStyle] = useState("iframeContainer2")
  const [buttonStyle, setButtonStyle] = useState("buttonContainer")
  const [buttonStyle2, setButtonStyle2] = useState("buttonContainer")
  const [apiResponseStyle, setApiResponseStyle] = useState("apiresponseContainer")
  const [buttonPressed, setButtonPressed] = useState(false)
  const [meterUid, setMeterUid] = useState("")
  // const [referralCode, setReferralCode] = useState("")
  const referralCode = useRef("")
  const [apiresponse, setApiResponse] = useState("")
  const domain = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DOMAIN_DEV_MODE : process.env.REACT_APP_DOMAIN_PRO_MODE)

  const location = useLocation();
  let navigate = useNavigate();
  //Do protection of page by checking state
  // console.log(location)
  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='selectUtility'){
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  // getting stored values
  const email = localStorage.getItem("email");
  // console.log("Email: " + email)
  const provider = localStorage.getItem("provider");
  // console.log("provider: " + provider)
  
  let iframeUrl = "https://utilityapi.com/authorize/energyaudit"
  if(email!=='' && provider!==''){
    iframeUrl = iframeUrl + "?email=" + email + "&utility=" + provider
  }

  async function goToDashboard(){
    navigate('/dashboard',{state : {prevPage: 'authForm'}})
    return;
  }

  async function goToLoadDataPage(){
    navigate('/dataPage',{state : {prevPage: 'authForm'}})
    return;
  }

  async function activateHistoricalDate(){
    // console.log("Referralcode: " + parseInt(referralCode.current))
    //Utility API
    const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/activateHistoricaldata"
    // console.log("URL: " + url)
    const data = {referral: parseInt(referralCode.current)}
    // console.log(JSON.stringify(data))
    fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        // "Authorization": "Bearer f148daa3f88f472198fd8c17d37501e9",
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        // "Access-Control-Allow-Origin" : "*", 
        // "Accept": "application/json"
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    .then((response) => response.json())
    .then((data) => {
      //  console.log(data);
       //Activate second button 
       setButtonStyle2("buttonContainer2")
       setMeterUid(data.meters[0])
       // Handle data
       setApiResponseStyle("apiresponseContainer2")
      //  setApiResponse(data)
    })
    // if(res.ok){
    //   console.log("Executed function.....")
    //   const result = await res.json()
    //   console.log(result)
    // }
    // else{
    //   console.log("did not execute function")
    // }

    // console.log(JSON.stringify(res.json()))
  }

  async function checkDataStatus(){
    //Utility API
    const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/pollHistoricaldata?meterUid=" + meterUid
    fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        // "Authorization": "Bearer f148daa3f88f472198fd8c17d37501e9",
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        // "Access-Control-Allow-Origin" : "*", 
        // "Accept": "application/json"
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then((response) => response.json())
    .then((data) => {
      //  console.log(data);
       // Handle data
      const response = data.status
      setApiResponse(response)
    })
    // if(res.ok){
    //   console.log("Executed function.....")
    //   const result = await res.json()
    //   console.log(result)
    // }
    // else{
    //   console.log("did not execute function")
    // }

    // console.log(JSON.stringify(res.json()))
  }
  

  useEffect(() => {

    async function registerAuthorizations(){
      try{
        // console.log("registerAuthorizations in progress!")
  
        //Get organization
        const jwtData = await Auth.currentSession();
        const org = jwtData.idToken.payload['custom:organization']
        if(org==="Energy Audit"){
          localStorage.setItem("admin", true)
        }
  
        //Save authorization in dynamodb
        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/registerAuthorizations"
        const data = {authorization: referralCode.current, payloadSub: payloadSub, groupName: org}
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        console.log(result)
      }
      catch(err){
        throw err
      }
    }

    async function addDashboardId(){
      try{
        // console.log("addDashboardId in progress!")
  
        //Get organization
        const jwtData = await Auth.currentSession();
        const org = jwtData.idToken.payload['custom:organization']
        if(org==="Energy Audit"){
          localStorage.setItem("admin", true)
        }
  
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/putDashboardIds"
        // console.log("URL: " + url)
        const data = {organization: org}
        // console.log(JSON.stringify(data))
        fetch(url, {
          method: "POST", 
          mode: "cors", 
          headers: {
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((data) => {
           console.log(data);
        })
        
      }
      catch(err){
  
      }
    }

    // console.log("Rendering...")
    window.addEventListener('message', async (event) => {
      // Do we trust the sender of this message? Always check origin of event!
      if(event.origin !== domain){
        // console.log("Invalid domain")
        return;
      }
      if(event.data.message===undefined){
        return;
      }

      setStyle("iframeContainer2") //Makes UtilityAPI Iframe container disappear
      setMessageStyle("iframeContainer") //Makes message container appear

      await makeMessageContainerDisappearAndIframeContainerAppear()
      // console.log("Referralcode: " + referralCode.current)
      // if(referralCode.current!==""){
      //   return;
      // }
      // console.log("Message from iframe:" + event.data.message)
      if(event.data!==null && event.data.message!==undefined && event.data.message!==null && event.data.message!==''){
        // console.log("Message from ifram2:" + event.data.message)
        referralCode.current = event.data.message
        // var frame = document.getElementById("utility_iframe");
        // console.log(frame)
        // frame.parentNode.removeChild(frame);
        setStyle("iframeContainer2")
        // setButtonStyle("buttonContainer2") //Add activate button - Commented as activation in done in utility API UI
        //Add authorization in dynamoDb
        await registerAuthorizations()
        await addDashboardId()
        // await goToDashboard()
        await goToLoadDataPage()
        // setReferralCode(event.data.message, () => {
        //   console.log("Referralcode2: " + referralCode);})
        
      }

    })

    async function makeMessageContainerDisappearAndIframeContainerAppear(){
      try{
        //This is to ensure the backend UtilityAPI process gets completed before user exits page
        setTimeout(function () {
          setStyle("iframeContainer") //Makes UtilityAPI Iframe container appear
          setMessageStyle("iframeContainer2") //Makes message container disappear
        },10000) //Delay of 10 seconds

      }
      catch(err){
        throw err
      }
    }



    //Clean Up
    return () => window.removeEventListener('message', () => {})
  
}, []); //Empty array => Run only once

  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "AuthForm")}
      {...rest}
    >
      <View className={style}>
      <iframe src={iframeUrl} style={{width: '800px', height: '800px', border:'none'}}></iframe>
      </View>
      <View className={messagestyle}>
      Please wait for 10 seconds for utility API authorization process to be completed! The successful message will be automatically shown
      </View>
      <View className={buttonStyle}>
        <Button
          width="222px"
          height="unset"
          shrink="0"
          size="default"
          isDisabled={buttonPressed}
          variation="primary"
          children="Activate Data>"
          // onClick={() => navigate('/register2')}
          onClick={activateHistoricalDate}
          {...getOverrideProps(overrides, "Button38452720")}
        ></Button>
      </View>
      <View className={buttonStyle2}>
        <Button
          width="222px"
          height="unset"
          shrink="0"
          size="default"
          isDisabled={buttonPressed}
          variation="primary"
          children="Check data status>"
          // onClick={() => navigate('/register2')}
          onClick={checkDataStatus}
          {...getOverrideProps(overrides, "Button38452720")}
        ></Button>
        <Button
          width="222px"
          height="unset"
          shrink="0"
          size="default"
          isDisabled={buttonPressed}
          variation="primary"
          children="Go to dashboard>"
          // onClick={() => navigate('/register2')}
          onClick={goToDashboard}
          {...getOverrideProps(overrides, "Button38452720")}
        ></Button>
      </View>
      <View className={apiResponseStyle}>
        <Text>
          {apiresponse}
        </Text>
      </View>
      
      
    </Flex>
  );
}
