/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import {
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  PasswordField,
  Text,
  TextField,
  View,
  Heading
} from "@aws-amplify/ui-react";
import {Auth} from 'aws-amplify'

export default function Register2(props) {
  const { overrides, ...rest } = props;

  const location = useLocation();
  //Do protection of page by checking state
  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='register' && location.state?.prevPage!=='verifyAccount'){
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }
  

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [email, setEmail] = React.useState(location.state.email)
  const [createPassword, setCreatePassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const organization = React.useRef("")

  React.useEffect(() => {
    // storing email in local storage
    localStorage.setItem("email", email);
  }, [email]);

  const [errors, setErrors] = React.useState({
    firstName : false,
    lastName : false,
    title : false,
    email : false,
    createPassword : false,
    confirmPassword : false,
    noMatchPassword : false
  })

  let navigate = useNavigate();

  const validateInputs = async () => {
    const newErrorsState = {...errors}
    let validInputs = true

    //Validate first name
    if(firstName===''){
      validInputs = false
      newErrorsState.firstName = true
    }
    else{
      newErrorsState.firstName = false
    }
    //Validate last name
    if(lastName===''){
      validInputs = false
      newErrorsState.lastName = true
    }
    else{
      newErrorsState.lastName = false
    }
    //Validate title
    if(title===''){
      validInputs = false
      newErrorsState.title = true
    }
    else{
      newErrorsState.title = false
    }
    //Validate email
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(email==='' || (email!=='' && !email.includes('@')) || (email!=='' && !email.match(validRegex))){
      validInputs = false
      newErrorsState.email = true
    }
    else{
      newErrorsState.email = false
    }
    //Validate createPassword
    if(createPassword===''){
      validInputs = false
      newErrorsState.createPassword = true
    }
    else{
      newErrorsState.createPassword = false
    }
    //Validate confirmPassword
    if(confirmPassword === '' || (confirmPassword!=='' && confirmPassword.length<8)){
      validInputs = false
      newErrorsState.confirmPassword = true
    }
    else{
      newErrorsState.confirmPassword = false
    }

    //Check if passwords match
    if(createPassword!==confirmPassword){
      validInputs = false
      newErrorsState.noMatchPassword = true
    }
    else{
      newErrorsState.noMatchPassword = false
    }

    //Validate form
    if(validInputs){
      await signUpAndGoToNextPage()
      
    }
    else{
      setErrors(newErrorsState)
    }
  }

  async function determineOrganization(){
    try{
      //Determines organization based on email
      let domainName = email
      try {
        //Try first time with domain name including @
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDomainByDomain?domain=" + domainName;
        const response = await fetch(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          }
        })
        // console.log(response)
        const result = await response.json()
        if(result?.organization){
          organization.current = result.organization
        }
        else{
          //Try second time as domainName might not have @
          domainName = email.split("@")[1]

          const url2 = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDomainByDomain?domain=" + domainName;
          const response2 = await fetch(url2, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          }
          })
          // console.log(response)
          const result2 = await response2.json()
          if(result2?.organization){
            organization.current = result2.organization
          }
        }
        
      }
      catch (err) {
        console.log(err)
      }

      return organization.current
    }
    catch(err){
      throw err
    }
  }

  async function signUpAndGoToNextPage() {
    try {
      const name = firstName + " " + lastName
      const password = createPassword
      //Determine organization
      const org = await determineOrganization()
      // console.log("Org: " + org)
      if(org!==""){
        const { user } = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            name: name,
            email: email,
            'custom:title': title,        // optional
            'custom:organization': org,
            'custom:status' : 'Activate'

          },
          autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: true,
          }
        });
        navigate('/verifyAccount', {state: {prevPage:'register2', email: email}});
        return;
      }
    } catch (error) {
      console.log('error signing up:', error);
      if(error.toString().includes("Password")){
        const errorState = {
          firstName : false,
          lastName : false,
          title : false,
          email : false,
          createPassword : true,
          confirmPassword : true,
          noMatchPassword : false
        }
        setErrors(errorState)
      }
      if(error.toString().includes("UsernameExistsException")){
        const errorState = {
          firstName : false,
          lastName : false,
          title : false,
          email : true,
          createPassword : false,
          confirmPassword : false,
          noMatchPassword : false
        }
        setErrors(errorState)
      }
    }
  }

  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Register2")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Logo")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Registration38452930")}
          >
            <Text
              fontSize="26px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Registration"
              {...getOverrideProps(overrides, "Registration38452933")}
            ></Text>
          </Flex>
          <Image
            width="143px"
            height="90px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src = "../../eaIcon.png"
            // {...getOverrideProps(overrides, "EA LOGO 1")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 324")}
        >
          <Flex
            gap="150px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 421")}
          >
            <Icon
              width="34px"
              height="34px"
              viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
              paths={[
                {
                  d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                  stroke: "rgba(61,99,115,1)",
                  fillRule: "nonzero",
                  strokeWidth: 0,
                },
                {
                  d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Ellipse 3")}
            ></Icon>
            <View
              width="34px"
              height="34px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 422")}
            >
              <Heading
                width="100px"
                height="unset"
                position="absolute"
                top="41px"
                left="-9px"
                level="6"
                children="Step 2"
                {...getOverrideProps(overrides, "Heading38662819")}
              ></Heading>
              <Icon
                width="34px"
                height="34px"
                viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
                paths={[
                  {
                    d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                    stroke: "rgba(61,99,115,1)",
                    fillRule: "nonzero",
                    strokeWidth: 0,
                  },
                  {
                    d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                    fill: "rgba(61,99,115,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Ellipse 4")}
              ></Icon>
            </View>
            <Icon
              width="34px"
              height="34px"
              viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
              paths={[
                {
                  d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                  stroke: "rgba(61,99,115,1)",
                  fillRule: "nonzero",
                  strokeWidth: 0,
                },
                {
                  d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Ellipse 2")}
            ></Icon>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <Divider
            width="unset"
            height="34px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452869")}
          ></Divider>
          <Flex
            gap="16px"
            direction="row"
            width="592px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 420")}
          >
            <TextField
              width="unset"
              height="unset"
              placeholder="First Name"
              label="First Name"
              alignItems="flex-start"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              hasError={errors.firstName}
              errorMessage="Please type a valid first name"
              onChange={(e) => {
                setFirstName(e.target.value);
                }}
            ></TextField>
            <TextField
              width="unset"
              height="unset"
              placeholder="Last Name"
              label="Last Name"
              alignItems="flex-start"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              hasError={errors.lastName}
              errorMessage="Please type a valid last name"
              onChange={(e) => {
                setLastName(e.target.value);
                }}
            ></TextField>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            placeholder="Your Title"
            label="Your Title"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={errors.title}
            errorMessage="Please type a valid title"
            onChange={(e) => {
              setTitle(e.target.value);
              }}
            {...getOverrideProps(overrides, "TextField38452934")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            placeholder="Email"
            label="Email"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={true}
            labelHidden={false}
            variation="default"
            hasError={errors.email}
            errorMessage="An account with the given email already exists. Please login instead."
            defaultValue={email}
            onChange={(e) => {
              setEmail(e.target.value);
              }}
            {...getOverrideProps(overrides, "TextField38452873")}
          ></TextField>
          <PasswordField
            width="unset"
            height="unset"
            placeholder="Create Password"
            label="Create Password"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={errors.createPassword || errors.noMatchPassword}
            errorMessage={errors.noMatchPassword ? "Please ensure passwords match" : "Please provide a valid password of at least 8 characters, at least 1 special character, at least 1 uppercase letter and at least 1 lowercase letter"}
            onChange={(e) => {
              setCreatePassword(e.target.value);
              }}
          ></PasswordField>
          <PasswordField
            width="unset"
            height="unset"
            placeholder="Confirm Password"
            label="Confirm Password"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={errors.confirmPassword || errors.noMatchPassword}
            errorMessage={errors.noMatchPassword ? "Please ensure passwords match" : "Please provide a valid password of at least 8 characters, at least 1 special character, at least 1 uppercase letter and at least 1 lowercase letter"}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              }}
          ></PasswordField>
          <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452876")}
          ></Divider>
        </Flex>
        <Flex
          gap="275px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 419")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="< Back"
            onClick={() => navigate('/')}
          ></Button>
          <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Verify Account >"
            onClick={validateInputs}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
