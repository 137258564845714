/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Divider, Flex, Image, Text } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
export default function YourProfile(props) {
  const { overrides, clickEdit, clickResetPassword, ...rest } = props;
  const name = props.name
  const email = props.email
  const [isVisible, setIsVisible] = React.useState(true);
  const [organization,setOrganization] = React.useState("")
  const [positionTitle, setPositionTitle] = React.useState("")

  React.useEffect(() => {
    async function getUserInfo() {
      const user = await Auth.currentAuthenticatedUser();
      let org = ""

      //Organization
      if ('custom:organization' in user.attributes) {
        org = user.attributes['custom:organization']
        if(org==="Energy Audit"){
          localStorage.setItem("admin", true)
        }
        setOrganization(org)
        if(org === "Energy Audit"){
          setIsVisible(true);
        }else{
          setIsVisible(false);
        }
      }

      //Title
      if('custom:title' in user.attributes){
        const positionTitleStr = user.attributes['custom:title']
        setPositionTitle(positionTitleStr)
      }
  }
    getUserInfo()
  }, [isVisible]);


  return (
    <Flex
      gap="16px"
      direction="column"
      width="730px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "YourProfile")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Edit Profile")}
        >
          <Text
            // fontFamily="Inter"
            fontSize="25px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Your Profile"
            {...getOverrideProps(overrides, "Your Profile")}
          ></Text>
          <Flex
            gap="25px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 426")}
          >
            
            <Button
              width="unset"
              height="unset"
              justifyContent="space-between"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Edit Profile"
              onClick={clickEdit}
              {...getOverrideProps(overrides, "Button39363181")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Reset Password"
              onClick={clickResetPassword}
              {...getOverrideProps(overrides, "Button39363140")}
            ></Button>
          </Flex>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider39363127")}
        ></Divider>
        <Flex
          gap="30px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Profile")}
        >
          <Image
            width="96px"
            height="96px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="160px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="../../profileIcon.png"
            {...getOverrideProps(overrides, "image")}
          ></Image>
          <Flex
            gap="20px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 425")}
          >
            <Text
              // fontFamily="Inter"
              fontSize="25px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={name}
              {...getOverrideProps(overrides, "John Doe")}
            ></Text>
            <Text
              // fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={email}
              {...getOverrideProps(overrides, "Email")}
            ></Text>
            <Text
              // fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="461px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={organization}
              {...getOverrideProps(overrides, "Org")}
            ></Text>
            <Text
              // fontFamily="Inter"
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="461px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={positionTitle}
              {...getOverrideProps(overrides, "Title")}
            ></Text>
          </Flex>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider39363138")}
        ></Divider>
      </Flex>
    </Flex>
  );
}
