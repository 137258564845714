/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Divider, Flex, PasswordField, Text, TextField } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
export default function ResetPassword(props) {
  const { overrides, clickResetPassword2,  ...rest } = props;

  const [currentPassword, setCurrentPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [currentPasswordError, setCurrentPasswordError] = React.useState(false)
  const [newPasswordError, setNewPasswordError] = React.useState(false)
  const [matchPasswordsError, setMatchPasswordsError] = React.useState(false)
  const [cognitoError, setCognitoError] = React.useState(false)
  const [cognitoErrorMessage, setCognitoErrorMessage] = React.useState("")

  async function updateUser() {
    // console.log("Printing updateUser in progress...")

    try{
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currentPassword, newPassword)
      return true
    }
    catch(err){
      console.log(err)
      setCognitoError(true)
      setCognitoErrorMessage(err.toString())
      return false
    }
    
  }

  async function validatePasswordInput(){
    let validationStatus = true
    //Validate currentPassword - 
    const specialChar_RE = new RegExp(/[~`!_#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/)
    if(currentPassword==='' || !specialChar_RE.test(currentPassword) || currentPassword.length<8
    || currentPassword.search(/[a-z]/i) < 0 || currentPassword.search(/[0-9]/) < 0){
      validationStatus = false
      setCurrentPasswordError(true)
    }
    else{
      setCurrentPasswordError(false)
    }

    //Validate newPassword
    if(newPassword==="" || !specialChar_RE.test(newPassword) || newPassword.length<8
    || newPassword.search(/[a-z]/i) < 0 || newPassword.search(/[0-9]/) < 0){
      validationStatus = false
      setNewPasswordError(true)

    }
    else{
      setNewPasswordError(false)
    }
    // console.log("matchPasswordsError: " + matchPasswordsError)
    // console.log(currentPasswordError)
    // console.log(cognitoError)
    // console.log(cognitoErrorMessage)
    //Check for matching passwords
    if(currentPassword!==newPassword){
      setMatchPasswordsError(false)
    }
    else{
      validationStatus = false
      setMatchPasswordsError(true)
    }

    if(validationStatus){
      const result = await updateUser()
      if(result){
        await clickResetPassword2()
      }
    }
  }

  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "ResetPassword")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="640px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "ResetPassword39472896")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 24px 24px 24px"
          {...getOverrideProps(overrides, "Content")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Reset Password39472898")}
          >
            <Text
              // fontFamily="Inter"
              fontSize="25px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Reset Password"
              {...getOverrideProps(overrides, "Reset Password39472899")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider39472900")}
          ></Divider>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Forms")}
          >
            <PasswordField
              width="unset"
              height="unset"
              label="Current Password"
              placeholder="Current Password"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              labelHidden={false}
              hasError={currentPasswordError || matchPasswordsError || cognitoError}
              errorMessage={cognitoError ? cognitoErrorMessage
                : matchPasswordsError ? "Please ensure new password is not same as old password" : "Please provide a valid password of at least 8 characters, at least 1 special character, at least 1 uppercase letter and at least 1 lowercase letter"}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                }}
              variation="default"
              {...getOverrideProps(overrides, "TextField39472908")}
            ></PasswordField>
            <PasswordField
              width="unset"
              height="unset"
              label="New Password"
              placeholder="New Password"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              labelHidden={false}
              hasError={newPasswordError || matchPasswordsError || cognitoError}
              errorMessage={cognitoError ? cognitoErrorMessage 
                : matchPasswordsError ? "Please ensure new password is not same as old password" : "Please provide a valid password of at least 8 characters, at least 1 special character, at least 1 uppercase letter and at least 1 lowercase letter"}
              onChange={(e) => {
                setNewPassword(e.target.value);
                }}
              variation="default"
              {...getOverrideProps(overrides, "TextField39472910")}
            ></PasswordField>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider39472911")}
          ></Divider>
          <Flex
            gap="100px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 424")}
          >
            <Button
              width="unset"
              height="unset"
              gap="100px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Reset Password"
              onClick={validatePasswordInput}
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
