/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { useEffect } from 'react';
import {
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  PasswordField,
  Text,
  TextField,
  View,
  Heading,
  Link
} from "@aws-amplify/ui-react";
import {Auth} from 'aws-amplify'


export default function VerifyAccount(props) {
  const { overrides, ...rest } = props;

  const location = useLocation();
  let navigate = useNavigate();
  const [verificationCode, setVerificationCode] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [verificationCodeError,setVerificationCodeError] = React.useState(false)
  const [checkVerificationCodeError,setCheckVerificationCodeError] = React.useState(false)
  const [cognitoErrorMessageStatus, setCognitoErrorMessageStatus] = React.useState(false)
  const [cognitoErrorMessage, setCognitoErrorMessage] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [passwordError, setPasswordError] = React.useState(false)
  const [sendverificationStatus, setSendverificationStatus] = React.useState(false)
  const [passwordForReset, setPasswordForReset] = React.useState(false)
  
  //Text labels and buttons for forgetpassword/registration
  const [verifyLabel, setVerifyLabel] = React.useState("")
  const [backStatusDisabled, setBackStatusDisabled] = React.useState(false)
  const [linkUtilityProviderButton, setLinkUtilityProviderButton] = React.useState(true)
  //Resend verification code
  // const [verification

  //Do protection of page by checking state
  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='register2' && location.state?.prevPage!=='signin'){
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  async function confirmSignUp() {
    try {
      if(verificationCode===''){
        setVerificationCodeError(true)
        return
      }
      setVerificationCodeError(false)
      const email = localStorage.getItem('email')
      // console.log("email: " + email)
      const result = await Auth.confirmSignUp(email, verificationCode);
      // console.log(result)
      if(result!=="SUCCESS"){
        setCheckVerificationCodeError(true)
      }
      else{
        setCheckVerificationCodeError(false)
      }
      // console.log("Navigating to select utility")
      navigate('/selectUtility',{state : {prevPage: 'verifyAccount'}})
      return;
    } catch (error) {
      console.log('error confirming sign up', error);
      setCheckVerificationCodeError(true)
      console.log(error.toString())
      if(error.toString().includes("Current status is CONFIRMED")){
        navigate('/selectUtility',{state : {prevPage: 'verifyAccount'}})
        return;
      }
      setCognitoErrorMessageStatus(true)
      setCognitoErrorMessage(error.toString())
    }
  }

  async function resetPassword() {
    try {
      if(verificationCode===''){
        setVerificationCodeError(true)
        return
      }
      setPasswordError(false)
      setVerificationCodeError(false)
      setCognitoErrorMessageStatus(false)
      //Check password
      // console.log("Checking password...")
      const passwordCondition = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      
      //const specialChar_RE = new RegExp(/[~`!_#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/)

      // console.log("Checking...")
      // console.log(specialChar_RE.test(password))
      // console.log(password.search(/[a-z]/i) < 0)
      // console.log(password.search(/[0-9]/) < 0)
      // console.log(password.length<8)

      // if( specialChar_RE.test(password)){
      //   console.log("Special character test pass!")
      // }
      // else{
      //   console.log("Special character test not passed!")
      // }
      const isValidPassword = passwordCondition.test(password);


      if(!isValidPassword){
        // console.log("bad")
        setPasswordError(true)
        return
      }
      else{
        // console.log("good")
        setPasswordError(false)
      }
      // console.log("Resetting password in process....")
      const result = await Auth.forgotPasswordSubmit(email, verificationCode, password)
      // console.log(result)
      if(result!=="SUCCESS"){
        setCheckVerificationCodeError(true)
      }
      else{
        setCheckVerificationCodeError(false)
      }
      // console.log("Signing in after reset...")
      const user = await Auth.signIn(email, password);
      // console.log(user)
      // console.log("Navigating to dashboard")
      navigate('/dashboard',{state : {prevPage: 'signin'}})
      return;
    } catch (error) {
      console.log('error confirming sign up', error);
      setCheckVerificationCodeError(false)
      console.log(error.toString())
      if(error.toString().includes("User cannot be confirmed. Current status is CONFIRMED")){
        navigate('/dashboard',{state : {prevPage: 'signin'}})
        return;
      }
      setCognitoErrorMessageStatus(true)
      setCognitoErrorMessage(error.toString())
    }
  }

  async function resendVerificationCode(){
    try {
      if(!sendverificationStatus){
        // console.log("resendVerificationCode in progress...")
        //Only resend if verification code has not been resent before to avoid spam
        const email = localStorage.getItem('email')
        await Auth.resendSignUp(email);
        // console.log('code resent successfully');
        setSendverificationStatus(true)
      }
    } catch (err) {
      console.log('error resending code: ', err);
      setCognitoErrorMessageStatus(true)
      setCognitoErrorMessage(err.toString())
    }
  }

  useEffect(() => {
    //Set email
    setEmail(location.state.email)
    // console.log(location.state)
    if(location.state?.prevPage==='signin'){
      //Come from sign in page due to forget password
      const label = "A verification code has been sent to your email - " + email + ". Please enter code to reset password"
      setVerifyLabel(label)
      setBackStatusDisabled(true)
      setLinkUtilityProviderButton(false)
      setPasswordForReset(true)
    }
    else{
      //Came from register 2 page
      const label = "A verification code has been sent to your email - " + email + ". Please enter code to confirm registration."
      setVerifyLabel(label)
    }
  })

  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Register2")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Logo")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Registration38452930")}
          >
            <Text
              fontSize="26px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Registration"
              {...getOverrideProps(overrides, "Registration38452933")}
            ></Text>
          </Flex>
          <Image
            width="143px"
            height="90px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src = "../../eaIcon.png"
            // {...getOverrideProps(overrides, "EA LOGO 1")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 324")}
        >
          <Flex
            gap="150px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 421")}
          >
            <Icon
              width="34px"
              height="34px"
              viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
              paths={[
                {
                  d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                  stroke: "rgba(61,99,115,1)",
                  fillRule: "nonzero",
                  strokeWidth: 0,
                },
                {
                  d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Ellipse 3")}
            ></Icon>
            <View
              width="34px"
              height="34px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 422")}
            >
              <Heading
                width="100px"
                height="unset"
                position="absolute"
                top="41px"
                left="-9px"
                level="6"
                children="Step 2"
                {...getOverrideProps(overrides, "Heading38662819")}
              ></Heading>
              <Icon
                width="34px"
                height="34px"
                viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
                paths={[
                  {
                    d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                    stroke: "rgba(61,99,115,1)",
                    fillRule: "nonzero",
                    strokeWidth: 0,
                  },
                  {
                    d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                    fill: "rgba(61,99,115,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Ellipse 4")}
              ></Icon>
            </View>
            <Icon
              width="34px"
              height="34px"
              viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
              paths={[
                {
                  d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                  stroke: "rgba(61,99,115,1)",
                  fillRule: "nonzero",
                  strokeWidth: 0,
                },
                {
                  d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Ellipse 2")}
            ></Icon>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <Divider
            width="unset"
            height="34px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452869")}
          ></Divider>
          <Flex
            gap="16px"
            direction="row"
            width="592px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 420")}
          >
          </Flex>
          <TextField
            width="unset"
            height="unset"
            placeholder="Please enter verification code"
            label={verifyLabel}
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            size="default"
            labelHidden={false}
            variation="default"
            hasError={verificationCodeError || checkVerificationCodeError || cognitoErrorMessageStatus}
            errorMessage={verificationCodeError && "Please enter verification code" 
            || checkVerificationCodeError && "Incorrect verification code. Please retype code."
            || cognitoErrorMessageStatus && cognitoErrorMessage}
            onChange={(e) => {
              setVerificationCode(e.target.value);
              }}
            {...getOverrideProps(overrides, "TextField38452873")}
          ></TextField>
          {passwordForReset &&
          <PasswordField
            width="unset"
            height="unset"
            placeholder="Please enter new password"
            label="Password"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            size="default"
            labelHidden={false}
            variation="default"
            hasError={passwordError || cognitoErrorMessageStatus}
            errorMessage={cognitoErrorMessageStatus && cognitoErrorMessage
            || passwordError && "Please provide a valid password of at least 8 characters, at least 1 special character, at least 1 uppercase letter and at least 1 lowercase letter"}
            onChange={(e) => {
              setPassword(e.target.value);
              }}
            {...getOverrideProps(overrides, "TextField38452873")}
          ></PasswordField>}
          <Link
            onClick={resendVerificationCode}
            textDecoration="underline"
            fontFamily="Inter"
            fontStyle="italic"
            to="/verifyAccount"
          >
            Resend verification code
          </Link>
          <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452876")}
          ></Divider>
        </Flex>
        <Flex
          gap="275px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 419")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={backStatusDisabled}
            variation="primary"
            children="< Back"
            onClick={() => navigate('/register2', {state: {prevPage: 'verifyAccount'}})}
          ></Button>
          {linkUtilityProviderButton && <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Link Utility Provider >"
            onClick={confirmSignUp}
            
          ></Button>}
          {!linkUtilityProviderButton && <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Reset Password >"
            onClick={resetPassword}
          ></Button>}
        </Flex>
      </Flex>
    </Flex>
  );
}
