/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

export default function EditProfile(props) {
  const { overrides,clickSave, positionTitle, name, ...rest } = props;
  let firstNameDefault = name.split(" ")[0]
  let lastNameDefault = name.split(" ")[1]

  const [firstName, setFirstName] = React.useState(firstNameDefault)
  const [lastName, setLastName] = React.useState(lastNameDefault)
  const [position,setPosition] = React.useState(positionTitle)

  //Errors - Form Validation
  const [firstNameError,setFirstNameError] = React.useState(false)
  const [lastNameError, setLastNameError] = React.useState(false)
  const [roleError, setRoleError] = React.useState(false)
  const [organization, setOrganization] = React.useState("")

  React.useEffect(() => {
    //Perform side effects whenever there are changes in dependecies - To force update just once
    // console.log("USeEffect in progress")
    setFirstName(firstNameDefault)
    setLastName(lastNameDefault)
    setPosition(positionTitle)
    getOrgDetails()
  },[props])

  
  async function processSave(){
    //Validate inputs
    //Check if blank string or contains special characters or numbers
    const specialChar_RE = new RegExp(/[~`!_#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/)
    let processError = false
    // console.log("FirstName: " + firstName)
    //Validate firstName
    if(firstName===undefined || firstName==="" || specialChar_RE.test(firstName) || (firstName!==undefined && firstName.search(/[0-9]/) >= 0)){
      processError = true
      setFirstNameError(state => state = true)
    }
    else{
      setFirstNameError(false)
    }
    // console.log("lastName: " + lastName)
    //Validate lastName
    if(lastName===undefined || lastName==="" || specialChar_RE.test(lastName) || (lastName!==undefined && lastName.search(/[0-9]/) >= 0)){
      processError = true
      setLastNameError(true)
    }
    else{
      setLastNameError(false)
    }
    // console.log("positionTitle: " + position)
    //Validate Role
    if(position===undefined || position===""){
      processError=true
      setRoleError(true)
    }
    else{
      setRoleError(false)
    }
    // console.log("processError: " + processError)
    if(!processError){
      await updateUser()
      await clickSave()
    }
  }

  async function updateUser() {
    // console.log("Printing updateUser in progress...")
    const user = await Auth.currentAuthenticatedUser();
    // console.log(user)
    // console.log(position)
    // console.log("Lastname: " + lastName)
    await Auth.updateUserAttributes(user, {
      'name': firstName + " " + lastName,
      // 'email': email,
      'custom:title': position,  
      // 'custom:organization': organization
    });
    const user2 = await Auth.currentAuthenticatedUser(); //To ensure update to user is complete before refresh
    // console.log("printing user2..")
    // console.log(user2)
  }

  async function getOrgDetails(){
    const user = await Auth.currentAuthenticatedUser();
    let org = ""

    //Organization
    if ('custom:organization' in user.attributes) {
      org = user.attributes['custom:organization']
      if(org==="Energy Audit"){
        localStorage.setItem("admin", true)
      }
      setOrganization(org)
    }
  }


  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "EditProfile")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Edit Profile29766913")}
        >
          {/* <View
            width="24px"
            height="24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon")}
          >
            <Icon
              width="14px"
              height="14px"
              viewBox={{ minX: 0, minY: 0, width: 14, height: 14 }}
              paths={[
                {
                  d: "M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z",
                  fill: "rgba(13,26,38,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="20.83%"
              bottom="20.83%"
              left="20.83%"
              right="20.83%"
              {...getOverrideProps(overrides, "Vector")}
            ></Icon>
          </View> */}
          <Text
            // fontFamily="Inter"
            fontSize="25px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Edit Profile"
            {...getOverrideProps(overrides, "Edit Profile29766916")}
          ></Text>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider29766917")}
        ></Divider>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Profile")}
        >
          <Image
            width="96px"
            height="96px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            borderRadius="160px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="../../profileIcon.png"
            {...getOverrideProps(overrides, "image")}
          ></Image>
          {/* <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(13,26,38,1)"
            lineHeight="22px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            textDecoration="underline"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Upload New Image"
            {...getOverrideProps(overrides, "Upload New Image")}
          ></Text> */}
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <TextField
            width="unset"
            height="unset"
            label="First Name"
            placeholder="First Name"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={firstNameDefault}
            hasError={firstNameError}
            errorMessage="Please provide a valid first name"
            onChange={(e) => {
              setFirstName(firstName => firstName= e.target.value)

            }}
            {...getOverrideProps(overrides, "TextField29766922")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Last Name"
            placeholder="Last Name"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={lastNameDefault}
            hasError={lastNameError}
            errorMessage="Please provide a valid last name"
            onChange={(e) => {
              setLastName(e.target.value)
            }}
            {...getOverrideProps(overrides, "TextField29766922")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Email"
            placeholder="Email"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={true}
            labelHidden={false}
            defaultValue={props.email}
            variation="default"
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            {...getOverrideProps(overrides, "TextField29766923")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Your Organization"
            placeholder="Your Organization"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={true}
            labelHidden={false}
            variation="default"
            defaultValue={organization}
            {...getOverrideProps(overrides, "TextField29766923")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Your Role"
            placeholder="Your Role"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={positionTitle}
            hasError={roleError}
            errorMessage="Please provide a valid role"
            onChange={(e) => {
              setPosition(e.target.value)
            }}
            {...getOverrideProps(overrides, "TextField29766924")}
          ></TextField>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider29766925")}
        ></Divider>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Save"
          onClick={processSave}
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
    </Flex>
  );
}
