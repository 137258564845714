/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import Register from "./Register";
import { Flex, Button,
  Divider,
  Image,
  SelectField,
  TextField, Text, Link } from "@aws-amplify/ui-react";
import {useSearchParams, useNavigate} from "react-router-dom";
export default function SCEScopeConfirmation(props) {
  const { overrides, ...rest } = props;
  //Get url params
  const [searchParams, setSearchParams] = useSearchParams()
  let navigate = useNavigate();
  const params = []

  //Push entries into params list
  for(let entry of searchParams.entries()){
    params.push(entry)
  }


  //Iterate params list to get parameters for redirect url
  let paramsForUrl = ""
  for(let i=0;i<params.length;i++){
    const currArray = params[i]
    if(i===0){
      paramsForUrl += currArray[0] + "=" + currArray[1]
    }
    else{
      paramsForUrl += "&" + currArray[0] + "=" + currArray[1]
    }
  }

  //Add final string to paramsForUrl
  paramsForUrl = process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_SANDBOX_SCE_REDIRECT_URL : process.env.REACT_APP_PRODUCTION_SCE_REDIRECT_URL 
  + paramsForUrl

  console.log(paramsForUrl)

  console.log(params)
  return (
    <Flex
    gap="16px"
    direction="column"
    width="640px"
    height="unset"
    justifyContent="flex-start"
    alignItems="flex-start"
    position="relative"
    padding="100px 0px 0px 0px"
    backgroundColor="rgba(255,255,255,1)"
    {...getOverrideProps(overrides, "Register")}
    {...rest}
  >
    <Flex
      gap="24px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      shrink="0"
      alignSelf="stretch"
      position="relative"
      padding="24px 24px 24px 24px"
      {...getOverrideProps(overrides, "Content")}
    >
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Logo")}
      >
        <Image
          width="287px"
          height="180px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="../../eaIcon.png"
          {...getOverrideProps(overrides, "EA LOGO 1")}
        ></Image>
        <Image
          // width="287px"
          height="100px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="../../SCEImage.png"
          {...getOverrideProps(overrides, "EA LOGO 1")}
        ></Image>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        border="1px SOLID rgba(245,245,245,1)"
        borderRadius="5px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 28")}
      >
        
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Forms")}
      >
        {/* <Divider
          width="unset"
          height="22px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider38452785")}
        ></Divider> */}
        {/* <SelectField
          width="unset"
          height="unset"
          placeholder="Select your organization"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hasError={error}
          errorMessage="Please select an organization"
          onChange={(e) => {
            setOrganization(e.target.value)
          }}
          {...getOverrideProps(overrides, "SelectField")}
        >
          <option value="Goodwill Industries of Ventura and Santa Barbara Counties">Goodwill Industries of Ventura and Santa Barbara Counties</option>
          <option value="Energy Audit">Energy Audit</option>
          <option value="Hygenia">Hygenia</option>
        </SelectField> */}
        <Text>
        Upon clicking the below button, you will be redirected to SCE to confirm your authorization.
        </Text>
        {/* <TextField
          width="unset"
          height="unset"
          label="SCE Account Email"
          placeholder="Please type your SCE email"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          hasError={false}
          errorMessage=""
          // onChange={(e) => {
          //   setOrganization(e.target.value)
          // }}
          {...getOverrideProps(overrides, "TextField38452584")}
        ></TextField> */}
        <Divider
          width="unset"
          height="22px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider38452787")}
        ></Divider>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 419")}
      >
        <Link href={paramsForUrl}>
        <Button
          width="250px"
          height="unset"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="primary"
          children="SCE Scope Confirmation >"
          // onClick={() => }
          {...getOverrideProps(overrides, "Button38452720")}
        ></Button>
        </Link>

      </Flex>
    </Flex>
  </Flex>
  );
}
