/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import AuthForm2 from "./AuthForm2";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { Flex, View, Loader, Divider, Button, Alert } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import Header from "./DashboardBar"
import Footer from "./MarketingFooter"
import SideBar from "./SideBar5";
import YourProfile from "./YourProfile"
import EditProfile from "./EditProfile";
import ResetPassword from "./ResetPassword";
import '../my-components-css/Dashboard.css'
import '../App.css'
import AddDomain from "./AddDomain";
import UserManagement from "./UserManagement";
import AdminOrganization from "./AdminOrganization";

export default function Settings(props) {
  const { overrides, ...rest } = props;
  const location = useLocation();
  const [url, setUrl] = React.useState("")
  const [url2, setUrl2] = React.useState("")
  const [loaderStyle, setLoaderStyle] = React.useState("block")
  const [date, setDate] = React.useState("")
  const [name,setName] = React.useState("")
  const [email,setEmail] = React.useState("")
  //Weather
  const [temperature, setTemperature] = React.useState("")
  const [windSpeed, setWindSpeed] = React.useState("")
  const [precipitation, setPrecipitation] = React.useState("")
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  //Sidebar
  const [sidebarOpen, setSidebarOpen] = React.useState(true)
  //Button statuses
  const [yourProfile, setYourProfile] = React.useState(true)
  
  const [editProfile, setEditProfile] = React.useState(false)
  const [resetPassword, setResetPassword] = React.useState(false)
  const [userProfile, setUserProfile] = React.useState(false)
  //Your Profile Status
  let organizationStr = ""
  if(location.state?.organization !==undefined && location.state?.organization){
    organizationStr = location.state.organization
  }
  const [organization, setOrganization] = React.useState(organizationStr)
  const [positionTitle, setPositionTitle] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  

  let navigate = useNavigate();

  //Do protection of page by checking state
  // console.log("Printing location for dashboard...")
  // console.log(location)
  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='signin' && location.state?.prevPage!=='dashboard' && location.state?.prevPage!=='settings'){
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function logoutProcess(){
    try {
      // console.log("Signing out...")
      await Auth.signOut();
      navigate('/signin',{state: {pressed : false}})
      return;
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async function toggleSideBar(){
    // console.log("Togglesidebar in progress..")
    // console.log("Converting " + sidebarOpen + " to " + !sidebarOpen) 
    setSidebarOpen(prev => !prev)
  }

  async function toggleDashboardOne(){
    //Upon landing in dashboard page, show just dashboard one
    navigate("/dashboard", {state: {prevPage: 'settings', dashboardTwo: false,  dashboardThree:false, organization: organization}})
    return;
  }

  async function toggleDashboardTwo(){
    //Upon landing in dashboard page, show just dashboard two
    navigate("/dashboard", {state: {prevPage: 'settings', dashboardTwo: true ,dashboardOne:false, dashboardThree:false, organization: organization}})
    return;
  }

  async function toggleDashboardThree() {
    //Upon landing in dashboard page, show just dashboard two
    navigate("/dashboard", { state: { prevPage: 'settings', dashboardThree: true,  dashboardOne:false, dashboardTwo:false, organization: organization } })
    return;
  }

  async function goToSettingsPage(){
     navigate('/settings', {state: {prevPage:'dashboard', organization: organization }})
  }

  async function goToReportsPage(){
     navigate('/reports', {state: {prevPage:'dashboard', organization: organization}})
  }

  //Functions to pass to child component to update parent state
  /* async function clickAddDomain(){
    
    setYourProfile(false)
    setAddDomain(true)
    //setUserProfile(true)
  } */

  //Functions to pass to child component to update parent state
  async function clickEdit(){
    
    setYourProfile(false)
    setEditProfile(true)
  }

  /* async function clickSaveDomain(){
    //Retrieve updated user attributes (name, title) from Cognito
    //const user = await Auth.currentAuthenticatedUser();
    setShowAlert(true)
    //setAddDomain(false)
    setYourProfile(true);
   // await window.location.reload(false) //Force refresh to handle edge case of user removing inputs from edit profile page

  }

  async function clickCancel(){
    //setAddDomain(false)
    setYourProfile(true)
  } */

  async function clickSave(){
    //Retrieve updated user attributes (name, title) from Cognito
    const user = await Auth.currentAuthenticatedUser();
    //Update UI properties
    setPositionTitle(user.attributes['custom:title'])
    setName(user.attributes.name)
    //Make YOur profile box visible and rest invisible
    setEditProfile(false)
    setYourProfile(true)
    await window.location.reload(false) //Force refresh to handle edge case of user removing inputs from edit profile page
  }

  async function clickResetPassword(){
    setEditProfile(false)
    setYourProfile(false)
    setResetPassword(true)
  }

  //This function which is passed to child component is for actual reset password 
  async function clickResetPassword2(){
    //Reset password via cognito
    setResetPassword(false)
    setEditProfile(false)
    setYourProfile(true)
    await window.location.reload(false) //Force refresh 
  }

  React.useEffect(() => {
    async function getQuickSightDashboardUrl(){
      try{
        // console.log("Generating equicksight url...")
        const jwtData = await Auth.currentSession();

        //Temporary fix : Check if user is goodwill or energyaudit user. If no, exit function
        const org = jwtData.idToken.payload['custom:organization']
        if(org!=="Energy Audit" && org!=="Goodwill"){
          if(org==="Energy Audit"){
            localStorage.setItem("admin", true)
          }
          setLoaderStyle("none")
          return;
        }

        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;
        
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getEmbedUrl"
        const data = {email: emailSub, payloadSub: payloadSub}
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        // console.log(result)
        // console.log(result.Status)
        if(result.length===2 && result[0].Status===200 && result[1].Status===200){
          // console.log("good status")
          const embedUrl = result[0].EmbedUrl
          const embedUrl2 = result[1].EmbedUrl
          setUrl(embedUrl)
          setUrl2(embedUrl2)
          await sleep(2000)
          setLoaderStyle("none")
        }
        else{
          console.log("Something went wrong with QuickSight Url Retrieval..")
        }
      }
      catch(err){
        console.log(err)
      }
    }

    async function getPosition(options){
      return new Promise((resolve, reject) => 
          navigator.geolocation.getCurrentPosition(resolve, reject, options)
      );
  }

    async function getUserLocation(){
      try{
        if("geolocation" in navigator){
          // console.log("Available")
          const position = await getPosition()
          // console.log(position)
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          setLatitude(latitude => latitude)
          setLongitude(longitude => longitude)
          // console.log("Latitude: " + latitude)
          // console.log("Logitude: " + longitude)
          await getCurrentWeatherInUserLocation(latitude,longitude)

        }
        else{
          // console.log("Not available")
          throw new Error("Location not available")
        }
      }
      catch(err){
        console.log(err)
        //Assume user rejected or browser rejects geolation
        await getCurrentWeatherInUserLocation("","")
      }
    }

    async function getCurrentWeatherInUserLocation(latitudeStr,longitudeStr){
      try{
        const localStorage_temp = localStorage.getItem("temperature")
        const localStorage_windspeed = localStorage.getItem("windspeed")
        const localStorage_precipitation = localStorage.getItem("precipitation")
        if(localStorage_temp===null || localStorage_windspeed===null || localStorage_precipitation===null
          || localStorage_temp==="" || localStorage_windspeed==="" || localStorage_precipitation===""){

            //Default location is los angeles
            const location = "Los Angeles"
            let url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + location
            // console.log("getCurrentWeatherInUserLocation latitude" + latitudeStr)
            //Check if user provided coordinates. If no, use default location
            if(latitudeStr!=="" && longitudeStr!==""){
              url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + latitudeStr + "," + longitudeStr
            }
            // console.log("Url: " + url)
            const response = await fetch(url, {
              headers: {
                "Content-Type": "application/json"
              }
            })
            const result = await response.json()
            let temperature = ""
            let windSpeed = ""
            let precipitation = ""
            if(result!==null && result.current!==undefined){
              //Temperature
              if(result.current.feelslike_f!==undefined){
                temperature = result.current.feelslike_f + "o"
              }
              //Wind speed
              if(result.current.wind_mph!==undefined){
                windSpeed = "SSW " + result.current.wind_mph + "mph"
              }
              //Precipitation
              if(result.current.precip_in!==undefined){
                precipitation = result.current.precip_in + "%"
              }
            }
            setTemperature(temperature)
            setWindSpeed(windSpeed)
            setPrecipitation(precipitation)

        }
        else{
            setTemperature(localStorage_temp)
            setWindSpeed(localStorage_windspeed)
            setPrecipitation(localStorage_precipitation)
        }
        
      }
      catch(err){
        console.log(err)
      }
    }

    async function getTodayDate(){
      try{
        const date = new Date()
        //Get Day
        const daysList = ["Sun.","Mon.","Tue.","Wed.","Thur.","Fri.","Sat."]
        const day = daysList[date.getDay()]
        //Get Month
        const monthsList = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        const month = monthsList[date.getMonth()]
        //Get Date day
        const dateDay = date.getDate()
        //Get Year
        const year = date.getFullYear()
        
        const dateString = day + " " + month + " " + dateDay +", " + year
        setDate(dateString)
      }
      catch(err){
        console.log(err)
      }
    }

    async function getPersonalName(){
      try{
        // const userInfo = await Auth.currentUserInfo()
        // const name = userInfo.attributes.name
        // setName(name)
        // const email = userInfo.attributes.email
        // setEmail(email)

        const user = await Auth.currentAuthenticatedUser();
        // console.log("printing user..")
        // console.log(user)
        const name = user.attributes.name
        setName(name)
        const email = user.attributes.email
        setEmail(email)

      }
      catch(err){
        console.log(err)
      }
    }

    async function getUserInfo() {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user)
      // const name = user.attributes.name
      // const email = user.attributes.email
      let org=""
      if('custom:organization' in user.attributes){
        org = user.attributes['custom:organization']
      }
      const title = user.attributes['custom:title']
      setOrganization(org)
      setPositionTitle(title)
    }

    getTodayDate()
    getPersonalName()
    getUserLocation()
    getUserInfo()
    getQuickSightDashboardUrl()
    // getCurrentWeatherInUserLocation()
  }, []);

  return (
    <Flex
      gap="0"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      // alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
    >
      <Flex direction="row">
        <Flex className={sidebarOpen ? 'sideBarDisplay' : 'sideBarNone'}>
          <SideBar 
          height="992px"
          toggleDashboardOne={toggleDashboardOne}
          toggleDashboardTwo={toggleDashboardTwo}
          toggleDashboardThree={toggleDashboardThree}
          goToSettingsPage={goToSettingsPage}
          goToReportsPage={goToReportsPage}
          organization={organization}
          ></SideBar>
        </Flex>

        <Flex 
        direction="column"
        width="100%"
        padding="20px 20px 20px 20px"
        >
          <Header
          date={date}
          name={name}
          email={email}
          temperature={temperature}
          windSpeed={windSpeed}
          precipitation={precipitation}
          toggleSideBar={toggleSideBar}>
          </Header>
          <Flex
          justifyContent="center"
          direction="column"
          alignItems="center"
          >
           
            <Flex className={yourProfile ? 'boxDisplay' : 'boxNone'}>
              <YourProfile
                clickEdit={clickEdit}
                clickResetPassword={clickResetPassword}
                name={name}
                email={email}
                // organization={organization}
                // positiontitle={positionTitle}
              ></YourProfile>
               {showAlert && (
              <Alert 
              width="unset"
              alignSelf="stretch"
              variation="success" heading="Success"
              isDismissible={true}
              onDismiss={() => setShowAlert(false)}
              >
                Organization Added successful
              </Alert>
            )}
            </Flex>
            {/* <Flex className={addDomain ? 'boxDisplay' : 'boxNone'}>
              <AddDomain
                clickSaveDomain={clickSaveDomain}
                clickCancel={clickCancel}
              ></AddDomain>
            </Flex> */}
            <Flex className={editProfile ? 'boxDisplay' : 'boxNone'}>
              <EditProfile
                clickSave={clickSave}
                name={name}
                email={email}
                organization={organization}
                positionTitle={positionTitle}
              ></EditProfile>
            </Flex>
            <Flex>
              <ResetPassword 
              className={resetPassword ? 'boxDisplay' : 'boxNone'}
              clickResetPassword2={clickResetPassword2}
              >
              </ResetPassword>
            </Flex>

          </Flex>

        </Flex>
      </Flex>
    </Flex>
  );
}
