/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  SearchField,
  SelectField,
  Text, Alert, Table, TableHead, TableRow, TableCell, TableBody
} from "@aws-amplify/ui-react";

import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Header from "./DashboardBar"
import SideBar from "./SideBar5";
import '../my-components-css/Dashboard.css'
import '../App.css'
import EditUserProfile from "./EditUserProfile";
import CreateUserProfile from "./CreateUserProfile";
export default function UserManagement(props) {
  const { overrides, ...rest } = props;
  const location = useLocation();
  const [date, setDate] = React.useState("")
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [url, setUrl] = React.useState("")
  const [url2, setUrl2] = React.useState("")
  //Weather
  const [temperature, setTemperature] = React.useState("")
  const [windSpeed, setWindSpeed] = React.useState("")
  const [precipitation, setPrecipitation] = React.useState("")
  const [positionTitle, setPositionTitle] = React.useState("");
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  //Sidebar
  const [sidebarOpen, setSidebarOpen] = React.useState(true)
  //Button statuses
  let organizationStr = ""
  if (location.state?.organization !== undefined && location.state?.organization) {
    organizationStr = location.state.organization
  }
  const [organization, setOrganization] = React.useState(organizationStr)
  const [showAlert, setShowAlert] = React.useState(false);
  const [showDisableUserAlert, setShowDisableUserAlert] = React.useState(false);
  const [userList, setUserList] = React.useState([])
  const [groupName, setGroupName] = React.useState([])
  const [usersResponse, setUsersResponse] = React.useState([]);

  const [updatedUser, setUpdatedUser] = React.useState(false);

  const [editRowData, setEditRowData] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState('');
  const [adminOrgResponse, setAdminOrgResponse] = React.useState([]);

  const [userListProfile, setUserListProfile] = React.useState(true);
  const [createUserProfile, setCreateUserProfile] = React.useState(false);
  const [editUserProfile, setEditUserProfile] = React.useState(false);
  const [filteredUserList, setFilteredUserList] = React.useState([]);
  const [disableNextButton, setDisableNextButton] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [displayRecordCount, setDisplayRecordCount] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(0);
  const [startIndex, setStartIndex] = React.useState(0);
  const [endIndex, setEndIndex] = React.useState(0);
  const ITEMS_PER_PAGE = 5;
  const [statusMap, setStatusMap] = React.useState({});


  let navigate = useNavigate();

  //Do protection of page by checking state
  // console.log("Printing location for dashboard...")
  // console.log(location)
  if (location?.state !== undefined && location.state?.prevPage !== undefined) {
    if (location.state?.prevPage !== 'signin' && location.state?.prevPage !== 'dashboard' && location.state?.prevPage !== 'settings') {
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else {
    //Undefined state
    return <Navigate replace to="/" />;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function toggleSideBar() {
    // console.log("Togglesidebar in progress..")
    // console.log("Converting " + sidebarOpen + " to " + !sidebarOpen) 
    setSidebarOpen(prev => !prev)
  }

  async function toggleDashboardOne() {
    //Upon landing in dashboard page, show just dashboard one
    navigate("/dashboard", { state: { prevPage: 'settings', dashboardTwo: false, organization: organization } })
    return;
  }

  async function toggleDashboardTwo() {
    //Upon landing in dashboard page, show just dashboard two
    navigate("/dashboard", { state: { prevPage: 'settings', dashboardTwo: true, organization: organization } })
    return;
  }

  async function goToSettingsPage() {
    navigate('/settings', { state: { prevPage: 'dashboard' } })
  }

  function onCreateUser() {
    setEditUserProfile(false);
    setCreateUserProfile(true);
    setUserListProfile(false);
  }

  function onCreateUserProfile() {
    setEditUserProfile(false);
    setCreateUserProfile(false);
    setUserListProfile(true);
  }

  function onEditUserProfile(email) {
    setUpdatedUser(email)
    setShowAlert(true)
    setEditUserProfile(false);
    setCreateUserProfile(false);
    setUserListProfile(true);
  }
  function onClickCancel() {
    setEditUserProfile(false)
    setCreateUserProfile(false);
    setUserListProfile(true);
  }

  function handleUpdateClick(rowData) {
    let status = statusMap[rowData.email];
      if(status === undefined){
        status = getActivateLabel(rowData['custom:status']);
      }else{
        status = statusMap[rowData.email];
      }
      if(status === "Activate"){
       setShowDisableUserAlert(true);
      }else{
        setEditUserProfile(true);
        setCreateUserProfile(false);
        setUserListProfile(false);
        setEditRowData(rowData)
      }
    
  }


  function onCreateCancel() {
    setEditUserProfile(false)
    setCreateUserProfile(false);
    setUserListProfile(true);
  }

  async function handleUserStatusClick(rowData) {
     //"Activate" : "Archive"
    const user = await Auth.currentAuthenticatedUser();
    try {
      const jwtData = await Auth.currentSession();
      const jwtToken = jwtData.idToken.jwtToken;
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/updateUserStatus"
      let status = statusMap[rowData.email];
      if(status === undefined){
        status = getActivateLabel(rowData['custom:status']);
      }else{
        status = statusMap[rowData.email];
      }
      const data = {
        email: rowData.email,
        organization:rowData['custom:organization'],
        status: status,
      }
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      if (response.ok) {
        const result = await response.json();
        console.log('Before setStatusMap' + statusMap);
        setStatusMap((prevStatusMap) => ({
          ...prevStatusMap,
          [rowData.email]: data.status === 'Archive' ? 'Activate' : 'Archive',
          //[rowData.email]: getActivateLabel(rowData['custom:status']),
        }));
        console.log('After setStatusMap' + statusMap);
        
       // console.error('getActivateLabel', getActivateLabel(rowData['custom:status']));
      } else {
        setShowAlert(true);
        console.error('Request failed with status:', response.status);
      }
    }catch (err) {
      console.log(err)
    }
}
const getActivateLabel = (status) => {
  console.log('Status:', status);
  return status === 'Archive' ? 'Activate' : 'Archive';
};
React.useEffect(() => {
  console.log('Updated statusMap:', statusMap);
}, [statusMap]);


  React.useEffect(() => {
    async function getQuickSightDashboardUrl() {
      try {
        // console.log("Generating equicksight url...")
        const jwtData = await Auth.currentSession();

        //Temporary fix : Check if user is goodwill or energyaudit user. If no, exit function
        const org = jwtData.idToken.payload['custom:organization']
        if (org !== "Energy Audit" && org !== "Goodwill") {
          setLoaderStyle("none")
          return;
        }

        if (org === "Energy Audit") {
          localStorage.setItem("admin", true)
        }

        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;

        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getEmbedUrl"
        const data = { email: emailSub, payloadSub: payloadSub }
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        // console.log(result)
        // console.log(result.Status)
        if (result.length === 2 && result[0].Status === 200 && result[1].Status === 200) {
          // console.log("good status")
          const embedUrl = result[0].EmbedUrl
          const embedUrl2 = result[1].EmbedUrl
          setUrl(embedUrl)
          setUrl2(embedUrl2)
          await sleep(2000)
          setLoaderStyle("none")
        }
        else {
          console.log("Something went wrong with QuickSight Url Retrieval..")
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    async function getPosition(options) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
      );
    }

    async function getUserLocation() {
      try {
        if ("geolocation" in navigator) {
          // console.log("Available")
          const position = await getPosition()
          // console.log(position)
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          setLatitude(latitude => latitude)
          setLongitude(longitude => longitude)
          // console.log("Latitude: " + latitude)
          // console.log("Logitude: " + longitude)
          await getCurrentWeatherInUserLocation(latitude, longitude)

        }
        else {
          // console.log("Not available")
          throw new Error("Location not available")
        }
      }
      catch (err) {
        console.log(err)
        //Assume user rejected or browser rejects geolation
        await getCurrentWeatherInUserLocation("", "")
      }
    }

    async function getCurrentWeatherInUserLocation(latitudeStr, longitudeStr) {
      try {
        const localStorage_temp = localStorage.getItem("temperature")
        const localStorage_windspeed = localStorage.getItem("windspeed")
        const localStorage_precipitation = localStorage.getItem("precipitation")
        if (localStorage_temp === null || localStorage_windspeed === null || localStorage_precipitation === null
          || localStorage_temp === "" || localStorage_windspeed === "" || localStorage_precipitation === "") {

          //Default location is los angeles
          const location = "Los Angeles"
          let url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + location
          // console.log("getCurrentWeatherInUserLocation latitude" + latitudeStr)
          //Check if user provided coordinates. If no, use default location
          if (latitudeStr !== "" && longitudeStr !== "") {
            url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + latitudeStr + "," + longitudeStr
          }
          // console.log("Url: " + url)
          const response = await fetch(url, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          const result = await response.json()
          let temperature = ""
          let windSpeed = ""
          let precipitation = ""
          if (result !== null && result.current !== undefined) {
            //Temperature
            if (result.current.feelslike_f !== undefined) {
              temperature = result.current.feelslike_f + "o"
            }
            //Wind speed
            if (result.current.wind_mph !== undefined) {
              windSpeed = "SSW " + result.current.wind_mph + "mph"
            }
            //Precipitation
            if (result.current.precip_in !== undefined) {
              precipitation = result.current.precip_in + "%"
            }
          }
          setTemperature(temperature)
          setWindSpeed(windSpeed)
          setPrecipitation(precipitation)

        }
        else {
          setTemperature(localStorage_temp)
          setWindSpeed(localStorage_windspeed)
          setPrecipitation(localStorage_precipitation)
        }

      }
      catch (err) {
        console.log(err)
      }
    }

    async function getTodayDate() {
      try {
        const date = new Date()
        //Get Day
        const daysList = ["Sun.", "Mon.", "Tue.", "Wed.", "Thur.", "Fri.", "Sat."]
        const day = daysList[date.getDay()]
        //Get Month
        const monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const month = monthsList[date.getMonth()]
        //Get Date day
        const dateDay = date.getDate()
        //Get Year
        const year = date.getFullYear()

        const dateString = day + " " + month + " " + dateDay + ", " + year
        setDate(dateString)
      }
      catch (err) {
        console.log(err)
      }
    }

    async function getPersonalName() {
      try {
        // const userInfo = await Auth.currentUserInfo()
        // const name = userInfo.attributes.name
        // setName(name)
        // const email = userInfo.attributes.email
        // setEmail(email)

        const user = await Auth.currentAuthenticatedUser();
        // console.log("printing user..")
        // console.log(user)
        const name = user.attributes.name
        setName(name)
        const email = user.attributes.email
        setEmail(email)

      }
      catch (err) {
        console.log(err)
      }
    }

    async function getUserInfo() {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user)
      // const name = user.attributes.name
      // const email = user.attributes.email
      let org = ""
      if ('custom:organization' in user.attributes) {
        org = user.attributes['custom:organization']
        if (org === "Energy Audit") {
          localStorage.setItem("admin", true)
        }
      }
      const title = user.attributes['custom:title']
      setOrganization(org)
      setPositionTitle(title)
    }



    async function fetchOrganization() {
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getAllGroups";
      const response = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
      })
      if (response.ok) {
        const result = await response.json();
        setGroupName(result);
      } else {
        setGroupName([]);
        console.error('Request failed with status:', response.status);
      }
    }

    getTodayDate()
    getPersonalName()
    getUserLocation()
    getUserInfo()
    fetchOrganization()
    getUserList()
    // getQuickSightDashboardUrl()
    // getCurrentWeatherInUserLocation()
  }, []);

  async function getUserList(organization) {
    let url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getUserList";
    if (organization !== "" && organization !== undefined && organization !== "all") {
      url = url + "?organization=" + organization
    }
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        "Content-Type": "application/json"
      },
    })
    if (response.ok) {
      const result = await response.json();
      setUserList(result);
    } else {
      setUserList([]);
      console.error('Request failed with status:', response.status);
    }
  }

  async function onChangeOrganization(e) {
    getUserList(e.target.value);
  }

  React.useEffect(() => {
    const filteredList = userList.filter((row) => {
      const name = row.name.toLowerCase();
      const userOrganization = row['custom:organization'].toLowerCase();
      const searchTermLowerCase = searchTerm.toLowerCase();

      return name.includes(searchTermLowerCase) || userOrganization.includes(searchTermLowerCase);
    });

    setFilteredUserList(filteredList);

    const totalItems = filteredList.length;
    setTotalItems(totalItems);
    if (totalItems !== 0) {
      const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
      setTotalPages(totalPages);
      // Correct the current page to be within the valid range
      const validCurrentPage = Math.min(currentPage, totalPages - 1);
      setCurrentPage(validCurrentPage);

      const startIndex = validCurrentPage * ITEMS_PER_PAGE;
      setStartIndex(startIndex);

      const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, totalItems);
      setEndIndex(endIndex);

      setDisplayRecordCount(validCurrentPage + 1);

      if (totalItems === 0) {
        setDisableNextButton(true);
      } else if (totalItems > endIndex) {
        setDisableNextButton(false);
      } else if (totalItems <= endIndex) {
        setDisableNextButton(true);
      }
    }


  }, [userList, searchTerm, organization, currentPage, displayRecordCount, totalItems, totalPages, startIndex, endIndex]);



  const paginatedData = filteredUserList.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };



  return (
    <Flex
      gap="0"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      //  alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
    >
      <Flex direction="row">
        <Flex className={sidebarOpen ? 'sideBarDisplay' : 'sideBarNone'}>
          <SideBar
            height="992px"
            toggleDashboardOne={toggleDashboardOne}
            toggleDashboardTwo={toggleDashboardTwo}
            goToSettingsPage={goToSettingsPage}
            organization={organization}
          ></SideBar>
        </Flex>

        <Flex
          direction="column"
          width="100%"
          padding="20px 20px 20px 20px"
        >
          <Header
            date={date}
            name={name}
            email={email}
            temperature={temperature}
            windSpeed={windSpeed}
            precipitation={precipitation}
            toggleSideBar={toggleSideBar}>
          </Header>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "UserManagement")}
            {...rest}
          >
            <Flex
              gap="0"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "User Management")}
            >
              <Flex className={userListProfile ? 'boxDisplay' : 'boxNone'} >
                <Flex
                  gap="24px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  shrink="0"
                  position="relative"
                  padding="24px 24px 24px 24px"
                  backgroundColor="rgba(255,255,255,1)"
                  {...getOverrideProps(overrides, "Content")}
                >
                  <Flex
                    gap="16px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "User Managment41484121")}
                  >
                    <Text
                      fontSize="25px"
                      fontWeight="700"
                      color="rgba(13,26,38,1)"
                      lineHeight="20px"
                      textAlign="left"
                      display="block"
                      direction="column"
                      justifyContent="unset"
                      width="unset"
                      height="unset"
                      gap="unset"
                      alignItems="unset"
                      shrink="0"
                      position="relative"
                      padding="0px 0px 0px 0px"
                      whiteSpace="pre-wrap"
                      children="User Management "
                      {...getOverrideProps(overrides, "User Managment41484122")}
                    ></Text>
                  </Flex>
                  <Divider
                    width="unset"
                    height="1px"
                    shrink="0"
                    alignSelf="stretch"
                    size="small"
                    orientation="horizontal"
                    {...getOverrideProps(overrides, "Divider41484123")}
                  ></Divider>
                  <Flex
                    gap="40px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-end"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Frame 28")}
                  >
                    <SelectField
                      width="300px"
                      height="unset"
                      label="Organization"
                      shrink="0"
                      // placeholder="Select Organization"
                      size="small"
                      isDisabled={false}
                      labelHidden={false}
                      variation="default"
                      onChange={e => onChangeOrganization(e)}
                      {...getOverrideProps(overrides, "SelectField41484125")}
                    >
                      <option value="all">All</option>
                      {groupName.map((object, index) => (
                        <option key={index} value={object.name}>
                          {object.name}
                        </option>
                      ))}
                    </SelectField>
                    {/* <SelectField
                      width="300px"
                      height="unset"
                      label="Department"
                      shrink="0"
                      placeholder="Select Department"
                      size="small"
                      isDisabled={false}
                      labelHidden={false}
                      variation="default"
                      {...getOverrideProps(overrides, "SelectField41484126")}
                      ></SelectField>*/}
                    <SearchField
                      width="300px"
                      height="unset"
                      placeholder="Search"
                      shrink="0"
                      size="small"
                      isDisabled={false}
                      labelHidden={true}
                      variation="default"
                      onChange={(event) => setSearchTerm(event.target.value)}
                      onClear={() => setSearchTerm('')}
                      {...getOverrideProps(overrides, "SearchField")}
                    ></SearchField>
                    <Button
                      width="unset"
                      height="unset"
                      shrink="0"
                      size="small"
                      isDisabled={false}
                      variation="primary"
                      children="Create User"
                      onClick={onCreateUser}
                      {...getOverrideProps(overrides, "Button")}
                    ></Button>
                  </Flex>
                  <Divider
                    width="unset"
                    height="1px"
                    shrink="0"
                    alignSelf="stretch"
                    size="small"
                    orientation="horizontal"
                    {...getOverrideProps(overrides, "Divider41484128")}
                  ></Divider>
                  {/* <SearchField
                    width="300px"
                    height="unset"
                    placeholder="Search"
                    shrink="0"
                    size="small"
                    isDisabled={false}
                    labelHidden={true}
                    variation="default"
                    onChange={(event) => setSearchTerm(event.target.value)}
                    {...getOverrideProps(overrides, "SearchField")}
                    ></SearchField>*/}
                  {showAlert && (
                    <Alert
                      width="unset"
                      alignSelf="stretch"
                      variation="success" heading="Success"
                      isDismissible={true}
                      onDismiss={() => setShowAlert(false)}
                    >
                      Details for {updatedUser} has been updated
                    </Alert>
                  )}
                  {showDisableUserAlert && (
                    <Alert
                      width="unset"
                      alignSelf="stretch"
                      variation="error" heading="Info"
                      isDismissible={true}
                      onDismiss={() => setShowDisableUserAlert(false)}
                    >
                      Account has been deactivated, No Update allowed. Please contact support
                    </Alert>
                  )}
                  <Flex
                    gap="10px"
                    width="unset"
                    height="300px"
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    position="relative"
                    padding="24px 24px 24px 24px"
                    backgroundColor="rgba(255,255,255,1)"
                    {...getOverrideProps(overrides, "Content")}
                  >

                    <Table title="Table" size="small" style={{ width: '100%' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell as="th" style={{ width: '250px' }}>NAME</TableCell>
                          <TableCell as="th" style={{ width: '250px' }}>ORGANIZATION</TableCell>
                          <TableCell as="th" >ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ width: '250px' }}>{row.name}</TableCell>
                            <TableCell style={{ width: '250px' }}>{row['custom:organization']}</TableCell>
                            <TableCell style={{ display: 'flex', gap: '10px' }}>
                              <Button width="unset"
                                height="unset"
                                justifyContent="flex-start"
                                shrink="0"
                                size="small"
                                isDisabled={false}
                                variation="primary" onClick={() => handleUpdateClick(row)}>Update</Button>
                              <Button width="unset"
                                height="unset"
                                justifyContent="flex-start"
                                shrink="0"
                                size="small"
                                isDisabled={false}
                                variation="secondary" onClick={() => handleUserStatusClick(row)}>
                                  {statusMap[row.email] || getActivateLabel(row['custom:status'])}</Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Flex
                      direction="row"
                      width="unset"
                      height="unset"
                      justifyContent="space-between"
                      alignItems="center"
                      shrink="0"
                      alignSelf="stretch"
                      position="relative"
                      padding="0px 0px 5px 0px"
                      {...getOverrideProps(overrides, "Frame 432")}>
                      <Button
                        width="144px"
                        height="unset"
                        shrink="0"
                        size="small"
                        isDisabled={currentPage === 0}
                        justifyContent="flex-start"
                        paddingLeft="10px"
                        variation="link" onClick={handlePrevPage}>
                        Previous
                      </Button>
                      <span>{`Page ${displayRecordCount} of ${totalPages}`}</span>
                      <Button
                        width="144px"
                        height="unset"
                        shrink="0"
                        size="small"
                        justifyContent="flex-end"
                        paddingRight="10px"
                        isDisabled={disableNextButton}
                        variation="link" onClick={handleNextPage} >
                        Next
                      </Button>
                    </Flex>
                  </Flex>

                </Flex>
              </Flex>
              <Flex className={editUserProfile ? 'boxDisplay' : 'boxNone'} >
                <EditUserProfile
                  editData={editRowData}
                  onEditUserProfile={onEditUserProfile}
                  onClickCancel={onClickCancel}
                ></EditUserProfile>
              </Flex>
              <Flex className={createUserProfile ? 'boxDisplay' : 'boxNone'} >
                <CreateUserProfile
                  onCreateUserProfile={onCreateUserProfile}
                  onCreateCancel={onCreateCancel}
                ></CreateUserProfile>

              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
