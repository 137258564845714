/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import AuthForm2 from "./AuthForm2";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { Flex, View, Loader, Divider, Button } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import Header from "./DashboardBar"
import Footer from "./MarketingFooter"
import SideBar from "./SideBar5";
import '../my-components-css/Dashboard.css'

export default function Dashboard(props) {
  const { overrides, ...rest } = props;
  const location = useLocation();
  const [url, setUrl] = React.useState("")
  const [url2, setUrl2] = React.useState("")
  const [url3, setUrl3] = React.useState("")
  const [url4, setUrl4] = React.useState("https://charts.energyaudit.earth/")
  const [loaderStyle, setLoaderStyle] = React.useState("block")
  const [date, setDate] = React.useState("")
  const [name,setName] = React.useState("")
  const [email,setEmail] = React.useState("")
  // console.log(location.state?.organization)
  const organization = React.useRef(location.state?.organization !==undefined ? location.state?.organization : "")
  //Weather
  const [temperature, setTemperature] = React.useState("")
  const [windSpeed, setWindSpeed] = React.useState("")
  const [precipitation, setPrecipitation] = React.useState("")
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  //Sidebar
  const [sidebarOpen, setSidebarOpen] = React.useState(true)
  //Dashboards
  const [dashboardOne, setDashboardOne] = React.useState(true)
  const [dashboardTwo, setDashboardTwo] = React.useState(false)
  const [dashboardThree, setDashboardThree] = React.useState(false)
  const [dashboardFour, setDashboardFour] = React.useState(false)

  let navigate = useNavigate();

  //Do protection of page by checking state
  // console.log("Printing location for dashboard...")
  // console.log(location)
  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='signin' && location.state?.prevPage!=='settings' && location.state?.prevPage!=='authForm'
    && location.state?.prevPage!=='dataPageSuccessDemo'){
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function logoutProcess(){
    try {
      // console.log("Signing out...")
      await Auth.signOut();
      navigate('/signin',{state: {pressed : false}})
      return;
    } catch (error) {
      // console.log('error signing out: ', error);
    }
  }

  async function toggleSideBar(){
    // console.log("Togglesidebar in progress..")
    // console.log("Converting " + sidebarOpen + " to " + !sidebarOpen) 
    setSidebarOpen(prev => !prev)
  }

  async function toggleDashboardOne(){
    // console.log("toggleDashboardOne in progress")
    //Only change statuses if dashboard one is not shown. If already shown, do not do anything
    if(!dashboardOne){
      console.log("Updating dashboardOne to true and dashboardTwo to false")
      //Update dashboard to On
      setDashboardOne(true)
      //Change dashboardTwo to Off
      setDashboardTwo(false)
      setDashboardThree(false)
      setDashboardFour(false)
    }
  }

  async function toggleDashboardTwo(){
    //Only change statuses if dashboard two is not shown. If already shown, do not do anything
    if(!dashboardTwo){
      console.log("Updating dashboardTwo to true and dashboardOne to false")
      //Update dashboard to On
      setDashboardTwo(true)
      //Change dashboardOne to Off
      setDashboardOne(false)
      setDashboardThree(false)
      setDashboardFour(false)
    }
  }

  async function toggleDashboardThree(){
    //Only change statuses if dashboard two is not shown. If already shown, do not do anything
    if(!dashboardThree){
      console.log("Updating dashboardThree to true and dashboardOne to false")
      //Update dashboard to On
      setDashboardTwo(false)
      //Change dashboardOne to Off
      setDashboardOne(false)
      setDashboardThree(true)
      setDashboardFour(false)
    }
  }

  async function toggleDashboardFour(){
    //Only change statuses if dashboard two is not shown. If already shown, do not do anything
    if(!dashboardFour){
      console.log("Updating dashboardFour to true and dashboardOne to false")
      //Update dashboard to On
      setDashboardTwo(false)
      //Change dashboardOne to Off
      setDashboardOne(false)
      setDashboardThree(false)
      setDashboardFour(true)
      setLoaderStyle("none")
    }
  }

  async function goToSettingsPage(){
    navigate('/settings', {state: {prevPage:'dashboard', organization: organization}})
    return;
  }

  async function goToReportsPage(){
    navigate('/reports', {state: {prevPage:'dashboard', organization: organization}})
    return;
  }

  React.useEffect(() => {
    async function getQuickSightDashboardUrl(){
      try{
        const jwtData = await Auth.currentSession();

        // console.log("JWTDATA... ")
        // console.log(jwtData)

        //Temporary fix : Check if user is goodwill or energyaudit user. If no, exit function
        const org = jwtData.idToken.payload['custom:organization']
        if(org!=="Energy Audit" && org!=="Goodwill"){
          if(org==="Energy Audit"){
            localStorage.setItem("admin", true)
          }
          setLoaderStyle("none")
          return;
        }

        // console.log("org is energy audit or goodwill")
        // console.log(jwtData)

        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;

        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getEmbedUrl"
        const data = {email: emailSub, payloadSub: payloadSub}
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        // console.log(result)
        // console.log(result.Status)
        if(result.length===2 && result[0].Status===200 && result[1].Status===200){
          // console.log("good status")
          const embedUrl = result[0].EmbedUrl
          const embedUrl2 = result[1].EmbedUrl
          setUrl(embedUrl)
          setUrl2(embedUrl2)
          await sleep(2000)
          setLoaderStyle("none")
        }
        else{
          console.log("Something went wrong with QuickSight Url Retrieval..")
        }
      }
      catch(err){
        console.log(err)
      }
    }

    async function getPosition(options){
      return new Promise((resolve, reject) => 
          navigator.geolocation.getCurrentPosition(resolve, reject, options)
      );
    }

    async function getUserLocation(){
      try{
        if("geolocation" in navigator){
          // console.log("Available")
          const position = await getPosition()
          // console.log(position)
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          setLatitude(latitude => latitude)
          setLongitude(longitude => longitude)
          // console.log("Latitude: " + latitude)
          // console.log("Logitude: " + longitude)
          await getCurrentWeatherInUserLocation(latitude,longitude)

        }
        else{
          // console.log("Not available")
          throw new Error("Location not available")
        }
      }
      catch(err){
        console.log(err)
        //Assume user rejected or browser rejects geolation
        await getCurrentWeatherInUserLocation("","")
      }
    }

    async function getCurrentWeatherInUserLocation(latitudeStr,longitudeStr){
      try{
        const localStorage_temp = localStorage.getItem("temperature")
        const localStorage_windspeed = localStorage.getItem("windspeed")
        const localStorage_precipitation = localStorage.getItem("precipitation")
        if(localStorage_temp===null || localStorage_windspeed===null || localStorage_precipitation===null
          || localStorage_temp==="" || localStorage_windspeed==="" || localStorage_precipitation===""){

            //Default location is los angeles
            const location = "Los Angeles"
            let url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + location
            // console.log("getCurrentWeatherInUserLocation latitude" + latitudeStr)
            //Check if user provided coordinates. If no, use default location
            if(latitudeStr!=="" && longitudeStr!==""){
              url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + latitudeStr + "," + longitudeStr
            }
            // console.log("Url: " + url)
            const response = await fetch(url, {
              headers: {
                "Content-Type": "application/json"
              }
            })
            const result = await response.json()
            let temperature = ""
            let windSpeed = ""
            let precipitation = ""
            if(result!==null && result.current!==undefined){
              //Temperature
              if(result.current.feelslike_f!==undefined){
                temperature = result.current.feelslike_f + "o"
              }
              //Wind speed
              if(result.current.wind_mph!==undefined){
                windSpeed = "SSW " + result.current.wind_mph + "mph"
              }
              //Precipitation
              if(result.current.precip_in!==undefined){
                precipitation = result.current.precip_in + "%"
              }
            }
            setTemperature(temperature)
            setWindSpeed(windSpeed)
            setPrecipitation(precipitation)

        }
        else{
            setTemperature(localStorage_temp)
            setWindSpeed(localStorage_windspeed)
            setPrecipitation(localStorage_precipitation)
        }
        
      }
      catch(err){
        console.log(err)
      }
    }

    async function getTodayDate(){
      try{
        const date = new Date()
        //Get Day
        const daysList = ["Sun.","Mon.","Tue.","Wed.","Thur.","Fri.","Sat."]
        const day = daysList[date.getDay()]
        //Get Month
        const monthsList = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        const month = monthsList[date.getMonth()]
        //Get Date day
        const dateDay = date.getDate()
        //Get Year
        const year = date.getFullYear()
        
        const dateString = day + " " + month + " " + dateDay + ", " + year
        setDate(dateString)
      }
      catch(err){
        console.log(err)
      }
    }

    async function getPersonalName(){
      try{
        // console.log("getPersonalName in progress..")
        const userInfo = await Auth.currentUserInfo()
        // console.log(userInfo)
        const name = userInfo.attributes.name
        setName(name)
        const email = userInfo.attributes.email
        setEmail(email)
        //Determine organization
        const org = userInfo.attributes['custom:organization']
        if(org==="Energy Audit"){
          localStorage.setItem("admin", true)
        }
        // console.log("Printing org: " + org)
        organization.current = org
      }
      catch(err){
        console.log(err)
      }
    }

    async function checkIfUserCameFromSettingsPage(){
      //Check if from settings page
      if(location?.state !==undefined && location.state?.prevPage==='settings'){
        //Check if user clicked on dashboard two
        if(location.state?.dashboardTwo !==undefined && location.state?.dashboardTwo===true){
          setDashboardTwo(true)
          setDashboardOne(false)
          setDashboardThree(false)
          setDashboardFour(false)
        }else if(location.state?.dashboardThree !==undefined && location.state?.dashboardThree===true){
          setDashboardTwo(false)
          setDashboardOne(false)
          setDashboardThree(true)
          setDashboardFour(false)
        }
        else if(location.state?.dashboardFour !==undefined && location.state?.dashboardFour===true){
          setDashboardTwo(false)
          setDashboardOne(false)
          setDashboardThree(false)
          setDashboardFour(true)
        }
      } 
    }

    async function getDashboardIdsFromDb(org){
      try{
        console.log('getDashboardIdsFromDb in progress!')

        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : 
        process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDashboardIdsFromDynamodb?organization=" + org

        // console.log(url)
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY
          }
        })
        const result = await response.json()
        return result
      }
      catch(err){
        console.log(err)
      }
    }

    async function getQuickSightDashboardAnonymousUrl(){
      try{
        console.log("Generating getQuickSightDashboardAnonymousUrl...")
        const jwtData = await Auth.currentSession();

        let orgStr = jwtData.idToken.payload['custom:organization']
        if(organization.current===''){
          organization.current = jwtData.idToken.payload['custom:organization']
        }

        //Temporary fix : Check if user is goodwill or energyaudit user. If no, exit function
        // if(org!=="Energy Audit" && org!=="Goodwill"){
        //   setLoaderStyle("none")
        //   return;
        // }

        if(organization.current===""){
          console.log("organization is empty!")
          return
        }

        //Get dashboard ids
        //Dashboard types
        //Admin - Energy Audit
        //Custom - Goodwill
        //Default - Calnrg
        // if(organization.current==="Energy Audit" || organization.current==="Goodwill"){
        //   orgStr = organization.current //Keep it same
        // }
        // else{
        //   orgStr = "Calnrg" //Use calnrg for all other organizations
        // }
        // console.log(orgStr)
        const dashboardIdsArray = await getDashboardIdsFromDb(orgStr)
        if(dashboardIdsArray===undefined || dashboardIdsArray===null){
          console.log("Organization is not found in dynamodb as dashboardIdsArray is null or undefined!")
          return;
        }
        if(dashboardIdsArray.length!==1){
          if(dashboardIdsArray.length===0){
            console.log("Organization is not found in dynamodb")
          }
          else{
            console.log("Organization has more than 1 entry in dynamodb. Therefore, invalid!")
          }
          return;
        }
        // console.log("dashboardIdsArray done!")


        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;
        
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getEmbedUrlAnonymous"

        const data = {email: emailSub, payloadSub: payloadSub, organization: organization.current ,dashboardIdsArray: dashboardIdsArray }
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        //Check if 2 dashboards are in result
        // if(!result?.dashboard1 && !result?.dashboard2){
        //   console.log("Something went wrong with QuickSight Url Retrieval..")
        // }
        //Only add dashboard3 if it is added
        // if(!result?.dashboard1 && !result?.dashboard2 && !result?.dashboard3){
        //   console.log("Something went wrong with QuickSight Url Retrieval..")
        // }

        // console.log(result)

        //Get urls for each dashboard


        //Dashboard1
        if(result?.dashboard1 && result.dashboard1.Status===200){
          console.log('dashboard1 found!')
          const embedUrl = result.dashboard1.EmbedUrl
          setUrl(embedUrl)
        }
        //Dashboard2
        if(result?.dashboard2 && result.dashboard2.Status===200){
          console.log('dashboard2 found!')
          const embedUrl = result.dashboard2.EmbedUrl
          setUrl2(embedUrl)
        }
        //Dashboard3
        if(result?.dashboard3 && result.dashboard3.Status===200){
          console.log('dashboard3 found!')
          const embedUrl = result.dashboard3.EmbedUrl
          setUrl3(embedUrl)
        }
        await sleep(2000)
        setLoaderStyle("none")

        // console.log(result)

      }
      catch(err){
        console.log(err)
      }
    }

    checkIfUserCameFromSettingsPage()
    getTodayDate()
    getPersonalName()
    getUserLocation()
    // getQuickSightDashboardUrl()
    getQuickSightDashboardAnonymousUrl()
    // getCurrentWeatherInUserLocation()
  }, []);

  return (
    <Flex
      gap="0"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      // alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      // {...rest}
    >
      <Flex direction="row">
        <Flex className={sidebarOpen ? 'sideBarDisplay' : 'sideBarNone'}>
        <SideBar 
        toggleDashboardOne={toggleDashboardOne}
        toggleDashboardTwo={toggleDashboardTwo}
        toggleDashboardThree={toggleDashboardThree}
        toggleDashboardFour={toggleDashboardFour}
        goToSettingsPage={goToSettingsPage}
        goToReportsPage={goToReportsPage}
        organization={organization.current}
         ></SideBar>
        </Flex>

        <Flex 
        direction="column"
        width="100%"
        padding="20px 20px 20px 20px"
        >
        <Header
        date={date}
        name={name}
        email={email}
        temperature={temperature}
        windSpeed={windSpeed}
        precipitation={precipitation}
        toggleSideBar={toggleSideBar}
      ></Header>
        <Loader 
        size="large"
        variation="linear"
        filledColor="green"
        display={loaderStyle}
        // padding="20px 50px 20px 50px"
        padding="20px"
      />
      <View className={dashboardOne ? 'dashboardDisplay':'dashboardNone'}>
      <iframe src={url} style={{width: '100%', height: '810px', padding:'20px', border:'none'}}></iframe>
      </View>
      <View className={dashboardTwo ? 'dashboardDisplay':'dashboardNone'}>
      <iframe src={url2} style={{width: '100%', height: '810px', padding:'20px', border:'none'}}></iframe>
      </View>
      <View className={dashboardThree ? 'dashboardDisplay':'dashboardNone'}>
      <iframe src={url3} style={{width: '100%', height: '810px', padding:'20px', border:'none'}}></iframe>
      </View>
      <View className={dashboardFour ? 'dashboardDisplay':'dashboardNone'}>
      <iframe src={url4} style={{width: '100%', height: '810px', padding:'20px', border:'none'}}></iframe>
      </View>
      <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 419")}
        >
          {/* <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452876")}
          ></Divider>
          <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Logout"
            backgroundColor="#3F7D4A"
            margin="0px 0px 20px 0px"
            onClick={logoutProcess}
            {...getOverrideProps(overrides, "Button38452586")}
          ></Button> */}
            {/* <Loader 
            size="large"
            variation="linear"
            filledColor="green"
            display={loaderStyle}
            /> */}
        </Flex>
        </Flex>
      </Flex>
      
        {/* <Footer
        alignItems="center"
        backgroundColor="black"
        width="100vx">

        </Footer> */}
    </Flex>
  );
}
