/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { Auth } from "aws-amplify";

export default function EnableDataPagePENDING(props) {
  const { overrides, ...rest } = props;
  let navigate = useNavigate();
  const location = useLocation();
  const [importedDataStatus, setImportedDataStatus] = React.useState(false)
  const [localDateTime, setLocalDateTime] = React.useState("N.A.")

  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='dataPage' && location.state?.prevPage!=='signin'){
      //Did not come from dataPage or signin prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  async function getLocalDateTimeString(){
    try{
      const localDate = new Date().toLocaleDateString()
      const localTime = new Date().toLocaleTimeString()
      const localDateTimeString = localDate + " " + localTime
      setLocalDateTime(localDateTimeString)
    }
    catch(err){
      console.log(err.stack)
      throw err
    }
  }

  async function checkIfDataIsActivated(){
    try{

      // console.log('checkIfDataIsActivated in progress!')
      const jwtData = await Auth.currentSession();

      // console.log('Auth checked!')
      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']

      await getLocalDateTimeString()

      //Get 

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfUtilityDataIsActivated"
      
      //Get list of authorization codes for groupName from dynamodb
      const authorizationCodesList = await getAuthorizationCodesForGroup()
      let activatedStatus = false
      
      //Iterate through each authCode to check if data is activated
      if(authorizationCodesList!==undefined && authorizationCodesList!==null){
        //Check if there is failed response
        if(authorizationCodesList.includes("Failed")){
          return;
        }
        for(let i=0;i<authorizationCodesList.length;i++){
          const authorization = authorizationCodesList[i]
          const data = {authorization: authorization, payloadSub: payloadSub, groupName: org}
          const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
              "Authorization": "Bearer " + jwtToken,
              "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
          const result = await response.json()
          
          //Validate if result contains success. If yes, activatedStatus is true
          if(result.includes("Success")){
            activatedStatus = true
          }
        }
        return activatedStatus
      }

      
      // console.log('checkIfDataIsActivated is completed.')
      return;
      
    }
    catch(err){
      throw err
    }
  }

  async function getAuthorizationCodesForGroup(){
    try{
      const jwtData = await Auth.currentSession();

      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']

      //Get 

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getAuthorizationCodesForGroup"
      const data = {payloadSub: payloadSub, groupName: org}
      // console.log(JSON.stringify(data))
      // console.log("Printing url...")
      // console.log(url)
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      const authorizationCodesList = await response.json()
      // console.log(authorizationCodesList)
      return authorizationCodesList
    }
    catch(err){
      throw err
    }
  }

  async function checkIfDataIsImportedButton(){
    try{
      const jwtData = await Auth.currentSession();

      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']

      //Get 

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      //Check if activated first - Also to make sure meterlist is updated
      const activatedStatus = await checkIfDataIsActivated()

      if(!activatedStatus){
        //If not activated, exit
        console.log("Utility data has not been activated. Therefore, cannot activate")
        return false
      }

      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfDataIsImported"
      
      //Get list of authorization codes for groupName from dynamodb
      const authorizationCodesList = await getAuthorizationCodesForGroup()

      let importedStatus = false

      //Check if authorizationCodeList map is empty. If yes, exit too.
      if(authorizationCodesList.length===0){
        console.log("MeterId list is empty! Therefore, not able to import data.")
        return false;
      }

      //Iterate through each authCode to check if data is activated
      for(let i=0;i<authorizationCodesList.length;i++){
        const authorization = authorizationCodesList[i]
        const data = {authorization: authorization, payloadSub: payloadSub, groupName: org}
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        // console.log(result)
        
        //Validate if result contains success. If yes, importedStatus is true
        if(result.includes("Success")){
          importedStatus = true
        }
      }

      if(importedStatus){
        // setImportedDataStatus(importedStatus)
        navigate('/dataPageSuccessDemo',{state: {prevPage: 'dataPagePending'}})
        return;
      }
      return importedStatus
      
    }
    catch(err){
      throw err
    }
  }

  React.useEffect(() => {
    async function checkIfDataIsImported(){
      try{
        const jwtData = await Auth.currentSession();
  
        //Get groupName based on org
        const org = jwtData.idToken.payload['custom:organization']

        //Get user cognito attributes
        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;

        //Check if activated first - Also to make sure meterlist is updated
        const activatedStatus = await checkIfDataIsActivated()

        if(!activatedStatus){
          //If not activated, exit
          console.log("Utility data has not been activated. Therefore, cannot activate")
          return false
        }

  
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfDataIsImported"
        
        let importedStatus = false

        const data = {payloadSub: payloadSub, groupName: org}
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        // console.log(result)
        
        //Validate if result contains success. If yes, importedStatus is true. As long one authorization has one imported status as true, consider it true.
        if(result.includes("Success")){
          importedStatus = true
        }
  
        if(importedStatus){
          // setImportedDataStatus(importedStatus)
          navigate('/dataPageSuccessDemo',{state: {prevPage: 'dataPagePending'}})
          return;
        }
        return importedStatus
        
      }
      catch(err){
        throw err
      }
    }

    checkIfDataIsImported()
  }, [])

  return (
    <Flex
      gap="71px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="38px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "EnableDataPagePENDING")}
      {...rest}
    >
      <Image
        width="270px"
        height="170px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src = "../../eaIcon.png"
        {...getOverrideProps(overrides, "EA LOGO 1")}
      ></Image>
      <View
        width="640px"
        height="140px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 427")}
      >
        <Text
          fontFamily="Inter"
          fontSize="19px"
          fontWeight="700"
          color="rgba(42,63,131,1)"
          lineHeight="22.99431800842285px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Your data connection is pending."
          {...getOverrideProps(overrides, "Your data connection is pending.")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="19px"
          fontWeight="400"
          color="rgba(42,63,131,1)"
          lineHeight="22.99431800842285px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="50px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="This process may take up to 24 hours. If your energy data is not connected within 24 hours, please contact your Energy Audit Representative. "
          {...getOverrideProps(
            overrides,
            "This process may take up to 24 hours. If your energy data is not connected within 24 hours, please contact your Energy Audit Representative."
          )}
        ></Text>
      </View>
      <View
        width="640px"
        height="134px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 428")}
      >
        <Button
          width="222px"
          height="unset"
          position="absolute"
          top="0px"
          left="209px"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Check data status"
          onClick={() => checkIfDataIsImportedButton()}
          {...getOverrideProps(overrides, "Button")}
        ></Button>
        <Text
          fontFamily="Inter"
          fontSize="10px"
          fontWeight="400"
          color="rgba(42,63,131,1)"
          lineHeight="12.102272033691406px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="50px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={"Last check: " + localDateTime}
          // {...getOverrideProps(overrides, "Last check: Jun 23, 2023 11:38 am")}
        ></Text>
      </View>
    </Flex>
  );
}
