// import logo from './logo.svg';
import './App.css';
import SignIn from './my-components/SignIn'
import ContactUs from './ui-components/ContactUs'
import Register from './my-components/Register'
import Register2 from './my-components/Register2'
import SelectUtility from './my-components/Register3'
import AuthForm from './my-components/AuthForm';
import AuthForm2 from './my-components/AuthForm2';
import Dashboard from './my-components/Dashboard';
import VerifyAccount from './my-components/VerfiyAccount'
import Settings from './my-components/Settings'
import DataPage from './my-components/EnableDataPage'
import DataPagePending from './my-components/EnableDataPagePENDING'
import DataPageSuccessDemo from './my-components/EnableDataPageSuccessDEMO'
import SCEScopeConfirmation from './my-components/SCEScopeConfirmation'
import RedirectPage from './my-components/RedirectPage'
import SCEAuthorizePage from './my-components/SceAuthorizePage'

// import Header from "./my-components/NavBar"
import Footer from "./my-components/MarketingFooter"
import React, { useState, useEffect } from 'react'
import { Amplify, Auth } from 'aws-amplify';


import {
  BrowserRouter as Router,
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";
import AdminOrganization from './my-components/AdminOrganization';
import UserManagement from './my-components/UserManagement';
import SignUp from './my-components/SignUp';
import Reports from './my-components/Reports';

function App() {

  return (
    <div className="App">
      <Router>
        {/* <Header></Header> */}
        <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <div className="container">
        <Routes>
            <Route path="/" element={<Register />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/register2" element={<Register2 />} />
            <Route path="/verifyAccount" element={<VerifyAccount />}/>
            <Route path="/selectUtility" element={<SelectUtility />} />
            <Route path="/authForm" element={<AuthForm />} />
            <Route path="/authForm2" element={<AuthForm2 />} />
            <Route path="/settings" element={<Settings />}/>
            <Route path="/dataPage" element={<DataPage />}/>
            <Route path="/dataPagePending" element={<DataPagePending />}/>
            <Route path="/dataPageSuccessDemo" element={<DataPageSuccessDemo />}/>
            <Route path="/orgManagement" element={<AdminOrganization />}/>
            <Route path="/userManagement" element={<UserManagement />}/>
            <Route path="/signUp" element={<SignUp />}/>
            <Route path="/sceAuthorize" element={<SCEAuthorizePage/>}/>
            <Route path="/sceConfirm" element={<SCEScopeConfirmation />}/>
            <Route path="/reports" element={<Reports />}/>
            <Route path="/redirectPage" element={<RedirectPage />}/>
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        </Routes>
        </div>
        {/* <Footer></Footer> */}
      </Router>
    </div>
  );
}

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_COGNITO_SANDBOX_USERPOOL_ID : process.env.REACT_APP_COGNITO_USERPOOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_COGNITO_SANDBOX_USERPOOL_WEBCLIENT_ID : process.env.REACT_APP_COGNITO_USERPOOL_WEBCLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: 'link', // 'code' | 'link'

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: '.yourdomain.com',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'strict' | 'lax',
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: {myCustomKey: 'myCustomValue'},

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //   domain: 'your_cognito_domain',
    //   scope: [
    //     'phone',
    //     'email',
    //     'profile',
    //     'openid',
    //     'aws.cognito.signin.user.admin',
    //   ],
    //   redirectSignIn: 'http://localhost:3000/',
    //   redirectSignOut: 'http://localhost:3000/',
    //   responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },
  },
});


// You can get the current config object
const currentConfig = Auth.configure();

export default App;
