/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Divider, Flex, Text, TextField, SwitchField, Alert, View, Radio, SelectField, Image } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
export default function AddDomain(props) {
  const { overrides, clickCancel, clickSaveDomain, ...rest } = props;

  const [domain, setDomain] = React.useState('')
  const domainSubmission = React.useRef("")
  const [organization, setOrganization] = React.useState('')
  const [companyContact, setCompanyContact] = React.useState('')
  const [domainError, setDomainError] = React.useState("")
  const [organizationError, setOrganizationError] = React.useState(false)
  const [companyContactError, setCompanyContactError] = React.useState(false)
  const [emailChecked, setEmailChecked] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const [dataActivated, setDataActivated] = React.useState(false);
  const [dataImported, setDataImported] = React.useState(false);
  const [switchDataActivate, setSwitchDataActivate] = React.useState("Inactive");
  const [switchDataImported, setSwitchDataImported] = React.useState("Inactive");

  const [selectedState, setSelectedState] = React.useState('');


  const [accountStatus, setAccountStatus] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [city, setCity] = React.useState('');
  // const [state, setState] = React.useState('');
  const [zipcode, setZipcode] = React.useState('');

  const stateList = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];


  /* React.useEffect(() => {
     //Perform side effects whenever there are changes in dependecies - To force update just once
     setDomain(domain)
     setOrganization(organization)
     setCompanyContact(companyContact)
   }, [props])*/


  async function processSave() {
    //Validate inputs
    //Check if blank string or contains special characters or numbers
    // const specialChar_RE = new RegExp(/[~`!_#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/)
    let processError = false
    if (organization === undefined || organization === "") {
      processError = true
      setOrganizationError("Please provide organization name")
    }
    else {
      setOrganizationError(false)
    }
    const mailValidRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;
    const domainValidRegex = /^[A-Z0-9.-]+\.[A-Z]{2,6}$/i;

    if (emailChecked) {
      if (domain === '' || (!domain.match(mailValidRegex))) {
        processError = true
        setDomainError("Please type a valid email")
      }
      else {
        domainSubmission.current = domain
        setDomainError(false)
        setShowAlert(false);
      }
    } else {
      if (domain === '' || (!domain.match(domainValidRegex))) {
        //Check if domain contains @
        const atIndex = domain.indexOf("@")
        if (atIndex > 0) {
          //Update domain with second half of split
          domainSubmission.current = domain.split("@")[1]
          const invalidDomains = ["gmail.com", "yahoo.com", "outlook.com", "sbcglobal.net"];
          const invalidDomainRE = new RegExp('(' + invalidDomains.join('|') + ')$', 'i');

          if (invalidDomainRE.test(domainSubmission.current)) {
            setDomainError("You cannot whitelist entire domains such as gmail, yahoo, outlook. Select 'full email' instead to whitelist email address");
            processError = true
          } else {
            setDomainError(false);
            domainSubmission.current = domainSubmission.current
          }
        }
        else {
          processError = true
          setDomainError("Please type a valid domain")
        }
      } else {
        const invalidDomains = ["gmail.com", "yahoo.com", "outlook.com", "sbcglobal.net"];
        const invalidDomainRE = new RegExp('(' + invalidDomains.join('|') + ')$', 'i');

        if (invalidDomainRE.test(domain)) {
          setDomainError("You cannot whitelist entire domains such as gmail, yahoo, outlook. Select 'full email' instead to whitelist email address");
          processError = true
        } else {
          setDomainError(false);
          domainSubmission.current = domain
        }
      }

      if (companyContact === undefined || companyContact === "") {
        processError = true
        setCompanyContactError("Please provide company contact")
      }
      else {
        setCompanyContactError(false)
      }
    }
    if (!processError) {
      const user = await Auth.currentAuthenticatedUser();
      try {
        const jwtData = await Auth.currentSession();
        const jwtToken = jwtData.idToken.jwtToken;
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/addDomain"
        const data = {
          organization: convertTextCamelCase(organization),
          domain: domainSubmission.current,
          companyContact: convertTextCamelCase(companyContact),
          changedBy: user.attributes.name,
          lastModifyDate: new Date().toISOString(),
          // accountStatus: accountStatus,
          // dataActivated: dataActivated,
          //  dataImported: dataImported,
          address1: address1,
          address2: address2,
          city: city,
          state: selectedState,
          zipcode: zipcode,
        }
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        if (response.ok) {
          const result = await response.json()
          clickSaveDomain()
          setDomain("")
          domainSubmission.current = ""
          setOrganization("")
          setCompanyContact("")
          setEmailChecked(false)
        } else {
          setShowAlert(true);
        }

      }
      catch (err) {
        console.log(err)
      }
    }
  }

  function convertTextCamelCase(string) {
    const arr = string.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  }

  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "AddDomain")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Add Organization40992892")}
        >
          <Text
            fontSize="25px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Add Organization"
            {...getOverrideProps(overrides, "Add Organization40992893")}
          ></Text>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider")}
        ></Divider>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <TextField
            width="unset"
            height="unset"
            label="Organization"
            placeholder="Organization Name"
            shrink="0"
            alignSelf="stretch"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={organizationError}
            errorMessage={organizationError}
            onChange={(e) => {
              setOrganization(e.target.value);
            }}
            value={organization}
            {...getOverrideProps(overrides, "TextField40772975")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Organization Contact Email"
            placeholder="Contact Email (e.g. name@organization.com)"
            shrink="0"
            alignSelf="stretch"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={domainError}
            errorMessage={domainError}
            onChange={(e) => {
              setDomain(e.target.value);
            }}
            value={domain}
            {...getOverrideProps(overrides, "TextField40772976")}
          ></TextField>
          <SwitchField
            width="unset"
            height="unset"
            label="Use Full Email"
            shrink="0"
            size="small"
            variation="default"
            isDisabled={false}
            labelPosition="start"
            checked={emailChecked}
            onChange={(e) => {
              setEmailChecked(e.target.checked)
            }}
            {...getOverrideProps(overrides, "SwitchField")}
          ></SwitchField>
          <TextField
            width="unset"
            height="unset"
            label="Organization Contact Name"
            placeholder="Contact Name"
            shrink="0"
            alignSelf="stretch"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={companyContactError}
            errorMessage={companyContactError}
            onChange={(e) => {
              setCompanyContact(e.target.value);
            }}
            value={companyContact}
            {...getOverrideProps(overrides, "TextField40992896")}
          ></TextField>
          {showAlert && (
            <Alert
              width="unset"
              alignSelf="stretch"
              variation="error" heading="Error"
              onDismiss={() => setShowAlert(false)}
              isDismissible={true}
            >
              Add Organization unsuccessful
            </Alert>
          )}
        </Flex>
        {/*  <Flex
          gap="5px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px"
          {...getOverrideProps(overrides, "Frame 43343741913")}
        >
          <Text

            fontSize="20px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Account & Data Status"
            {...getOverrideProps(overrides, "Account & Data Status")}
          ></Text>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 0px 10px 0px"
            {...getOverrideProps(overrides, "Frame 43343741915")}
          >
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="180px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Account Status "
              {...getOverrideProps(overrides, "Account Status")}
            ></Text>
            <View
              width="70px"
              height="21px"
              display="block"
              gap="10px"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 438")}
            >
              <Radio
                width="unset"
                height="unset"
                position="absolute"
                top="0px"
                left="0px"
                size="small"
                isDisabled={false}
                labelPosition="start"
                children="Demo"
                value="Demo"
                checked={accountStatus === 'Demo'}
                onChange={(e) => {
                  setAccountStatus(event.target.value)
                }
                }
                {...getOverrideProps(overrides, "Radio43741918")}
              ></Radio>
            </View>
            <View
              width="110px"
              height="21px"
              display="block"
              gap="10px"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 436")}
            >
              <Radio
                width="unset"
                height="unset"
                position="absolute"
                top="0px"
                left="0px"
                size="small"
                isDisabled={false}
                labelPosition="start"
                children="Standard"
                value="Standard"
                checked={accountStatus === 'Standard'}
                onChange={(e) => {
                  setAccountStatus(event.target.value)
                }
                }
                {...getOverrideProps(overrides, "Radio43741920")}
              ></Radio>
            </View>
            <View
              width="110px"
              height="21px"
              display="block"
              gap="10px"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 437")}
            >
              <Radio
                width="unset"
                height="unset"
                position="absolute"
                top="0px"
                left="0px"
                size="small"
                isDisabled={false}
                labelPosition="start"
                children="Admin"
                value="Admin"
                checked={accountStatus === 'Admin'}
                onChange={(e) => {
                  setAccountStatus(event.target.value)
                }
                }
                {...getOverrideProps(overrides, "Radio43741922")}
              ></Radio>
            </View>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="2px 0px 2px 0px"
            {...getOverrideProps(overrides, "Frame 434")}
          >
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="180px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Data Activated"
              {...getOverrideProps(overrides, "Data Activated")}
            ></Text>
            <SwitchField
              width="unset"
              height="unset"
              label={switchDataActivate}
              shrink="0"
              size="small"
              isDisabled={false}
              labelPosition="end"
              isChecked={dataActivated}
              variation="default"
              onChange={(e) => {
                setDataActivated(e.target.checked)
                setSwitchDataActivate(e.target.checked ? "Active" : "Inactive")
              }}
              {...getOverrideProps(overrides, "SwitchField43741925")}
            ></SwitchField>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="2px 0px 2px 0px"
            {...getOverrideProps(overrides, "Frame 435")}
          >
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="180px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Data Imported"
              {...getOverrideProps(overrides, "Data Imported")}
            ></Text>
            <SwitchField
              width="unset"
              height="unset"
              label={switchDataImported}
              shrink="0"
              size="small"
              isDisabled={false}
              labelPosition="end"
              isChecked={dataImported}
              onChange={(e) => {
                setDataImported(e.target.checked)
                setSwitchDataImported(e.target.checked ? "Active" : "Inactive")
              }}
              {...getOverrideProps(overrides, "SwitchField43741928")}
            ></SwitchField>
          </Flex>
        </Flex> */}
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px"
          {...getOverrideProps(overrides, "Frame 439")}
        >
          <Divider
            width="unset"
            height="3px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider43741929")}
          ></Divider>
          <Flex
            gap="24px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="20px 0px 20px 0px"
            {...getOverrideProps(overrides, "Profile")}
          >
            <Image
              width="388px"
              height="99px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "image 2")}
            ></Image>
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Upload New Logo Image"
              {...getOverrideProps(overrides, "Upload New Logo Image")}
            ></Text>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider43741936")}
          ></Divider>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 32")}
          >
            <Text

              fontSize="20px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="25px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Address"
              {...getOverrideProps(overrides, "Address43741938")}
            ></Text>
          </Flex>
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Address43741939")}
          >
            <TextField
              width="unset"
              height="unset"
              label="Street Address Line 1"
              shrink="0"
              alignSelf="stretch"
              placeholder="Address line 2"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={address1}
              onChange={(e) => {
                setAddress1(address1 => address1 = e.target.value)

              }}
              {...getOverrideProps(overrides, "TextField43741940")}
            ></TextField>
            <TextField
              width="unset"
              height="unset"
              label="Stree Address Line 2"
              shrink="0"
              alignSelf="stretch"
              placeholder="Address line 2"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={address2}
              onChange={(e) => {
                setAddress2(address2 => address2 = e.target.value)

              }}
              {...getOverrideProps(overrides, "TextField43741941")}
            ></TextField>
            <Flex
              gap="24px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 431")}
            >
              <TextField
                width="unset"
                height="unset"
                label="City"
                grow="1"
                shrink="1"
                basis="0"
                placeholder="City"
                size="small"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                defaultValue={city}
                onChange={(e) => {
                  setCity(zipcode => zipcode = e.target.value)

                }}
                {...getOverrideProps(overrides, "TextField43741943")}
              ></TextField>
              <SelectField
                width="unset"
                height="unset"
                label="State"
                grow="1"
                shrink="1"
                basis="0"
                placeholder="Select State"
                size="small"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                defaultValue={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                }
                }
                {...getOverrideProps(overrides, "SelectField43741944")}
              >
                {stateList.map((object, index) => (
                  <option key={index} value={object.name}>
                    {object.name}
                  </option>
                ))}
              </SelectField>
              <TextField
                width="unset"
                height="unset"
                label="Zip Code"
                grow="1"
                shrink="1"
                basis="0"
                placeholder="Zipcode"
                size="small"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                defaultValue={zipcode}
                onChange={(e) => {
                  setZipcode(zipcode => zipcode = e.target.value)

                }}
                {...getOverrideProps(overrides, "TextField43741945")}
              ></TextField>
            </Flex>
            <Flex
              gap="100px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 424")}
            >
              <Button
                width="unset"
                height="unset"
                shrink="0"
                size="small"
                isDisabled={false}
                variation="primary"
                children="Cancel"
                onClick={clickCancel}
                {...getOverrideProps(overrides, "Button40772979")}
              ></Button>
              <Button
                width="unset"
                height="unset"
                gap="100px"
                shrink="0"
                size="small"
                isDisabled={false}
                variation="primary"
                children="Save"
                onClick={processSave}
                {...getOverrideProps(overrides, "Button40772980")}
              ></Button>
            </Flex>
          </Flex>
        </Flex>

      </Flex>
    </Flex>
  );

}
