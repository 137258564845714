/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import {
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  SelectField,
  Text,
  View,
  Heading
} from "@aws-amplify/ui-react";
import {Auth} from 'aws-amplify'

export default function Register3(props) {
  const { overrides, ...rest } = props;

  const [error, setError] = React.useState(false)
  const [provider, setProvider] = React.useState('')
  const location = useLocation();
  let navigate = useNavigate();

  // console.log("Printing register3...")
  // console.log(location.state.prevPage)

  //Do protection of page by checking state
  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='verifyAccount' && location.state?.prevPage!=='signin'){
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  React.useEffect(() => {
    // storing provider in local storage - Check value first to get short name acronym
    let acronymProvider = ''
    if(provider.includes("SCE")){
      acronymProvider = "SCE"
    }
    if(provider.includes("LADWP")){
      acronymProvider = "LADWP"
    }
    if(provider.includes("PG&E")){
      acronymProvider = "PG%26E"
    }
    if(provider.includes("NVE")){
      acronymProvider = "NVE"
    }
    if(provider.includes("FPL")){
      acronymProvider = "FPL"
    }
    if(provider.includes("HECO, MECO, HELCO")){
      acronymProvider = "HECO"
    }
    if(provider.includes("SoCalGas")){
      acronymProvider = "SoCalGas"
    }
    if(provider.includes("PCE")){
      acronymProvider = "PCE"
    }
    if(provider.includes("SDG&E")){
      acronymProvider = "SDG&E"
    }
    if(provider.includes("SMUD")){
      acronymProvider = "SMUD"
    }
    if(provider.includes("SFPUC")){
      acronymProvider = "SFPUC"
    }
    if(provider.includes("SVCE")){
      acronymProvider = "SVCE"
    }
    localStorage.setItem("provider", acronymProvider);
  }, [provider]);


  const validateProvider = () => {
    let providerError = false
    if(provider===''){
      providerError = true
    }
    if(!providerError){
      navigate('/authForm', {state: {prevPage: "selectUtility"}})
      return;
      // if(provider.includes("SCE")){
      //   navigate('/sceConfirm', {state: {prevPage: "selectUtility"}})
      //   return;
      // }else{
      //   navigate('/authForm', {state: {prevPage: "selectUtility"}})
      //   return;
      // }
     
     
    }
    else{
      setError(true)
    }
  }


  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Register3")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Logo")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Registration38462734")}
          >
            <Text
              fontSize="26px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Registration"
              {...getOverrideProps(overrides, "Registration38462737")}
            ></Text>
          </Flex>
          <Image
            width="143px"
            height="90px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src = "../../eaIcon.png"
            // {...getOverrideProps(overrides, "EA LOGO 1")}
          ></Image>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 324")}
        >
          <Flex
            gap="150px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 421")}
          >
            <Icon
              width="34px"
              height="34px"
              viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
              paths={[
                {
                  d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                  stroke: "rgba(61,99,115,1)",
                  fillRule: "nonzero",
                  strokeWidth: 0,
                },
                {
                  d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Ellipse 3")}
            ></Icon>
            <Icon
              width="34px"
              height="34px"
              viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
              paths={[
                {
                  d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                  stroke: "rgba(61,99,115,1)",
                  fillRule: "nonzero",
                  strokeWidth: 0,
                },
                {
                  d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Ellipse 438662824")}
            ></Icon>
            <View
              width="34px"
              height="34px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 422")}
            >
              <Heading
                width="100px"
                height="unset"
                position="absolute"
                top="41px"
                left="-9px"
                level="6"
                children="Step 3"
                {...getOverrideProps(overrides, "Heading38662822")}
              ></Heading>
              <Icon
                width="34px"
                height="34px"
                viewBox={{ minX: 0, minY: 0, width: 34, height: 34 }}
                paths={[
                  {
                    d: "M31 17C31 24.732 24.732 31 17 31L17 37C28.0457 37 37 28.0457 37 17L31 17ZM17 31C9.26801 31 3 24.732 3 17L-3 17C-3 28.0457 5.95431 37 17 37L17 31ZM3 17C3 9.26801 9.26801 3 17 3L17 -3C5.95431 -3 -3 5.95431 -3 17L3 17ZM17 3C24.732 3 31 9.26801 31 17L37 17C37 5.95431 28.0457 -3 17 -3L17 3Z",
                    stroke: "rgba(61,99,115,1)",
                    fillRule: "nonzero",
                    strokeWidth: 0,
                  },
                  {
                    d: "M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17Z",
                    fill: "rgba(61,99,115,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="0px"
                left="0px"
                {...getOverrideProps(overrides, "Ellipse 4")}
              ></Icon>
            </View>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <Divider
            width="unset"
            height="34px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38462747")}
          ></Divider>
          <SelectField
            width="unset"
            height="unset"
            placeholder="Select your utility provider"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={error}
            errorMessage="Please select a utility provider"
            onChange={(e) => {
              setProvider(e.target.value)
            }}
            {...getOverrideProps(overrides, "SelectField")}
          >
            <option value="So Cal Edison (SCE)">So Cal Edison (SCE)</option>
            <option value="LA Dept. of Water & Power (LADWP)">LA Dept. of Water & Power (LADWP)</option>
            <option value="Pacific Gas and Electric (PG&E)">Pacific Gas and Electric (PG&E)</option>
            <option value="Nevada Energy (NVE)">Nevada Energy (NVE)</option>
            <option value="Florida Power and Light Company (FPL)">Florida Power and Light Company(FPL)</option>
            <option value="Hawaii Electric (HECO, MECO, HELCO)">Hawaii Electric (HECO, MECO, HELCO)</option>
            <option value="Southern California Gas (SoCalGas)">Southern California Gas (SoCalGas)</option>
            <option value="Peninsula Clean Energy (PCE)">Peninsula Clean Energy (PCE)</option>
            <option value="San Diego Gas and Electric (SDG&E)">San Diego Gas and Electric (SDG&E)</option>
            <option value="Sacramento Municipal Utility District (SMUD)">Sacramento Municipal Utility District (SMUD)</option>
            <option value="San Francisco Public Utilities Commission (SFPUC)">San Francisco Public Utilities Commission (SFPUC)</option>
            <option value="Silicon Valley Clean Energy (SVCE)">Silicon Valley Clean Energy (SVCE)</option>
          </SelectField>
          <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38462755")}
          ></Divider>
        </Flex>
        <Flex
          gap="275px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 419")}
        >
          {/* <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="< Back"
            onClick={() => navigate('/register2')}
          ></Button> */}
          <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Connect >"
            onClick={validateProvider}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
