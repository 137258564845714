/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  TextField,
  PasswordField
} from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { Auth } from "aws-amplify";
export default function SignUp(props) {

  const location = useLocation();

  const { overrides, ...rest } = props;
  const [email, setEmail] = React.useState('');
  const [currentPassword, setCurrentPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")

  useEffect(() => {
    //Set email
    setEmail(location.state.email)
  })

  async function onSignUp() {
      //Check username/password
          const url = (process.env.NODE_ENV === 'development' ? 
          process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/confirmSignUpUser"
          const data = {
            "userName": email,
            "password": newPassword,
          }
          const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
              "Authorization": "Bearer " + process.env.REACT_APP_ENERGYAUDIT_BEARER_API_KEY,
              "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
          const result = await response.json()

          //Validate if result contains success. If yes, activatedStatus is true
          if (result.includes("Success")) {
          }
      console.log('New password set successfully');
  }

  let navigate = useNavigate();
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "SignUp")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Logo")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "SignUp38452930")}
          >
            <Heading
              fontSize="26px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="SignUp"
              {...getOverrideProps(overrides, "SignUp38452933")}
            ></Heading>
          </Flex>
          <Image
            width="143px"
            height="90px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src = "../../eaIcon.png"
            // {...getOverrideProps(overrides, "EA LOGO 1")}
          ></Image>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <Divider
            width="unset"
            height="34px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider43931150")}
          ></Divider>
          <TextField
            width="unset"
            height="unset"
            label="Email"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={true}
            labelHidden={false}
            defaultValue={email}
            variation="default"
            {...getOverrideProps(overrides, "TextField43931155")}
          ></TextField>
         <PasswordField
              width="unset"
              height="unset"
              label="Current Password"
              placeholder="Current Password"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              labelHidden={false}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                }}
              variation="default"
              {...getOverrideProps(overrides, "TextField39472908")}
            ></PasswordField>
            <PasswordField
              width="unset"
              height="unset"
              label="New Password"
              placeholder="New Password"
              shrink="0"
              alignSelf="stretch"
              size="default"
              isDisabled={false}
              labelHidden={false}
              onChange={(e) => {
                setNewPassword(e.target.value);
                }}
              variation="default"
              {...getOverrideProps(overrides, "TextField39472910")}
            ></PasswordField>
          <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider43931158")}
          ></Divider>
        </Flex>
        <Flex
          gap="275px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 419")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="< Back"
            onClick={() => {
              navigate('/');
            }}
            {...getOverrideProps(overrides, "Button43931160")}
          ></Button>
          <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="SignUp"
            onClick={onSignUp}
            {...getOverrideProps(overrides, "Button43931161")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
