/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Button,
  Divider,
  Flex,
  Image,
  SelectField,
  TextField,
  Link
} from "@aws-amplify/ui-react";

export default function Register(props) {
  const { overrides, ...rest } = props;
  const [isPressed, setIsPressed] = React.useState(true);

  const [error, setError] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState("Please type a valid email")
  const [organization, setOrganization] = React.useState('')

  let navigate = useNavigate();

  const validateOrganization = async () => {
    let orgError = false
    if (organization === '') {
      orgError = true
    }
    else {
      //const user = await Auth.currentAuthenticatedUser();
      //const suffix = organization.match(/.+@(.+)/);
      var domainName = organization.split("@")[1];
      // console.log("organization:" + organization)
      // console.log('domain is : ' + domainName);

      try {
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDomainByDomain?domain=" + domainName;
        // console.log("Url: " + url)
        // console.log(data)
        const response = await fetch(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          }
        })
        // console.log(url)
        // console.log(response)
        const result = await response.json()
        console.log(result);
        if (domainName === result.domain) {
          orgError = false;
        }
        else {
          //Check if it is entire email
          const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDomainByDomain?domain=" + organization;
          const response = await fetch(url, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
              "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
              "Content-Type": "application/json"
            }
          })
          const result = await response.json()
          if (organization === result.domain) {
            orgError = false;
          }
          else{
            orgError = true
            setErrorMsg("Your organization is not registered!")
          }
        }
        if (!orgError) {
          navigate('/register2', { state: { email: organization, prevPage: 'register' } })
          return;
        }
        else {
          setError(true)
        }
      }
      catch (err) {
        console.log(err)
      }
      //Check if organization is included in listted
      /*if(!organization.includes('goodwill') && !organization.includes('hygiena') && !organization.includes('energyaudit') && !organization.includes('calnrg')
      && !organization.includes('microdyn-nadir') && !organization.includes('a-m-c') && !organization.includes('divergent3d') && !organization.includes('phasemargin')
      && !organization.includes('emlinq') && !organization.includes('emlinq') && !organization.includes('ejharrison') && organization!==('venturapetro@gmail.com')
      && !organization.includes('garedgraphics') && !organization.includes('meritronics')){
        orgError = true
        setErrorMsg("Your organization is not registered!")
      }*/

    }


  }

  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="100px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Register")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Logo")}
        >
          <Image
            width="287px"
            height="180px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="../../eaIcon.png"
            {...getOverrideProps(overrides, "EA LOGO 1")}
          ></Image>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(245,245,245,1)"
          borderRadius="5px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 28")}
        >
          <Button
            width="unset"
            height="unset"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={!isPressed}
            variation="primary"
            children="Sign In"
            color="grey"
            style={{ backgroundColor: isPressed === false ? "#EFF0F0" : "white" }}
            onClick={() => {
              setIsPressed(!isPressed);
              navigate('/signin', { state: { pressed: isPressed, prevPage: 'register' } });
              return;
            }}
            {...getOverrideProps(overrides, "Button38452714")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            grow="1"
            shrink="1"
            basis="0"
            color="grey"
            style={{ backgroundColor: isPressed === true ? "#EFF0F0" : "white" }}
            size="large"
            isDisabled={isPressed}
            variation="primary"
            children="Register"
            onClick={() => {
              setIsPressed(!isPressed);
            }}
          ></Button>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452785")}
          ></Divider>
          {/* <SelectField
            width="unset"
            height="unset"
            placeholder="Select your organization"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={error}
            errorMessage="Please select an organization"
            onChange={(e) => {
              setOrganization(e.target.value)
            }}
            {...getOverrideProps(overrides, "SelectField")}
          >
            <option value="Goodwill Industries of Ventura and Santa Barbara Counties">Goodwill Industries of Ventura and Santa Barbara Counties</option>
            <option value="Energy Audit">Energy Audit</option>
            <option value="Hygenia">Hygenia</option>
          </SelectField> */}
          <TextField
            width="unset"
            height="unset"
            label="Email"
            placeholder="Please type your work email"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            hasError={error}
            errorMessage={errorMsg}
            onChange={(e) => {
              setOrganization(e.target.value)
            }}
            {...getOverrideProps(overrides, "TextField38452584")}
          ></TextField>
          <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452787")}
          ></Divider>
        </Flex>

        <Flex
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          <Link
            href="/privacy_policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
            textDecoration="underline"
            fontFamily="Inter"
            fontStyle="italic"
          >
            Privacy Policy
          </Link>
        </Flex>


        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 419")}
        >
          <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Get Started >"
            // onClick={() => navigate('/register2')}
            onClick={validateOrganization}
            {...getOverrideProps(overrides, "Button38452720")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}