/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  Image,
  Radio,
  SelectField,
  SwitchField,
  Text,
  TextField,
  View,
  Alert,
} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
export default function EditOrganizationProfile(props) {
  const { overrides, onClickCancel, onEditOrgProfile, editData, ...rest } = props;

  const fileInputRef = React.useRef(null);
  const [editOrgData, setEditOrgData] = React.useState({});
  const [domain, setDomain] = React.useState('')
  const [organization, setOrganization] = React.useState("");
  const [originalOrganization, setOriginalOrganization] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [orgRole, setOrgRole] = React.useState(false);
  const [switchDataActivate, setSwitchDataActivate] = React.useState("Inactive");
  const [switchDataImported, setSwitchDataImported] = React.useState("Inactive");

  const [dataActivated, setDataActivated] = React.useState(false);
  const [dataImported, setDataImported] = React.useState(false);

  const [selectedState, setSelectedState] = React.useState('');
  const [changedBy, setChangedBy] = React.useState('');
  const [companyContact, setCompanyContact] = React.useState('');
  const [lastModifyDate, setLastModifyDate] = React.useState('');

  const [accountStatus, setAccountStatus] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [address2, setAddress2] = React.useState('');
  const [city, setCity] = React.useState('');
  const [zipcode, setZipcode] = React.useState('');
  const [organizationProfilePic, setOrganizationProfilePic] = React.useState('');
  const [organizationProfilePicDisplay, setOrganizationProfilePicDisplay] = React.useState();
  const [showDataImportAlert, setShowDataImportAlert] = React.useState(false);
  const [showDataImportMessage, setShowDataImportMessage] = React.useState("");
  const [variation, setVariation] = React.useState("");
  const [isDataImported, setIsDataImported] = React.useState(true);




  React.useEffect(() => {
    setDataActivated(false);
    setDataImported(false);
    //setOrgRole(editData.demoUser);
    setDomain('')
    setOrganization('')
    setSwitchDataImported('Inactive');
    setSwitchDataActivate('Inactive');
    setChangedBy('');
    setCompanyContact('');
    setLastModifyDate('');
    setAddress1('');
    setAddress2('');
    setCity('');
    setSelectedState('');
    setZipcode('');
    setAccountStatus('')
    setShowDataImportAlert(false);
    setShowDataImportMessage("");
    setVariation("");
    setOrganization(editData.organization);
    setOriginalOrganization(editData.organization);
    //checkIfDataIsImportedAndActivated();
    if (editData !== undefined && editData.noData !== 'Domain not found') {
      setDataActivated(editData.activatedStatus);
      setDataImported(editData.importedStatus);
      setSwitchDataImported(editData.importedStatus ? 'Active' : 'Inactive');
      setSwitchDataActivate(editData.activatedStatus ? 'Active' : 'Inactive');
      //setOrgRole(editData.demoUser);
      setDomain(editData.domain);

      setAccountStatus(editData.accountStatus);
      setChangedBy(editData.changedBy);
      setCompanyContact(editData.companyContact);
      setLastModifyDate(editData.lastModifyDate);
      setAddress1(editData.address1);
      setAddress2(editData.address2);
      setCity(editData.city);
      setSelectedState(editData.state);
      setZipcode(editData.zipcode);
      setOrganizationProfilePicDisplay(editData.organizationPic)
      setIsDataImported(editData.dataImportByAdminTimeStamp);
      //setDataImportTimestamp(editData.dataImportTimestamp);
      if (editData.dataImportByAdminTimeStamp) {
        setVariation("success");
        setShowDataImportAlert(editData.dataImportByAdminTimeStamp ? true : false);
        setShowDataImportMessage('Data Import initiated at ' + formatDateTime(editData.dataImportTimestamp) + '. Try again later.');
      }

    }

  }, [props]);


  function formatDateTime(dataImportTimestamp) {
    const dateObject = new Date(dataImportTimestamp);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/Los_Angeles',
    };

    return dateObject.toLocaleString('en-US', options);
  }


  async function onUpdateOrgProfile() {
    const user = await Auth.currentAuthenticatedUser();
    try {
      const jwtData = await Auth.currentSession();
      const jwtToken = jwtData.idToken.jwtToken;
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/updateOrganization"
      const data = {
        organization: organization,
        originalOrganization: originalOrganization,
        domain: domain,
        companyContact: companyContact,
        changedBy: user.attributes.name,
        lastModifyDate: new Date().toISOString(),
        accountStatus: accountStatus,
        dataActivated: dataActivated,
        dataImported: dataImported,
        address1: address1,
        address2: address2,
        city: city,
        state: selectedState,
        zipcode: zipcode,
      }
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      if (response.ok) {
        const result = await response.json();
        console.log('Success:', result);
        await uploadOrganizationProfilePic()
        onEditOrgProfile(organization)
      } else {
        setShowAlert(true);
        console.error('Request failed with status:', response.status);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  async function uploadOrganizationProfilePic() {
    if (organizationProfilePic !== "") {
      const user = await Auth.currentAuthenticatedUser();
      try {
        const jwtData = await Auth.currentSession();
        const jwtToken = jwtData.idToken.jwtToken;
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/uploadOrganizationPhoto"
        const formData = new FormData();
        formData.append('organization', organization);
        formData.append('organizationPic', organizationProfilePic);
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          },
          body: formData
        })
        if (response.ok) {
          const result = await response.json();
          console.log('Success:', result);
        } else {
          console.error('Request failed with status:', response.status);
        }
      }
      catch (err) {
        console.log(err)
      }
    }
  }


  const handleLabelClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = event => {
    console.log('File selected:', event.target.files[0]);
    setOrganizationProfilePic(event.target.files[0]);
    setOrganizationProfilePicDisplay(URL.createObjectURL(event.target.files[0]));
  };

  const onClickAccountStatus = (event) => {
    let message = (accountStatus === '' || accountStatus === undefined) ? `Do you want to change to ${event.target.value}?` : `Do you want to change from ${accountStatus} to ${event.target.value}?`
    const confirmation = window.confirm(message);
    if (confirmation) {
      setAccountStatus(event.target.value);
    } else {
      event.preventDefault();
    }
  }

  const onClickDataImport = async (event) => {

    let message = `Please provide password to Data Import:`;

    const confirmation = window.prompt(message, '');
    if (confirmation) {
      const actionTypeValue = await getActionTypeValue();
      if (confirmation === actionTypeValue) {
        checkIfUtilityDataIsActivated();
        setIsDataImported(true);
      } else {
        alert("You are not authorized!");
      }
    } else {
      if (confirmation === "") {
        event.preventDefault();
        alert("Please enter password");
      }
    }

    // if(dataImported){
    //   setVariation("success");
    //   setShowDataImportAlert(true);
    //   setShowDataImportMessage("Data Import already enabled!");
    // }else{
    //   const confirmation = window.prompt(message, '');
    // if (confirmation) {
    //   const actionTypeValue = await getActionTypeValue(); 
    //   if (confirmation === actionTypeValue) {
    //      checkIfUtilityDataIsActivated();
    //   } else {
    //     alert("You are not authorized!");
    //   }
    // } else {
    //   if(confirmation === ""){
    //     event.preventDefault();
    //     alert("Please enter password");
    //   }
    // }
    // }

  }

  const getActionTypeValue = async () => {
    const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getEACredential?actionType=DataImport"
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      //mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        "Content-Type": "application/json"
      },
      //body: JSON.stringify(data)
    })
    if (response.ok) {
      const result = await response.json();
      return result.Value.S;
    } else {
      throw new Error("Failed to fetch action type value");
    }

  }


  async function checkIfUtilityDataIsActivated() {
    try {

      const checkIfUtilityDataIsActivated = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfUtilityDataIsActivated"
      const data = { groupName: organization }
      const dataIsActivatedResponse = await fetch(checkIfUtilityDataIsActivated, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          //"Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      const dataIsActivatedResult = await dataIsActivatedResponse.json()
      setShowDataImportAlert(false);
      setShowDataImportMessage("");
      setVariation("");
      //Validate if result contains success. If yes, activatedStatus is true
      if (dataIsActivatedResult.includes("Success")) {
        // setDataActivated(true)
        // setSwitchDataActivate('Active');
        checkIfDataIsImported()
        //return true;
      } else {
        setVariation("error");
        setShowDataImportAlert(true);
        setShowDataImportMessage("Utility API data has not been activated");
        setIsDataImported(false);
      }
    }
    catch (err) {
      setIsDataImported(false);
      throw err
    }
  }

  async function checkIfDataIsImported() {
    try {

      const checkIfDataIsImported = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfDataIsImported";

      //Get list of authorization codes for groupName from dynamodb
      // const authorizationCodesList = await getAuthorizationCodesForGroup()

      //Iterate through each authCode to check if data is activated
      //for (let i = 0; i < authorizationCodesList.length; i++) {
      // const authorization = authorizationCodesList[i]
      const data = { groupName: organization }
      const dataIsImportedResponse = await fetch(checkIfDataIsImported, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          //"Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      setShowDataImportAlert(false);
      setShowDataImportMessage("")
      const dataIsImportedResult = await dataIsImportedResponse.json()
      //Validate if result contains success. If yes, importedStatus is true
      if (dataIsImportedResult.includes("Success")) {
        //setDataImported(true);
        // setSwitchDataImported('Active');
        initialUploadJob()
      } else {
        setVariation("error");
        setShowDataImportAlert(true);
        setShowDataImportMessage('Utility API data has not been Imported');
        setIsDataImported(false);
      }
      //}
    }
    catch (err) {
      setIsDataImported(false);
      throw err
    }
  }

  async function initialUploadJob() {
    try {

      const initialUploadJobURL = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/initialUploadJob";

      const data = { groupName: organization }
      const initialUploadJobResponse = await fetch(initialUploadJobURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          //"Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      setShowDataImportAlert(false);
      setShowDataImportMessage("")
      const initialUploadJobResult = await initialUploadJobResponse.json()
      //Validate if result contains success. If yes, importedStatus is true
      if (initialUploadJobResult.includes("Success")) {
        //setDataImported(true);
        // setSwitchDataImported('Active');
        setVariation("success");
        setShowDataImportAlert(true);
        setShowDataImportMessage("Data Import done successfully.");
      } else {
        setVariation("error");
        setShowDataImportAlert(true);
        setShowDataImportMessage('Initial Upload Job Failed');

      }
      //}
    }
    catch (err) {
      setIsDataImported(false);
      throw err
    }
  }




  const stateList = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];


  return (
    <Flex
      gap="24px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "EditOrganizationProfile")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Frame 434")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 31")}
        >
          <Text

            fontSize="36px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            size="default"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Update Organization"
            {...getOverrideProps(overrides, "Update Organization")}
          ></Text>
        </Flex>
        {showAlert && (
          <Alert
            width="unset"
            alignSelf="stretch"
            variation="error" heading="Error"
            onDismiss={() => setShowAlert(false)}
            isDismissible={true}
          >
            Edit Organization has been failed
          </Alert>
        )}
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="default"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider43203640")}
        ></Divider>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 43343681208")}
        >
          <Text

            fontSize="20px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Account & Data Status"
            {...getOverrideProps(overrides, "Account & Data Status")}
          ></Text>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 0px 10px 0px"
            {...getOverrideProps(overrides, "Frame 43343701258")}
          >
            <Text
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="180px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Account Status"
              {...getOverrideProps(overrides, "Account Status :")}
            ></Text>
            <View
              width="100px"
              height="21px"
              display="block"
              gap="5px"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 438")}
            >
              <Radio
                width="unset"
                height="unset"
                position="absolute"
                top="0px"
                left="0px"
                size="small"
                isDisabled={false}
                labelPosition="start"
                children="Demo"
                checked={accountStatus === 'Demo'}
                defaultValue="Demo"
                onChange={onClickAccountStatus}
                {...getOverrideProps(overrides, "Radio43681979")}
              ></Radio>
            </View>
            <View
              width="110px"
              height="21px"
              display="block"
              gap="5px"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 436")}
            >
              <Radio
                width="unset"
                height="unset"
                position="absolute"
                top="0px"
                left="0px"
                size="small"
                isDisabled={false}
                labelPosition="start"
                children="Standard"
                checked={accountStatus === 'Standard'}
                defaultValue="Standard"
                onChange={onClickAccountStatus}
                {...getOverrideProps(overrides, "Radio43681965")}
              ></Radio>
            </View>
            {/* <View
              width="110px"
              height="21px"
              display="block"
              gap="5px"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 437")}
            >
              <Radio
                width="unset"
                height="unset"
                position="absolute"
                top="0px"
                left="10px"
                size="small"
                isDisabled={false}
                labelPosition="start"
                children="Admin"
                checked={accountStatus === 'Admin'}
                defaultValue="Admin"
                onChange={onClickAccountStatus}
                {...getOverrideProps(overrides, "Radio43681972")}
              ></Radio>
            </View> */}
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="2px 0px 2px 0px"
            {...getOverrideProps(overrides, "Frame 43443701266")}
          >
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="180px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Data Activated"
              {...getOverrideProps(overrides, "Data Activated")}
            ></Text>
            <SwitchField
              width="unset"
              height="unset"
              label={switchDataActivate}
              shrink="0"
              size="small"
              isDisabled={true}
              labelPosition="end"
              isChecked={dataActivated}
              variation="default"
              onChange={(e) => {
                setDataActivated(e.target.checked)
                setSwitchDataActivate(e.target.checked ? "Active" : "Inactive")
              }}
              {...getOverrideProps(overrides, "SwitchField43701272")}
            ></SwitchField>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="2px 0px 2px 0px"
            {...getOverrideProps(overrides, "Frame 435")}
          >
            <Text

              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="180px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Data Imported"
              {...getOverrideProps(overrides, "Data Imported")}
            ></Text>
            <SwitchField
              width="unset"
              height="unset"
              label={switchDataImported}
              shrink="0"
              size="small"
              isDisabled={true}
              labelPosition="end"
              variation="default"
              isChecked={dataImported}
              onChange={(e) => {
                setDataImported(e.target.checked);
                setSwitchDataImported(e.target.checked ? "Active" : "Inactive")
              }}
              {...getOverrideProps(overrides, "SwitchField43701297")}
            ></SwitchField>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={isDataImported}
              variation="primary"
              children="Data Import"
              onClick={onClickDataImport}
              {...getOverrideProps(overrides, "Button43203528")}
            ></Button>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="2px 0px 2px 0px"
            {...getOverrideProps(overrides, "Frame 435")}
          >
            {showDataImportAlert && (
              <Alert
                width="100%"
                alignSelf="stretch"
                variation={variation}
                onDismiss={() => setShowDataImportAlert(false)}
                isDismissible={true}
              >{showDataImportMessage}
              </Alert>)}
          </Flex>
        </Flex>
        <Divider
          width="unset"
          height="3px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider43681249")}
        ></Divider>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Profile")}
        >
          <Image
            width="388px"
            height="99px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="contain"
            src={organizationProfilePicDisplay}
            onClick={handleLabelClick}
            style={{ cursor: 'pointer' }}
            {...getOverrideProps(overrides, "image 2")}
          ></Image>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={fileInputRef}
            accept="image/*"
            onChange={handleFileChange}
          />
          <Text
            onClick={handleLabelClick}
            fontSize="16px"
            fontWeight="400"
            color="rgba(13,26,38,1)"
            lineHeight="22px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            textDecoration="underline"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Upload New Logo Image"
            {...getOverrideProps(overrides, "Upload New Logo Image")}
          ></Text>
        </Flex>
        <TextField
          width="unset"
          height="unset"
          label="Organization Name"
          placeholder="Organization Name"
          shrink="0"
          alignSelf="stretch"
          size="small"
          isDisabled={false}
          labelHidden={false}
          defaultValue={organization}
          onChange={(e) => {
            setOrganization(e.target.value);
          }}
          variation="default"
          {...getOverrideProps(overrides, "TextField43203524")}
        ></TextField>
        <TextField
          width="unset"
          height="unset"
          label="Organization Domain"
          placeholder="Organization Domain"
          shrink="0"
          alignSelf="stretch"
          size="default"
          isDisabled={true}
          labelHidden={false}
          variation="default"
          onChange={(e) => {
            setDomain(e.target.value);
          }}
          defaultValue={domain}
          {...getOverrideProps(overrides, "TextField43203526")}
        ></TextField>
        <TextField
          width="unset"
          height="unset"
          label="Organization Contact Name"
          placeholder="Contact Name"
          shrink="0"
          alignSelf="stretch"
          size="small"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          onChange={(e) => {
            setCompanyContact(e.target.value);
          }}
          defaultValue={companyContact}
          {...getOverrideProps(overrides, "TextField40992896")}
        ></TextField>
        <SelectField
          width="unset"
          height="unset"
          label="Industry"
          shrink="0"
          alignSelf="stretch"
          placeholder=""
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "SelectField43203523")}
        ></SelectField>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider43203642")}
        ></Divider>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 32")}
        >
          <Text

            fontSize="20px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Address"
            {...getOverrideProps(overrides, "Address43203615")}
          ></Text>
        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Address43741939")}
        >
          <TextField
            width="unset"
            height="unset"
            label="Street Address Line 1"
            shrink="0"
            alignSelf="stretch"
            placeholder="Street Address Line 1"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={address1}
            onChange={(e) => {
              setAddress1(e.target.value)
            }}
            {...getOverrideProps(overrides, "TextField43741940")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Stree Address Line 2"
            shrink="0"
            alignSelf="stretch"
            placeholder="Address line 2"
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={address2}
            onChange={(e) => {
              setAddress2(e.target.value)
            }}
            {...getOverrideProps(overrides, "TextField43741941")}
          ></TextField>
          <Flex
            gap="24px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 431")}
          >
            <TextField
              width="unset"
              height="unset"
              label="City"
              grow="1"
              shrink="1"
              basis="0"
              placeholder="City"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={city}
              onChange={(e) => {
                setCity(e.target.value)
              }}
              {...getOverrideProps(overrides, "TextField43741943")}
            ></TextField>
            <SelectField
              width="unset"
              height="unset"
              label="State"
              grow="1"
              shrink="1"
              basis="0"
              placeholder="Select State"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value);
              }
              }
              {...getOverrideProps(overrides, "SelectField43741944")}
            >
              {stateList.map((object, index) => (
                <option key={index} value={object.name}>
                  {object.name}
                </option>
              ))}
            </SelectField>
            <TextField
              width="unset"
              height="unset"
              label="Zip Code"
              grow="1"
              shrink="1"
              basis="0"
              placeholder="Zipcode"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={zipcode}
              onChange={(e) => {
                setZipcode(e.target.value)

              }}
              {...getOverrideProps(overrides, "TextField43741945")}
            ></TextField>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="35px 0px 35px 0px"
          {...getOverrideProps(overrides, "Frame 432")}
        >
          <Button
            width="144px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="link"
            children="X Cancel"
            onClick={onClickCancel}
            {...getOverrideProps(overrides, "Button43203528")}
          ></Button>
          <Button
            width="197px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Save"
            onClick={onUpdateOrgProfile}
            {...getOverrideProps(overrides, "Button43203617")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
