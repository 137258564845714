/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Alert,
  Button,
  Divider,
  Flex,
  SelectField,
  SwitchField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
export default function CreateUserProfile(props) {
  const { overrides, onCreateCancel, onCreateUserProfile, ...rest } = props;


  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [role, setRole] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [organizationList, setOrganizationList] = React.useState([]);
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(false);
  const [switchAdmin, setSwitchAdmin] = React.useState("Active");
  const [errorResponseDetails, setErrorResponseDetails] = React.useState("");

  async function getOrgList() {
    const jwtData = await Auth.currentSession();

    const jwtToken = jwtData.idToken.jwtToken;
    const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getOrganizationByName";
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Authorization": "Bearer " + jwtToken,
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ "groupName": 'all' }) // body data type must match "Content-Type" header
    })
    if (response.ok) {
      const result = await response.json();
      const sortedData = result.sort((a, b) => a.name.localeCompare(b.name));
      const filteredOrgs = sortedData.map(org => {
        if(org.activatedStatus && !org.demoUser){
          return org.name
        }
      }).filter(org => org);
      setOrganizationList(filteredOrgs);
      
    } else {
      console.error('Request failed with status:', response.status);
    }
  }

  React.useEffect(() => {
    getOrgList()
  }, []);

  

  async function onCreateUser() {
    setErrorResponseDetails("");
      try {
        if(organization!==""){
          const { user } = await Auth.signUp({
            username: email,
            password: password,
            attributes: {
              name: firstName + " " + lastName,
              email: email,
              'custom:title': role,        // optional
              'custom:organization': organization,
              'custom:status' : 'Activate'
            },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
              enabled: true,
            }
          });
          onCreateUserProfile(email)
          return;
        }
      } catch (error) {
        console.log('error signing up:', error);
        //onCreateUserProfile(email)
        setShowAlert(true);
        setErrorResponseDetails(error.message);
    }
    /*try {
      const jwtData = await Auth.currentSession();
      const jwtToken = jwtData.idToken.jwtToken;
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/createNewUser"
      const data = {
        "userName": email,
        "name": firstName + " " + lastName,
        "role": role,
        "password":password,
        "organization":organization,
        "status":"Activate",
        "isAdmin": false
      }
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      if (response.ok) {
        const result = await response.json();
        console.log('Success:', result);
        onCreateUserProfile(email)
      } else {
        setShowAlert(true);
        const errorResponse = await response.json();
        setErrorResponseDetails(errorResponse.details);
        console.error('Request failed with status:', response.status, 'and message:', errorResponse.details);
      }
    }*/
    
  }
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "CreateUserProfile")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="column"
        width="640px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "CreateUserProfile4389900")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="24px 24px 24px 24px"
          {...getOverrideProps(overrides, "Content")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Create Profile")}
          >
            <Text
             
              fontSize="25px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Create User"
              {...getOverrideProps(overrides, "Create User")}
            ></Text>
          </Flex>
          {showAlert && (
            <Alert
              width="unset"
              alignSelf="stretch"
              variation="error" heading="Error"
              onDismiss={() => setShowAlert(false)}
              isDismissible={true}
            >
              {email} : {errorResponseDetails} 
            </Alert>
          )}
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider4389904")}
          ></Divider>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Forms")}
          >
            <Flex
              gap="16px"
              direction="row"
              width="592px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 420")}
            >
              <TextField
                width="unset"
                height="unset"
                label="First Name"
                grow="1"
                shrink="1"
                basis="0"
                size="small"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                defaultValue={firstName}
                onChange={(e) => {
                  setFirstName(firstName => firstName = e.target.value)
  
                }}
                {...getOverrideProps(overrides, "TextField4389907")}
              ></TextField>
              <TextField
                width="unset"
                height="unset"
                label="Last Name"
                grow="1"
                shrink="1"
                basis="0"
                size="small"
                isDisabled={false}
                labelHidden={false}
                variation="default"
                defaultValue={lastName}
                onChange={(e) => {
                  setLastName(lastName => lastName = e.target.value)
  
                }}
                {...getOverrideProps(overrides, "TextField4389908")}
              ></TextField>
            </Flex>
            <TextField
              width="unset"
              height="unset"
              label="Email"
              placeholder="john.doe@goodwillvsb.org"
              shrink="0"
              alignSelf="stretch"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={email}
              onChange={(e) => {
                setEmail(email => email = e.target.value)
              }}
              {...getOverrideProps(overrides, "TextField4389909")}
            ></TextField>
            <TextField
              width="unset"
              height="unset"
              label="Pasword"
              placeholder="Password"
              shrink="0"
              alignSelf="stretch"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={password}
              onChange={(e) => {
                setPassword(password => password = e.target.value)
              }}
              {...getOverrideProps(overrides, "TextField4389909")}
            ></TextField>
            {organizationList.length === 0 ? <TextField
              width="unset"
              height="unset"
              label="Organization"
              shrink="0"
              alignSelf="stretch"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={organization}
              onChange={(e) => {
                setOrganization(organization => organization = e.target.value)
              }}
              {...getOverrideProps(overrides, "TextField4389910")}
            ></TextField>: 
              <SelectField
                    width="300px"
                    height="unset"
                    label="Organization"
                    shrink="0"
                    alignSelf="stretch"
                    placeholder="Select Organization"
                    size="small"
                    isDisabled={false}
                    labelHidden={false}
                    variation="default"
                    options={organizationList}
                    value={organization}
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }
                    }
                  ></SelectField>}
            <TextField
              width="unset"
              height="unset"
              label="Role"
              placeholder="e.g. Building Manager"
              shrink="0"
              alignSelf="stretch"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={role}
              onChange={(e) => {
                setRole(role => role = e.target.value)
              }}
              {...getOverrideProps(overrides, "TextField4389911")}
            ></TextField>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 0px 10px 0px"
              {...getOverrideProps(overrides, "Frame 433")}
            >
              <Text
               
                fontSize="20px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="20px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Administrator"
                {...getOverrideProps(overrides, "Administrator")}
              ></Text>
              <SwitchField
                width="120px"
                height="unset"
                label="Active"
                shrink="0"
                size="small"
                isDisabled={false}
                labelPosition="end"
                {...getOverrideProps(overrides, "SwitchField")}
              ></SwitchField>
            </Flex>
          </Flex>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider4389915")}
          ></Divider>
          <Flex
            gap="100px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 424")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="link"
              children="X Cancel"
              onClick={onCreateCancel}
              {...getOverrideProps(overrides, "Button4389917")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              gap="100px"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Save"
              onClick={onCreateUser}
              {...getOverrideProps(overrides, "Button4389918")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
