/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Alert,
  Button,
  Divider,
  Flex,
  SwitchField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
export default function EditUserProfile(props) {
  const { overrides, onClickCancel, onEditUserProfile, editData, ...rest } = props;



  const [editUserData, setEditUserData] = React.useState({});
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  /** Since we use Email as main identifier for user, tracking changing email separately */
  const [updatedEmail, setUpdatedEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(false);
  const [switchAdmin, setSwitchAdmin] = React.useState("Active");

  React.useEffect(() => {
    let fullName = editData.name
    console.log("Row Data" + editData.name)
    console.log("Row Data" + editData)
    setName(editData.name);
    setEmail(editData.email)
    setRole(editData["custom:title"])
    setOrganization(editData["custom:organization"])
    setIsAdmin(editData.isAdmin)
    if (fullName !== undefined) {
      let firstNameDefault = fullName.split(" ")[0];
      let lastNameDefault = fullName.split(" ")[1];
      setFirstName(firstNameDefault);
      setLastName(lastNameDefault);
    }
  }, [props]);

  async function onUpdateUserProfile() {
    const user = await Auth.currentAuthenticatedUser();
    try {
      const jwtData = await Auth.currentSession();
      const jwtToken = jwtData.idToken.jwtToken;
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/updateUser"
      const data = {
        "userName": email,
        "name": firstName + " " + lastName,
        "role": role,
        "isAdmin": false,
        ...(updatedEmail && {"email" : updatedEmail}),
      }
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      if (response.ok) {
        const result = await response.json();
        console.log('Success:', result);
        onEditUserProfile(email)
      } else {
        setShowAlert(true);
        console.error('Request failed with status:', response.status);
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  return (
    <Flex
      gap="16px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "EditUserProfile")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >

        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Edit Profile")}
        >
          <Text
            fontSize="25px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Update User"
            {...getOverrideProps(overrides, "Update User")}
          ></Text>
        </Flex>
        {showAlert && (
          <Alert
            width="unset"
            alignSelf="stretch"
            variation="error" heading="Error"
            onDismiss={() => setShowAlert(false)}
            isDismissible={true}
          >
           Details for {email} has been failed
          </Alert>
        )}
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider43213875")}
        ></Divider>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="592px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 420")}
          >
            <TextField
              width="unset"
              height="unset"
              label="First Name"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={firstName}
              onChange={(e) => {
                setFirstName(firstName => firstName = e.target.value)

              }}
              {...getOverrideProps(overrides, "TextField43213881")}
            ></TextField>
            <TextField
              width="unset"
              height="unset"
              label="Last Name"
              grow="1"
              shrink="1"
              basis="0"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              defaultValue={lastName}
              onChange={(e) => {
                setLastName(lastName => lastName = e.target.value)

              }}
              {...getOverrideProps(overrides, "TextField43213882")}
            ></TextField>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            label="Email"
            placeholder="john.doe@goodwillvsb.org"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={email}
            onChange={(e) => {
              setUpdatedEmail(updatedEmail => updatedEmail = e.target.value)

            }}
            {...getOverrideProps(overrides, "TextField43213883")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Organization"
            placeholder="Goodwill Industries of Ventura and Santa Barbara Counties"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={true}
            labelHidden={false}
            variation="default"
            defaultValue={organization}
            onChange={(e) => {
              setOrganization(organization => organization = e.target.value)

            }}
            {...getOverrideProps(overrides, "TextField43213884")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Role"
            placeholder="CTO"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            defaultValue={role}
            onChange={(e) => {
              setRole(role => role = e.target.value)

            }}
            {...getOverrideProps(overrides, "TextField43213885")}
          ></TextField>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="10px 0px 10px 0px"
            {...getOverrideProps(overrides, "Frame 433")}
          >
            <Text
              fontSize="20px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Administrator"
              {...getOverrideProps(overrides, "Administrator")}
            ></Text>
            <SwitchField
              width="120px"
              height="unset"
              label={switchAdmin}
              shrink="0"
              size="small"
              isDisabled={false}
              labelPosition="end"
              checked={isAdmin}
              onChange={(e) => {
                setIsAdmin(isAdmin => isAdmin = e.target.checked);
                setSwitchAdmin(e.target.checked ? "Active" : "Inactive")
              }}
              {...getOverrideProps(overrides, "SwitchField")}
            ></SwitchField>
          </Flex>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider43213886")}
        ></Divider>
        <Flex
          gap="100px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 424")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="link"
            children="X Cancel"
            onClick={onClickCancel}
            {...getOverrideProps(overrides, "Button43213888")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            gap="100px"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Save"
            onClick={onUpdateUserProfile}
            {...getOverrideProps(overrides, "Button43213889")}
          ></Button>
        </Flex>

      </Flex>
    </Flex>
  );
}
