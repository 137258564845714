/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
import {useNavigate, useLocation, Navigate} from "react-router-dom";
import { Auth } from "aws-amplify";

export default function EnableDataPage(props) {
  const { overrides, ...rest } = props;
  let navigate = useNavigate();
  const location = useLocation();
  const [activatedDataStatus, setActivatedDataStatus] = React.useState(false)
  const [localDateTime, setLocalDateTime] = React.useState("N.A.")


  if(location?.state !==undefined && location.state?.prevPage !==undefined){
    if(location.state?.prevPage!=='authForm' && location.state?.prevPage!=='signin'){
      //Did not come from authForm or signin prevPage
      return <Navigate replace to="/" />;
    }
  }
  else{
    //Undefined state
    return <Navigate replace to="/" />;
  }

  async function getLocalDateTimeString(){
    try{
      const localDate = new Date().toLocaleDateString()
      const localTime = new Date().toLocaleTimeString()
      const localDateTimeString = localDate + " " + localTime
      setLocalDateTime(localDateTimeString)
    }
    catch(err){
      console.log(err.stack)
      throw err
    }
  }

  async function getAuthorizationCodesForGroup(){
    try{
      const jwtData = await Auth.currentSession();

      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']

      //Get 

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getAuthorizationCodesForGroup"
      const data = {payloadSub: payloadSub, groupName: org}
      // console.log(JSON.stringify(data))
      // console.log("Printing url...")
      // console.log(url)
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      const authorizationCodesList = await response.json()
      // console.log(authorizationCodesList)
      return authorizationCodesList
    }
    catch(err){

    }
  }

  async function checkIfDataIsActivated(){
    try{

      // console.log('checkIfDataIsActivated in progress!')
      const jwtData = await Auth.currentSession();

      // console.log('Auth checked!')
      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']

      await getLocalDateTimeString()

      //Get 

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfUtilityDataIsActivated"
      
      //Get list of authorization codes for groupName from dynamodb
      const authorizationCodesList = await getAuthorizationCodesForGroup()
      let activatedStatus = false
      
      //Iterate through each authCode to check if data is activated
      if(authorizationCodesList!==undefined && authorizationCodesList!==null){
        //Check if there is failed response
        if(authorizationCodesList.includes("Failed")){
          return;
        }
        for(let i=0;i<authorizationCodesList.length;i++){
          const authorization = authorizationCodesList[i]
          const data = {authorization: authorization, payloadSub: payloadSub, groupName: org}
          const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
              "Authorization": "Bearer " + jwtToken,
              "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
          const result = await response.json()
          
          //Validate if result contains success. If yes, activatedStatus is true
          if(result.includes("Success")){
            activatedStatus = true

            //Redirect to Pending page
            // console.log("printing localDateTIme..")
            // console.log(localDateTime)
            navigate('/dataPagePending',{state: {prevPage: 'dataPage'}})
            return;
          }
        }
        // console.log('activatedStatus in checkIfDataIsActivated: ' + activatedStatus)
        if(activatedStatus){
          setActivatedDataStatus(activatedStatus)
        }
      }

      
      // console.log('checkIfDataIsActivated is completed.')
      return;
      
    }
    catch(err){
      throw err
    }
  }

  React.useEffect(() => {

    async function checkIfDataIsActivated(){
      try{

        // console.log('checkIfDataIsActivated in progress!')
        const jwtData = await Auth.currentSession();

        // console.log('Auth checked!')
        //Get groupName based on org
        const org = jwtData.idToken.payload['custom:organization']

        await getLocalDateTimeString()

        //Get 

        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;

        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfUtilityDataIsActivated"
        
        //Get list of authorization codes for groupName from dynamodb
        const authorizationCodesList = await getAuthorizationCodesForGroup()
        let activatedStatus = false
        
        //Iterate through each authCode to check if data is activated
        if(authorizationCodesList!==undefined && authorizationCodesList!==null){
          //Check if there is failed response
          if(authorizationCodesList.includes("Failed")){
            return;
          }
          for(let i=0;i<authorizationCodesList.length;i++){
            const authorization = authorizationCodesList[i]
            const data = {authorization: authorization, payloadSub: payloadSub, groupName: org}
            const response = await fetch(url, {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              headers: {
                "Authorization": "Bearer " + jwtToken,
                "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
                "Content-Type": "application/json"
              },
              body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            const result = await response.json()
            
            //Validate if result contains success. If yes, activatedStatus is true
            if(result.includes("Success")){
              activatedStatus = true
  
              //Redirect to Pending page
              // console.log("printing localDateTIme..")
              // console.log(localDateTime)
              navigate('/dataPagePending',{state: {prevPage: 'dataPage'}})

              break
            }
          }
          // console.log('activatedStatus in checkIfDataIsActivated: ' + activatedStatus)
          if(activatedStatus){
            setActivatedDataStatus(activatedStatus)
          }
        }

        
        // console.log('checkIfDataIsActivated is completed.')
        return;
        
      }
      catch(err){
        throw err
      }
    }

    checkIfDataIsActivated()

  }, [activatedDataStatus])
  
  return (
    <Flex
      gap="71px"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="38px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "EnableDataPage")}
      {...rest}
    >
      <Image
        width="270px"
        height="170px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src = "../../eaIcon.png"
        {...getOverrideProps(overrides, "EA LOGO 1")}
      ></Image>
      <View
        width="640px"
        height="140px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 427")}
      >
        <Text
          fontFamily="Inter"
          fontSize="19px"
          fontWeight="700"
          color="rgba(42,63,131,1)"
          lineHeight="22.99431800842285px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="You have successfully registered! "
          {...getOverrideProps(overrides, "You have successfully registered!")}
        ></Text>
        <Text
          fontFamily="Inter"
          fontSize="19px"
          fontWeight="400"
          color="rgba(42,63,131,1)"
          lineHeight="22.99431800842285px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="50px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Check on the status of your energy data using the button below. &#xA;This process may take up to 24 hours. If your energy data is not connected within 24 hours, please contact your Energy Audit Representative. "
          {...getOverrideProps(
            overrides,
            "Check on the status of your energy data using the button below. This process may take up to 24 hours. If your energy data is not connected within 24 hours, please contact your Energy Audit Representative."
          )}
        ></Text>
      </View>
      <View
        width="640px"
        height="134px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 428")}
      >
        <Button
          width="222px"
          height="unset"
          position="absolute"
          top="0px"
          left="209px"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Check data status"
          onClick={() => checkIfDataIsActivated()}
          {...getOverrideProps(overrides, "Button")}
        ></Button>
        <Text
          fontFamily="Inter"
          fontSize="10px"
          fontWeight="400"
          color="rgba(42,63,131,1)"
          lineHeight="12.102272033691406px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="640px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="50px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={"Last check: " + localDateTime}
          // {...getOverrideProps(overrides, "Last check: " + localDateTime)}
        ></Text>
      </View>
    </Flex>
  );
}
