/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, View, Button, Link } from "@aws-amplify/ui-react";
export default function SceAuthorizePage(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="640px"
      height="482px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      borderRadius="4px"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "SceAuthorizePage")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="222px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top="419px"
        left="209px"
        borderRadius="4px"
        padding="10px 10px 10px 10px"
        backgroundColor="rgba(63,125,74,1)"
        // {...getOverrideProps(overrides, "Frame 440")}
      >
        
        <Link href={process.env.REACT_APP_SANDBOX_STATUS==="true" ? process.env.REACT_APP_SANDBOX_SCE_CALLBACK_URL : process.env.REACT_APP_PRODUCTION_SCE_CALLBACK_URL}>
        <Button
          fontSize="16px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Authorize SCE Account"
          // onClick={() => window.open('https://test3.dms.sce.com/mysce/datasharing/authorizeavendor?thirdPartyId=SQXTGU4EVZSJVD', '_blank')}
          // {...getOverrideProps(overrides, "Authorize SCE Account")}
        ></Button>
        </Link>
        
      </Flex>
      <Image
        width="270px"
        height="58px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="300px"
        left="185px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="../../SCEImage.png"
        {...getOverrideProps(overrides, "SCEImage 1")}
      ></Image>
    </View>
  );
}
