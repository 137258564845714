/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  SelectField,
  Text,
  TextField,
  View,
  Input,
  Expander,
  ExpanderItem
} from "@aws-amplify/ui-react";
import { MultiSelect } from "react-multi-select-component";
import Chart from "react-google-charts";
export default function PeakDemandReport(props) {
  const { overrides, ...rest } = props;


  const [groupName, setGroupName] = React.useState([]);
  const [organization, setOrganization] = React.useState('');
  const [meterId, setMeterId] = React.useState([]);
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [threshold, setThreshold] = React.useState('');

  const [enableOrganization, setEnableOrganization] = React.useState(false);
  const [enableMeterId, setEnableMeterId] = React.useState(false);
  const [enableStartDate, setEnableStartDate] = React.useState(false);
  const [enableEndDate, setEnableEndDate] = React.useState(false);
  const [enableThreshold, setEnableThreshold] = React.useState(false);
  const [showDownloadButton, setShowDownloadButton] = React.useState(false);
  const [buttonVariation, setButtonVariation] = React.useState("primary");


  const [organizationError, setOrganizationError] = React.useState("");
  const [meterIdError, setMeterIdError] = React.useState("");
  const [startDateError, setStartDateError] = React.useState("");
  const [endDateError, setEndDateError] = React.useState("");
  const [thresholdError, setThresholdError] = React.useState("");
  const [meterIds, setMeterIds] = React.useState([]);
  const [runEnabled, setRunEnabled] = React.useState(false)



  React.useEffect(() => {
    fetchOrganization()
  }, []);

  async function fetchOrganization() {
    const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getAllGroups";
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        "Content-Type": "application/json"
      },
    })
    if (response.ok) {
      const result = await response.json();
      setGroupName(result.sort((a, b) => a.name.localeCompare(b.name)));
    } else {
      console.error('Request failed with status:', response.status);
    }
  }


  async function getMeterIds(groupName) {
    if (groupName !== '') {
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getMeterIds?groupName=" + groupName;
      const response = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
      })
      if (response.ok) {
        const result = await response.json();
        let resultData = result.map((item) => {
          return { label: item, value: item };
       });
        setMeterIds(resultData);
      } else {
        console.error('Request failed with status:', response.status);
      }
    }

  }

  const onClickRun = () => {

    let processError = false
    if (organization === undefined || organization === "") {
      processError = true
      setOrganizationError("Please provide organization name")
    }
    else {
      setOrganizationError(false)
    }

    if (meterId.length === 0) {
      processError = true
      setMeterIdError("Please provide meter Id")
    }
    else {
      setMeterIdError(false)
    }

    if (startDate === undefined || startDate === "") {
      processError = true
      setStartDateError("Please provide Start Time")
    }
    else {
      setStartDateError(false)
    }

    if (endDate === undefined || endDate === "") {
      processError = true
      setEndDateError("Please provide End Time")
    }
    else {
      setEndDateError(false)
    }

    if (threshold === undefined || threshold === "") {
      processError = true
      setThresholdError("Please provide Target")
    }
    else {
      if (/^[+]?\d*\.?\d+$/.test(threshold)) {
        setThresholdError(false)
      } else {
        processError = true
        setThresholdError('Please enter a positive number');
      }

    }

    if (!processError) {
      setEnableOrganization(true);
      setEnableMeterId(true);
      setEnableStartDate(true);
      setEnableEndDate(true);
      setEnableThreshold(true);
      setShowDownloadButton(true);
      setRunEnabled(true)
      setButtonVariation("default");

    } else {

    }

  }

  const data = [
    ["Hour", "min & max", "average", "standard deviation", "target overnight usge"],
    ["19", 80, 50, 20, 10],
    ["20", 80, 50, 20, 10],
    ["21", 75, 45, 18, 10],
    ["22", 70, 40, 18, 10],
  ];

  const options = {
    //isStacked: "relative",
    hAxis: { title: "Hour" },
    //chartArea: { height: "90%" },
    legend: { position: "top", maxLines: 2 },
    vAxis: {
      title: "net_kWh",
      minValue: 0,
      //ticks: [0, 2.0, 4.0, 6.0, 8.8],
    },
   // colors: ["#ff5733", "#66a103", "#2196F3", "#9C27B0"],
    series: {
      0: { lineDashStyle: [0] }, 
      1: { lineDashStyle: [0] },  
      2: { lineDashStyle: [0] }, 
      3: { lineDashStyle: [2, 2]}, 
    },
  };


  return (
    <View
      width="500px"
      height="557px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "PeakDemandReport")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="24px 24px 24px 24px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Create Profile")}
        >
          <Text
            fontSize="25px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Peak Demand Charge"
            {...getOverrideProps(overrides, "Peak Demand Charge")}
          ></Text>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider44811544")}
        ></Divider>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <SelectField
            width="unset"
            height="unset"
            label="Select Organization"
            shrink="0"
            alignSelf="stretch"
            placeholder=""
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            value={organization}
            hasError={organizationError}
            errorMessage={organizationError}
            onChange={(e) => {
              setOrganization(e.target.value);
              getMeterIds(e.target.value)
            }
            }
            {...getOverrideProps(overrides, "SelectField44811313")}
          >
            <option value="all">All</option>
            {groupName.map((object, index) => (
              <option key={index} value={object.name}>
                {object.name}
              </option>
            ))}
          </SelectField>
          <Flex
            gap="4px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Forms")}
          >
            <Text
              fontSize="14px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Select Meters"
              {...getOverrideProps(overrides, "Select Meters")}
            ></Text>
            <MultiSelect className="rmsc1 amplify-select__wrapper"
              options={meterIds}
              value={meterId}
              hasError={meterIdError}
              errorMessage={meterIdError}
              onChange={(e) => {
                setMeterId(e);
              }
              }
              labelledBy="Select Meters"
              disabled={false}
            />
            {meterIdError && (
              <Text className="amplify-text amplify-field__error-message"
              >
                {meterIdError}
              </Text>
            )}
            {/*  <SelectField
            width="unset"
            height="unset"
            label="Select Meters"
            shrink="0"
            alignSelf="stretch"
            placeholder=""
            size="small"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            value={meterId}
            hasError={meterIdError}
            errorMessage={meterIdError}
            onChange={(e) => {
              setMeterId(e.target.value);
            }
            }
            {...getOverrideProps(overrides, "SelectField44811547")}
          >
            <option value="all">All</option>
            {groupName.map((object, index) => (
              <option key={index} value={object.name}>
                {object.name}
              </option>
            ))}
          </SelectField> */}
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="100%"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 420")}
          >
            <TextField
              type="date"
              width="unset"
              height="unset"
              label="Start Date"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              placeholder=""
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              value={startDate}
              hasError={startDateError}
              errorMessage={startDateError}
              onChange={(e) => {
                setStartDate(e.target.value);
              }
              }
              {...getOverrideProps(overrides, "SelectField44811549")}
            ></TextField>
            <TextField
              type="date"
              width="unset"
              height="unset"
              label="End Date"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              placeholder=""
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              value={endDate}
              hasError={endDateError}
              errorMessage={endDateError}
              onChange={(e) => {
                setEndDate(e.target.value);
              }
              }
              {...getOverrideProps(overrides, "SelectField44811550")}
            ></TextField>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            label="Anomaly Peak Removal Threshold"
            descriptiveText="Defined as the number of occurrences a specific anomaly must occur to be removed. This should be a positive integer."
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            value={threshold}
            hasError={thresholdError}
            errorMessage={thresholdError}
            onChange={(e) => {
              setThreshold(e.target.value);
            }
            }
            {...getOverrideProps(overrides, "TextField")}
          ></TextField>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider44811552")}
        ></Divider>
        <Flex
          gap="100px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 424")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation={buttonVariation}
            children="Run"
            onClick={onClickRun}
            {...getOverrideProps(overrides, "Button43213888")}
          ></Button>
          {showDownloadButton && (<Button
            width="unset"
            height="unset"
            gap="100px"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="link"
            children="Download Report"
            {...getOverrideProps(overrides, "Button43213889")}
          ></Button>
          )}
        </Flex>
        <Flex className={runEnabled ? 'boxDisplay' : 'boxNone'}
          gap="100px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Chart")}
        >
          <Expander type="multiple" style={{ margin: "0", padding: "0" }}>
            <ExpanderItem title="Demand Cost as percentage of usage bill" value="average-overnight-energy-usage-target">
              <Chart
                height="400px"
                width='500px'
                chartType="AreaChart"
                data={data}
                options={options}
              />
            </ExpanderItem>
            <ExpanderItem title="Historical Demand Pricing" value="overnight-energy-usage-last-year">
              <Chart
                height="400px"
                width='500px'
                chartType="AreaChart"
                data={data}
                options={options}
              />
            </ExpanderItem>
            <ExpanderItem title="Interval Energy usage Frequency" value="average-daily-usage-profile">
              <Chart
                height="400px"
                width='500px'
                chartType="AreaChart"
                data={data}
                options={options}
              />
            </ExpanderItem>
            <ExpanderItem title="Peak Removal Target Threshold" value="average-daily-usage-profile">
              <Chart
                height="400px"
                width='500px'
                chartType="AreaChart"
                data={data}
                options={options}
              />
            </ExpanderItem>
            <ExpanderItem title="Top Peak Demand Period" value="average-daily-usage-profile">
              <Chart
                height="400px"
                width='500px'
                chartType="AreaChart"
                data={data}
                options={options}
              />
            </ExpanderItem>
          </Expander>
        </Flex>
      </Flex>
    </View>
  );
}
