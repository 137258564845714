/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import Dashboard from "./Dashboard";
import {useSearchParams} from "react-router-dom";
import { Flex, Text} from "@aws-amplify/ui-react";
import axios from 'axios';
// import fs from 'fs';

// import {}
export default function RedirectPage(props) {
  const { overrides, ...rest } = props;

  //Get url params
  const [searchParams, setSearchParams] = useSearchParams()
  const baseAccessTokenUrl = 'https://energydatasharest.sce.com:443/OAuthServices/oauth20/token'

  const params = []

  for(let entry of searchParams.entries()){
    params.push(entry)
  }

  let code = ""

  //Iterate through params and check for code
  //Use code to retrieve access token via Oauth redirect
  for(let i=0;i<params.length;i++){
    const currParam = params[i]
    const key = currParam[0]
    const value = currParam[1]
    if(key==='code'){
      code = value
    }
  }

  if(code!==""){
    //Perform axios request
    console.log("Performing Axios request...")
    // generateAccessToken()

  }

  // function generateAccessToken(){
  //   try{

  //   let cert_file = fs.readFileSync("../../certificate.crt")
  //   let ca_file = fs.readFileSync("./../ca_bundle.crt")
  //   const agent = new https.Agent({
  //       requestCert: true,
  //       rejectUnauthorized: true, // not for production
  //       cert: cert_file,
  //       ca: ca_file
  //   });

  //     axios.request({
  //       url: "/OAuthServices/oauth20/token",
  //       method: "post",
  //       httpsAgent: agent,
  //       baseURL: "https://energydatasharest.sce.com:443",
  //       auth: {
  //         username: "0oa1u4fgese0h22HK0h8", // This is the client_id
  //         password: "euqux_pRH3yLvc3T2q8tfsdHZyty2lZY_9O3gg5dJJOXFw_1P3FXuwuxyoGB52E9" // This is the client_secret
  //       },
  //       data: {
  //         "grant_type": "client_credentials",
  //         "scope": "/DataCustodian/espi/1_1/resource/Batch/Bulk/SQXTGU4EVZSJVD/"    
  //       }
  //     }).then(respose => {
  //       console.log("success!")
  //       console.log(respose);  
  //     }); 
  //     // axios.request({
  //     //   url: "/oauth/token",
  //     //   method: "post",
  //     //   baseURL: "http://sample.oauth.server.com/",
  //     //   auth: {
  //     //     username: "myUsername", // This is the client_id
  //     //     password: "myPassword" // This is the client_secret
  //     //   },
  //     //   data: {
  //     //     "grant_type": "client_credentials",
  //     //     "scope": "public"    
  //     //   }
  //     // }).then(respose => {
  //     //   console.log(respose);  
  //     // }); 
  //   }
  //   catch(err){
  //     console.log("error")
  //     throw err
  //   }
  // }

  // console.log(params)

  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "RedirectPage")}
      {...rest}
    >
          <Text
            fontSize="25px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="20px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Redirect Page"
          ></Text>

          <Text
            fontSize="15px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="20px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Url Parameters"
          ></Text>
          <ul>
            {params.map(([key,value]) => (
              <li key={key}>{key} - {value}</li>
            ))}
          </ul>
    </Flex>
  );
}
