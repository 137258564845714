/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button, Flex, Image, PasswordField, TextField, Loader,
  Divider, Link
} from "@aws-amplify/ui-react";
import { useNavigate, useLocation, json, Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
//import SignUp from "./SignUp";

export default function SignIn(props) {
  const location = useLocation();
  //Do protection of page by checking state
  if (location?.state !== undefined && location.state?.prevPage !== undefined) {
    if (location.state?.prevPage !== 'register') {
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else {
    //Undefined state
    return <Navigate replace to="/" />;
  }

  const [isPressed, setIsPressed] = React.useState(location.state.pressed);

  const { overrides, ...rest } = props;
  const [error, setErrors] = React.useState({
    email: false,
    password: false
  });
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [signinError, setSigninError] = React.useState(false);
  const [loaderStyle, setLoaderStyle] = React.useState("none")
  const [loadingStatus, setLoadingStatus] = React.useState(false);
  const [userDisabledError, setUserDisabledError] = React.useState(false);

  const errorMessage = signinError ? "Invalid email/password. Please type a correct email/password" 
                     : userDisabledError ? "Your account is disabled. Please contact support."
                     : "Please type a valid email";

  let navigate = useNavigate();

  async function goToLoadDataPage() {
    navigate('/dataPage', { state: { prevPage: 'signin' } })
    return;
  }

  async function getAuthorizationCodesForGroup() {
    try {
      const jwtData = await Auth.currentSession();

      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']
      if (org === "Energy Audit") {
        localStorage.setItem("admin", true)
      }


      //Get 

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getAuthorizationCodesForGroup"
      const data = { payloadSub: payloadSub, groupName: org }
      // console.log(JSON.stringify(data))
      // console.log("Printing url...")
      // console.log(url)
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      const authorizationCodesList = await response.json()
      // console.log(authorizationCodesList)
      return authorizationCodesList
    }
    catch (err) {

    }
  }

  async function checkIfDataIsActivated() {
    try {

      console.log('checkIfDataIsActivated in progress!')
      const jwtData = await Auth.currentSession();

      // console.log('Auth checked!')
      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']

      //Get 

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfUtilityDataIsActivated"

      let activatedStatus = false
      const data = { payloadSub: payloadSub, groupName: org }
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      const result = await response.json()

      //Validate if result contains success. If yes, activatedStatus is true
      if (result.includes("Success")) {
        activatedStatus = true
      }
      //console.log('activatedStatus in checkIfDataIsActivated: ' + activatedStatus)
      //console.log('checkIfDataIsActivated is completed.')
      return activatedStatus;

    }
    catch (err) {
      throw err
    }
  }

  async function forgetPassword() {
    try {
      const newErrorsState = { ...error };
      //Validate email
      if (email === '' || (email !== '' && !email.includes('@'))) {
        newErrorsState.email = true
      }
      else {
        newErrorsState.email = false
      }
      setErrors(newErrorsState);
      if (newErrorsState.email) {
        return
      }
      const forgetPasswordResult = await Auth.forgotPassword(email)
      // console.log(forgetPasswordResult)
      navigate('/verifyAccount', { state: { prevPage: 'signin', email: email } })
      return;
    }
    catch (err) {
      console.log(err.toString())
      setSigninError(true)
    }
  }

  async function validateInputs() {
    const newErrorsState = { ...error };
    let validInputs = true;
    //Validate email
    if (email === '' || (email !== '' && !email.includes('@'))) {
      validInputs = false
      newErrorsState.email = true
    }
    else {
      newErrorsState.email = false
      localStorage.setItem("email", email);
    }
    //Validate password
    if (password === '') {
      validInputs = false
      newErrorsState.password = true
    }
    else {
      newErrorsState.password = false
    }
    //Validate form
    if (validInputs) {
      //Check username/password
      try {
        setLoaderStyle("block")
        setLoadingStatus(true)
        //const currentUser = await Auth.currentUserCredentials()
        const user = await Auth.signIn(email, password);
        // console.log("Logging user...")
        // console.log(user);
        setLoaderStyle("none")
        setLoadingStatus(false)

        //Get organization name to see it if is energy audit. If yes, go straight to dashboard
        const jwtData = await Auth.currentSession();
        const org = jwtData.idToken.payload['custom:organization']
        if (org === "Energy Audit") {
          // console.log("Navigating to dashboard")
          //Navigate to dashboard since user is standard user instead of demo user
          getOrganizationByUtility() //Update utility if not available
          navigate("/dashboard", { state: { prevPage: 'signin' } });
          localStorage.setItem("admin", true)
          return;
        }
        else {

          //Check if user has registered. Do this by getting authorization codes for group
          const authorizationCodesList = await getAuthorizationCodesForGroup()
          // console.log("First authorizationCodesList done!")
          //If list is blank or failed, redirect to authform 
          if (authorizationCodesList === undefined || authorizationCodesList === null) {
            //Redirect to select utility page
            navigate('/selectUtility', { state: { prevPage: 'signin' } })
            return;
          }

          //Check if there is failed response
          if (authorizationCodesList.includes("Failed")) {
            //Redirect to select utility page
            navigate('/selectUtility', { state: { prevPage: 'signin' } })
            return;
          }


          // console.log("Going to check demoUserStatus...")
          //Check if user is demoUser or standardUser. If user is demoUser, redirect to dataPageSuccessDemo
          const demoUserStatus = await getDemoUserStatus()
          // console.log("demoUserStatus: " + demoUserStatus)
          if (demoUserStatus) {
            //Decide which landing page to land on - ActivatedData page or dataPagePending page
            const activatedStatus = await checkIfDataIsActivated()
            if (!activatedStatus) {
              //Navigate to activatedData page
              navigate("/dataPage", { state: { prevPage: 'signin' } });
              return;
            }
            else {
              //Navigate to importedData page - dataPagePending
              navigate("/dataPagePending", { state: { prevPage: 'signin' } });
              return;
            }
          }
          else {
            getOrganizationByUtility() //Update utility if not available
            // console.log("Navigating to dashboard")
            //Navigate to dashboard since user is standard user instead of demo user
            navigate("/dashboard", { state: { prevPage: 'signin' } });
            return;
          }
        }



      } catch (error) {
        console.log("error signing in", error);
        // console.error('Error signing in:', error);
        if (error.code === 'NotAuthorizedException' && error.message === 'Incorrect username or password.') {
          try {
            const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getNewUserDetail?userName=" + email
            const response = await fetch(url, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              mode: "cors", // no-cors, *cors, same-origin
              headers: {
                "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
                "Content-Type": "application/json"
              },
            })
            if (response.ok) {
              const result = await response.json();
              console.log('Success:', result);
              if (result.UserStatus === "FORCE_CHANGE_PASSWORD") {
                navigate('/signUp', { state: { prevPage: 'signin', email: email } })
              }
              else {
                //Incorrect password for registered user
                setSigninError(true);
                setLoaderStyle("none")
                setLoadingStatus(false)
              }
            } else {
              const result = await response.json();
              // console.log(result)
              if (result?.error && result.error === "No User found") {
                //Incorrect account credentials
                setSigninError(true);
                setLoaderStyle("none")
                setLoadingStatus(false)
              }
              else {
                console.error('Request failed with status:', response.status);
              }

            }
          }
          catch (err) {
            console.log(err)
            setSigninError(true);
            setLoaderStyle("none")
            setLoadingStatus(false)
          }


        } else if (error.code === 'UserNotConfirmedException' && error.message === 'User is not confirmed.') {

          navigate('/verifyAccount', { state: { prevPage: 'register2', email: email } });
          return;
        } else if (error.code === 'NotAuthorizedException' && error.message === 'User is disabled.') {
          setSigninError(false);
          setUserDisabledError(true)
          setLoaderStyle("none")
          setLoadingStatus(false)
        } else {
          setSigninError(true);
          setLoaderStyle("none")
          setLoadingStatus(false)
        }

      }
    } else {
      setErrors(newErrorsState);
    }
  }



  async function getDemoUserStatus() {
    try {
      const jwtData = await Auth.currentSession();

      //Get groupName based on org
      const org = jwtData.idToken.payload['custom:organization']

      //Get token attributes

      const jwtToken = jwtData.idToken.jwtToken;
      const payloadSub = jwtData.idToken.payload.sub;
      const emailSub = jwtData.idToken.payload.email;

      //Get Authorizations
      const authorizationCodesList = await getAuthorizationCodesForGroup()
      if (!Array.isArray(authorizationCodesList)) {
        console.log(authorizationCodesList) //Print error message
        return
      }
      // console.log(authorizationCodesList)

      let demoUserStatus = true
      //As long one true demoUser is found, return that status
      for (let i = 0; i < authorizationCodesList.length; i++) {
        const authorization = authorizationCodesList[i]
        // console.log("authorization: " + authorization)
        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDemoUserStatusInGroupAuthorizations"
        const data = { authorization: authorization, payloadSub: payloadSub, groupName: org }
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const demoUserResultStr = await response.json()
        // console.log(demoUserResultStr)

        if (!demoUserResultStr.includes("demoUser status is demoUser:")) {
          console.log(demoUserResultStr)
        }
        else {
          //Update result by spliting first :
          const strArray = demoUserResultStr.split(":")
          const forIndex = strArray[1].indexOf("for")
          const result = strArray[1].substring(0, forIndex).trim()
          // console.log(result)
          demoUserStatus = (result === 'true') //Convert string to boolean
          //As long one true demoUser is found, return that status
          if (demoUserStatus) {
            return demoUserStatus
          }
        }
      }

      return demoUserStatus
    }
    catch (err) {
      throw err
    }
  }

  async function getOrganizationByUtility() {
      const jwtData = await Auth.currentSession();
      const jwtToken = jwtData.idToken.jwtToken;
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/updateOrganizationUtility";
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ "groupName": 'all' }) // body data type must match "Content-Type" header
      })
      if (response.ok) {
        const result = await response.json();
      } else {
        setAdminOrgResponse([]);
        console.error('Request failed with status:', response.status);
      }

  }



  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="100px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "SignIn")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Profile")}
        >
          <Image
            width="287px"
            height="180px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="../../eaIcon.png"
          ></Image>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(245,245,245,1)"
          borderRadius="5px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 28")}
        >
          <Button
            width="unset"
            height="unset"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={isPressed}
            variation="primary"
            children="Sign In"
            color="grey"
            style={{ backgroundColor: isPressed === true ? "#EFF0F0" : "white" }}
            onClick={() => setIsPressed(!isPressed)}
            {...getOverrideProps(overrides, "Button38452618")}
          ></Button>
          <Button
            width="unset"
            height="unset"
            grow="1"
            shrink="1"
            basis="0"
            color="grey"
            style={{ backgroundColor: isPressed === false ? "#EFF0F0" : "white" }}
            size="large"
            isDisabled={!isPressed}
            variation="primary"
            children="Register"
            onClick={() => {
              setIsPressed(!isPressed);
              navigate('/');
            }}
            {...getOverrideProps(overrides, "Button38452621")}
          ></Button>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <TextField
            width="unset"
            height="unset"
            label="Email"
            placeholder="Email"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={loadingStatus}
            labelHidden={false}
            variation="default"
            hasError={error.email || signinError || userDisabledError}
            errorMessage={
              signinError
                ? "Invalid email/password. Please type a correct email/password"
                : userDisabledError
                  ? "Your account is disabled. Please contact support."
                  : "Please type a valid email"
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            {...getOverrideProps(overrides, "TextField38452584")}
          ></TextField>
          <PasswordField
            width="unset"
            height="unset"
            label="Password"
            placeholder="Password"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={loadingStatus}
            labelHidden={false}
            variation="default"
            hasError={error.password || signinError || userDisabledError}
            errorMessage={
              signinError
                ? "Invalid email/password. Please type a correct email/password"
                : userDisabledError
                  ? "Your account is disabled. Please contact support."
                  : "Please type a valid email"
            }
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></PasswordField>
        </Flex>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Link
            onClick={forgetPassword}
            textDecoration="underline"
            fontFamily="Inter"
            fontStyle="italic"
            to="/"
          >
            Forget your password?
          </Link>
          <Link
            href="/privacy_policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
            textDecoration="underline"
            fontFamily="Inter"
            fontStyle="italic"
          >
            Privacy Policy
          </Link>
        </Flex>

        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 419")}
        >
          {/* <Divider
            width="unset"
            height="22px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38452876")}
          ></Divider> */}
          <Button
            width="222px"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={loadingStatus}
            variation="primary"
            children="Save"
            backgroundColor="#3F7D4A"
            onClick={validateInputs}
            {...getOverrideProps(overrides, "Button38452586")}
          ></Button>
          <Loader
            size="large"
            variation="linear"
            filledColor="green"
            display={loaderStyle}
          />
        </Flex>
      
      </Flex>
      {/* <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <SignUp/>
        </Flex>*/}
    </Flex >
  );
}