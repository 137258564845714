/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { useSearchParams } from "react-router-dom";
import AuthForm from "./AuthForm";
import { Flex, Text, View, Button, TextField } from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";
import { useEffect } from 'react';

export default function AuthForm2(props) {
  const { overrides, ...rest } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const referral = searchParams.get("referral")
  const domain = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DOMAIN_DEV_MODE : process.env.REACT_APP_DOMAIN_PRO_MODE)

  useEffect(() => {
    window.parent.postMessage(
      {
        type: "message",
        message: referral
      },
      domain + "/authForm"
    );
  })

  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "AuthForm2")}
      {...rest}
    >
      {/* <Text>
        {referral}
      </Text> */}
    </Flex>
  );
}
