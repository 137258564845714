/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  SearchField,
  SelectField,
  Text, Table, TableHead, TableRow, TableCell, TableBody, Alert
} from "@aws-amplify/ui-react";

import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Header from "./DashboardBar"
import SideBar from "./SideBar5";
import '../my-components-css/Dashboard.css'
import '../App.css'
import EditOrganizationProfile from "./EditOrganizationProfile";
import AddDomain from "./AddDomain";
export default function AdminOrganization(props) {
  const { overrides, ...rest } = props;
  const location = useLocation();
  const [date, setDate] = React.useState("")
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  //Weather
  const [temperature, setTemperature] = React.useState("")
  const [windSpeed, setWindSpeed] = React.useState("")
  const [precipitation, setPrecipitation] = React.useState("")
  const [positionTitle, setPositionTitle] = React.useState("");
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  //Sidebar
  const [sidebarOpen, setSidebarOpen] = React.useState(true)
  //Button statuses
  let organizationStr = ""
  if (location.state?.organization !== undefined && location.state?.organization) {
    organizationStr = location.state.organization
  }
  const [organization, setOrganization] = React.useState(organizationStr)
  const [showAlert, setShowAlert] = React.useState(false);

  const [orgManagement, setOrgManagement] = React.useState(false)
  const [userManagement, setUserManagement] = React.useState(false)
  const [groupName, setGroupName] = React.useState([])
  const [adminOrgResponse, setAdminOrgResponse] = React.useState([]);

  const [orgProfile, setOrgProfile] = React.useState(true);
  const [editOrgProfile, setEditOrgProfile] = React.useState(false);

  const [editRowData, setEditRowData] = React.useState({});
  const [domainByOrganization, setDomainByOrganization] = React.useState({});

  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedOrganization, setSelectedOrganization] = React.useState('all');
  const [currentPage, setCurrentPage] = React.useState(0);
  const [updatedOrganization, setUpdatedOrganization] = React.useState(false);

  const [addDomain, setAddDomain] = React.useState(false);
  const [showAddDomainAlert, setAddDomainShowAlert] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);
  const [cellStyle, setCellStyle] = React.useState({});



  //Do protection of page by checking state
  // console.log("Printing location for dashboard...")
  // console.log(location)
  if (location?.state !== undefined && location.state?.prevPage !== undefined) {
    if (location.state?.prevPage !== 'signin' && location.state?.prevPage !== 'dashboard' && location.state?.prevPage !== 'settings') {
      //Did not come from register prevPage
      return <Navigate replace to="/" />;
    }
  }
  else {
    //Undefined state
    return <Navigate replace to="/" />;
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function toggleSideBar() {
    // console.log("Togglesidebar in progress..")
    // console.log("Converting " + sidebarOpen + " to " + !sidebarOpen) 
    setSidebarOpen(prev => !prev)
  }

  async function toggleDashboardOne() {
    //Upon landing in dashboard page, show just dashboard one
    navigate("/dashboard", { state: { prevPage: 'settings', dashboardTwo: false, organization: organization } })
    return;
  }

  async function toggleDashboardTwo() {
    //Upon landing in dashboard page, show just dashboard two
    navigate("/dashboard", { state: { prevPage: 'settings', dashboardTwo: true, organization: organization } })
    return;
  }

  async function goToSettingsPage() {
    navigate('/settings', { state: { prevPage: 'dashboard' } })
  }

  async function fetchOrganization() {
    const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getAllGroupAuthorizations";
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        "Content-Type": "application/json"
      },
    })
    if (response.ok) {
      const result = await response.json();
      const sortedData = result.sort((a, b) => a.GroupName.S.localeCompare(b.GroupName.S));
      setGroupName(sortedData);
      onChangeOrganization()
    } else {
      console.error('Request failed with status:', response.status);
    }
  }

  React.useEffect(() => {
    async function getQuickSightDashboardUrl() {
      try {
        // console.log("Generating equicksight url...")
        const jwtData = await Auth.currentSession();

        //Temporary fix : Check if user is goodwill or energyaudit user. If no, exit function
        const org = jwtData.idToken.payload['custom:organization']
        if (org !== "Energy Audit" && org !== "Goodwill") {
          setLoaderStyle("none")
          if (org === "Energy Audit") {
            localStorage.setItem("admin", true)
          }
          return;
        }

        const jwtToken = jwtData.idToken.jwtToken;
        const payloadSub = jwtData.idToken.payload.sub;
        const emailSub = jwtData.idToken.payload.email;

        const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getEmbedUrl"
        const data = { email: emailSub, payloadSub: payloadSub }
        // console.log(JSON.stringify(data))
        // console.log("Printing url...")
        // console.log(url)
        const response = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          headers: {
            "Authorization": "Bearer " + jwtToken,
            "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        const result = await response.json()
        // console.log(result)
        // console.log(result.Status)
        if (result.length === 2 && result[0].Status === 200 && result[1].Status === 200) {
          // console.log("good status")
          const embedUrl = result[0].EmbedUrl
          const embedUrl2 = result[1].EmbedUrl
          setUrl(embedUrl)
          setUrl2(embedUrl2)
          await sleep(2000)
          setLoaderStyle("none")
        }
        else {
          console.log("Something went wrong with QuickSight Url Retrieval..")
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    async function getPosition(options) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
      );
    }

    async function getUserLocation() {
      try {
        if ("geolocation" in navigator) {
          // console.log("Available")
          const position = await getPosition()
          // console.log(position)
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          setLatitude(latitude => latitude)
          setLongitude(longitude => longitude)
          // console.log("Latitude: " + latitude)
          // console.log("Logitude: " + longitude)
          await getCurrentWeatherInUserLocation(latitude, longitude)

        }
        else {
          // console.log("Not available")
          throw new Error("Location not available")
        }
      }
      catch (err) {
        console.log(err)
        //Assume user rejected or browser rejects geolation
        await getCurrentWeatherInUserLocation("", "")
      }
    }

    async function getCurrentWeatherInUserLocation(latitudeStr, longitudeStr) {
      try {
        const localStorage_temp = localStorage.getItem("temperature")
        const localStorage_windspeed = localStorage.getItem("windspeed")
        const localStorage_precipitation = localStorage.getItem("precipitation")
        if (localStorage_temp === null || localStorage_windspeed === null || localStorage_precipitation === null
          || localStorage_temp === "" || localStorage_windspeed === "" || localStorage_precipitation === "") {

          //Default location is los angeles
          const location = "Los Angeles"
          let url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + location
          // console.log("getCurrentWeatherInUserLocation latitude" + latitudeStr)
          //Check if user provided coordinates. If no, use default location
          if (latitudeStr !== "" && longitudeStr !== "") {
            url = "https://api.weatherapi.com/v1/current.json?key=" + process.env.REACT_APP_WEATHER_API_KEY + "&q=" + latitudeStr + "," + longitudeStr
          }
          // console.log("Url: " + url)
          const response = await fetch(url, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          const result = await response.json()
          let temperature = ""
          let windSpeed = ""
          let precipitation = ""
          if (result !== null && result.current !== undefined) {
            //Temperature
            if (result.current.feelslike_f !== undefined) {
              temperature = result.current.feelslike_f + "o"
            }
            //Wind speed
            if (result.current.wind_mph !== undefined) {
              windSpeed = "SSW " + result.current.wind_mph + "mph"
            }
            //Precipitation
            if (result.current.precip_in !== undefined) {
              precipitation = result.current.precip_in + "%"
            }
          }
          setTemperature(temperature)
          setWindSpeed(windSpeed)
          setPrecipitation(precipitation)

        }
        else {
          setTemperature(localStorage_temp)
          setWindSpeed(localStorage_windspeed)
          setPrecipitation(localStorage_precipitation)
        }

      }
      catch (err) {
        console.log(err)
      }
    }

    async function getTodayDate() {
      try {
        const date = new Date()
        //Get Day
        const daysList = ["Sun.", "Mon.", "Tue.", "Wed.", "Thur.", "Fri.", "Sat."]
        const day = daysList[date.getDay()]
        //Get Month
        const monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const month = monthsList[date.getMonth()]
        //Get Date day
        const dateDay = date.getDate()
        //Get Year
        const year = date.getFullYear()

        const dateString = day + " " + month + " " + dateDay + ", " + year
        setDate(dateString)
      }
      catch (err) {
        console.log(err)
      }
    }

    async function getPersonalName() {
      try {
        // const userInfo = await Auth.currentUserInfo()
        // const name = userInfo.attributes.name
        // setName(name)
        // const email = userInfo.attributes.email
        // setEmail(email)

        const user = await Auth.currentAuthenticatedUser();
        // console.log("printing user..")
        // console.log(user)
        const name = user.attributes.name
        setName(name)
        const email = user.attributes.email
        setEmail(email)

      }
      catch (err) {
        console.log(err)
      }
    }

    async function getUserInfo() {
      const user = await Auth.currentAuthenticatedUser();
      // console.log(user)
      // const name = user.attributes.name
      // const email = user.attributes.email
      let org = ""
      if ('custom:organization' in user.attributes) {
        org = user.attributes['custom:organization']
      }
      const title = user.attributes['custom:title']
      setOrganization(org)
      setPositionTitle(title);
      if (org === "Energy Audit") {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    getTodayDate()
    getPersonalName()
    getUserLocation()
    getUserInfo()
    fetchOrganization()
    // getQuickSightDashboardUrl()
    // getCurrentWeatherInUserLocation()
  }, [setAdminOrgResponse]);

  async function onChangeOrganization() {
    //let selectedOrg = e.target.value;
    if (selectedOrganization !== "") {
      const jwtData = await Auth.currentSession();

      const jwtToken = jwtData.idToken.jwtToken;
      const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getOrganizationByName";
      const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ "groupName": selectedOrganization }) // body data type must match "Content-Type" header
      })
      if (response.ok) {
        const result = await response.json();

        const formattedDataPromises = result.map(async (item) => {
          if (item.dailyUploadTimestamp) {
            const formattedTimestamp = await formatDateTimeAsync(item.dailyUploadTimestamp);
            return { ...item, dailyUploadTimestamp: formattedTimestamp };
          } else {
            return item; 
          }
        });
        const formattedData = await Promise.all(formattedDataPromises);
        const sortedData = formattedData.sort((a, b) => a.name.localeCompare(b.name));

        setAdminOrgResponse(sortedData);

      } else {
        setAdminOrgResponse([]);
        console.error('Request failed with status:', response.status);
      }
    } else {
      setAdminOrgResponse([]);
    }

  }

  function onEditOrgProfile(organization) {
    setUpdatedOrganization(organization)
    setShowAlert(true)
    setEditOrgProfile(false);
    setOrgProfile(true);
    setAddDomain(false);
    setAddDomainShowAlert(false)
  }
  function onClickCancel() {
    setEditOrgProfile(false)
    setEditOrgProfile(false);
    setOrgProfile(true);
    setAddDomain(false);

  }

  async function handleUpdateClick(rowData) {

    getDomainByName(rowData.name, rowData.authorization, rowData.dataImportTimestamp)
    //setEditRowData(rowData)
    //setDomainByOrganization(domainByOrg)
  }

  function handleDeleteClick(rowData) {

  }


  async function getDomainByName(orgName, authorization, dataImportTimestamp) {
    const user = await Auth.currentAuthenticatedUser();
    const jwtData = await Auth.currentSession();
    const jwtToken = jwtData.idToken.jwtToken;
    const url = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/getDomainByName"
    const data = {
      domain: orgName,
    }
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Authorization": "Bearer " + jwtToken,
        "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    if (response.ok) {
      let result = await response.json();
      if (result !== "Domain not found") {
        const dataIsActivated = await checkIfUtilityDataIsActivated(orgName)
        const dataIsImported = await checkIfDataIsImported(orgName);
        result.activatedStatus = dataIsActivated;
        result.importedStatus = dataIsImported;
        result.dataImportByAdminTimeStamp = await getDataImportByAdminTimeStamp(dataImportTimestamp);
        result.dataImportTimestamp = dataImportTimestamp;
      } else {
        result = {
          noData: 'Domain not found',
          organization: orgName
        }
        //setEditRowData(result.organization = orgName);
      }
      setEditOrgProfile(true);
      setEditRowData(result);
      setAddDomain(false);
      setOrgProfile(false);
      setAddDomainShowAlert(false);
      setShowAlert(false)

    } else {
      console.error('Request failed with status:', response.status);
    }
  }

  async function checkIfUtilityDataIsActivated(orgName) {
    try {

      const checkIfUtilityDataIsActivated = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfUtilityDataIsActivated"
      const data = { groupName: orgName }
      const dataIsActivatedResponse = await fetch(checkIfUtilityDataIsActivated, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          //"Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      const dataIsActivatedResult = await dataIsActivatedResponse.json()
      if (dataIsActivatedResult.includes("Success")) {
        return true;
      } else {
        return false;
      }
    }
    catch (err) {
      throw err
    }
  }

  async function checkIfDataIsImported(orgName) {
    try {

      const checkIfDataIsImported = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BACKEND_DOMAIN_DEV_MODE : process.env.REACT_APP_SANDBOX_STATUS === "true" ? process.env.REACT_APP_BACKEND_DOMAIN_SANDBOX_MODE : process.env.REACT_APP_BACKEND_DOMAIN_PRO_MODE) + "/checkIfDataIsImported";

      const data = { groupName: orgName }
      const dataIsImportedResponse = await fetch(checkIfDataIsImported, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          //"Authorization": "Bearer " + jwtToken,
          "token": process.env.REACT_APP_ENERGYAUDIT_BACKEND_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      const dataIsImportedResult = await dataIsImportedResponse.json()
      //Validate if result contains success. If yes, importedStatus is true
      if (dataIsImportedResult.includes("Success")) {
        return true;
      } else {
        return false;
      }
      //}
    }
    catch (err) {
      throw err
    }
  }

  async function getDataImportByAdminTimeStamp(dataImportTimestamp) {
    try {
      let isMoreThan24HoursOld = false;

      if (dataImportTimestamp !== undefined && dataImportTimestamp !== "") {
        const dateObject = new Date(dataImportTimestamp);
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
      
        isMoreThan24HoursOld = dateObject > twentyFourHoursAgo
      }

      return isMoreThan24HoursOld;
    }
    catch (err) {
      throw err
    }
  }

  async function formatDateTimeAsync(dateTimestamp) {
    const dateObject = new Date(dateTimestamp);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/Los_Angeles',
    };

    const formattedDateTime = dateObject.toLocaleString('en-US', options);
    return formattedDateTime.replace(' at ', ' ');
  }


  const filteredOrgList = adminOrgResponse.filter((row) => {
    const name = row.name.toLowerCase();
    const searchTermLowerCase = searchTerm.toLowerCase();

    return name.includes(searchTermLowerCase);
  });

  const ITEMS_PER_PAGE = 5;

  const totalItems = filteredOrgList.length;
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const paginatedData = filteredOrgList.slice(startIndex, endIndex);


  let navigate = useNavigate();

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  async function clickAddDomain() {
    setAddDomain(true)
    setEditOrgProfile(false);
    setOrgProfile(false);
    setAddDomainShowAlert(false);
    setShowAlert(false)
  }

  async function clickSaveDomain() {
    //Retrieve updated user attributes (name, title) from Cognito
    //const user = await Auth.currentAuthenticatedUser();
    setAddDomainShowAlert(true);
    setAddDomain(false);
    setEditOrgProfile(false);
    setOrgProfile(true);

  }

  async function clickCancel() {
    //setAddDomain(false)
    setAddDomainShowAlert(false);
    setAddDomain(false);
    setEditOrgProfile(false);
    setOrgProfile(true);
  }

  const getLastDataImportTextColor = (dailyUploadTimestamp) => {
    if (dailyUploadTimestamp !== undefined) {
      const dateObject = new Date(dailyUploadTimestamp);
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      // Compare the date to see if it's older than 7 days
      const isOlderThan7Days = dateObject < sevenDaysAgo;

      // Return the color based on the condition
      return isOlderThan7Days ? 'red' : '#0d1926';
    }
  }

  return (
    <Flex
      gap="0"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      // alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
    >
      <Flex direction="row">
        <Flex className={sidebarOpen ? 'sideBarDisplay' : 'sideBarNone'}>
          <SideBar
            height="992px"
            toggleDashboardOne={toggleDashboardOne}
            toggleDashboardTwo={toggleDashboardTwo}
            goToSettingsPage={goToSettingsPage}
            organization={organization}
          ></SideBar>
        </Flex>

        <Flex
          direction="column"
          width="100%"
          padding="20px 20px 20px 20px"
        >
          <Header
            date={date}
            name={name}
            email={email}
            temperature={temperature}
            windSpeed={windSpeed}
            precipitation={precipitation}
            toggleSideBar={toggleSideBar}>
          </Header>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "AdminOrganization")}
            {...rest}
          >
            <Flex className={orgProfile ? 'boxDisplay' : 'boxNone'}>
              <Flex
                gap="14px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="24px 24px 24px 24px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Content")}
              >
                <Flex
                  gap="16px"
                  direction="row"
                  width="unset"
                  height="unset"
                  justifyContent="flex-start"
                  alignItems="center"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Organization Managment41413042")}
                >
                  <Text

                    fontSize="25px"
                    fontWeight="700"
                    color="rgba(13,26,38,1)"
                    lineHeight="20px"
                    textAlign="left"
                    display="block"
                    direction="column"
                    justifyContent="unset"
                    width="unset"
                    height="unset"
                    gap="unset"
                    alignItems="unset"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Organization Management "
                    {...getOverrideProps(overrides, "Organization Managment41413043")}
                  ></Text>
                </Flex>
                <Divider
                  width="unset"
                  height="1px"
                  shrink="0"
                  alignSelf="stretch"
                  size="small"
                  orientation="horizontal"
                  {...getOverrideProps(overrides, "Divider41413044")}
                ></Divider>
                <Flex
                  gap="40px"
                  direction="row"
                  width="100%"
                  height="unset"
                  justifyContent="left"
                  alignItems="flex-end"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 28")}
                >
                  {/*  <SelectField
                    width="300px"
                    height="unset"
                    label="Organization"
                    shrink="0"
                    //placeholder="Select Organization"
                    size="small"
                    isDisabled={false}
                    labelHidden={false}
                    variation="default"
                    value={selectedOrganization}
                    onChange={(e) => {
                      setSelectedOrganization(e.target.value);
                      onChangeOrganization(e)
                    }
                    }
                    {...getOverrideProps(overrides, "SelectField41423084")}
                  >
                    <option value="all">All</option>
                    {groupName.map((object, index) => (
                      <option key={index} value={object.name}>
                        {object.name}
                      </option>
                    ))}
                  </SelectField>
                  <SelectField
                    width="300px"
                    height="unset"
                    label="Department"
                    shrink="0"
                    placeholder="Select Department"
                    size="small"
                    isDisabled={false}
                    labelHidden={false}
                    variation="default"
                    {...getOverrideProps(overrides, "SelectField41423093")}
                  ></SelectField>
                  <Button
                    width="unset"
                    height="unset"
                    shrink="0"
                    size="default"
                    isDisabled={false}
                    variation="primary"
                    children="Create Organization"
                    {...getOverrideProps(overrides, "Button")}
                    ></Button>*/}
                  <SearchField
                    width="300px"
                    height="unset"
                    placeholder="Search"
                    shrink="0"
                    size="small"
                    isDisabled={false}
                    labelHidden={true}
                    variation="default"
                    onChange={(event) => setSearchTerm(event.target.value)}
                    onClear={() => setSearchTerm('')}
                    {...getOverrideProps(overrides, "SearchField")}
                  ></SearchField>
                  <Flex
                    gap="40px"
                    direction="row"
                    width="60%"
                    height="unset"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Add Organization")}
                  >
                    <Button style={{ display: isVisible ? "block" : "none" }}
                      width="unset"
                      height="unset"
                      justifyContent="flex-start"
                      shrink="0"
                      size="small"
                      isDisabled={false}
                      variation="primary"
                      children="Add Organization"
                      onClick={clickAddDomain}
                      {...getOverrideProps(overrides, "Button40792891")}
                    ></Button>
                  </Flex>

                </Flex>
                <Divider
                  width="unset"
                  height="1px"
                  shrink="0"
                  alignSelf="stretch"
                  size="small"
                  orientation="horizontal"
                  {...getOverrideProps(overrides, "Divider41423880")}
                ></Divider>
                {showAlert && (
                  <Alert
                    width="unset"
                    alignSelf="stretch"
                    variation="success" heading="Success"
                    isDismissible={true}
                    onDismiss={() => setShowAlert(false)}
                  >
                    Details for {updatedOrganization} has been updated
                  </Alert>
                )}
                {showAddDomainAlert && (
                  <Alert
                    width="unset"
                    alignSelf="stretch"
                    variation="success" heading="Success"
                    isDismissible={true}
                    onDismiss={() => setAddDomainShowAlert(false)}
                  >
                    Organization Added successful
                  </Alert>
                )}
                <Flex
                  gap="10px"
                  direction="column"
                  width="unset"
                  height="unset"
                  justifyContent="center"
                  alignItems="flex-end"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 28")}
                >
                  <Table title="Table" size="small" style={{ width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell as="th" style={{ width: '250px' }}>NAME</TableCell>
                        <TableCell as="th" style={{ width: '100px' }}>UTILITY</TableCell>
                        <TableCell as="th" style={{ width: '120px' }}>ACCOUNT TYPE</TableCell>
                        <TableCell as="th" style={{ width: '150px' }}>DATA IMPORTED</TableCell>
                        <TableCell as="th" style={{ width: '150px' }}>DATA ACTIVATED</TableCell>
                        <TableCell as="th" style={{ width: '150px' }}>LAST DATA IMPORT</TableCell>
                        <TableCell as="th">ACTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ width: '250px' }}>{row.name}</TableCell>
                          <TableCell style={{ width: '100px' }}>{row.utilityActivation}</TableCell>
                          <TableCell style={{ width: '150px' }}>{row.demoUser ? "Demo" : "Standard"}</TableCell>
                          <TableCell style={{ width: '150px' }}>{row.importedStatus ? 'True' : "False"}</TableCell>
                          <TableCell style={{ width: '150px' }}>{row.activatedStatus ? 'True' : "False"}</TableCell>
                          <TableCell style={{ width: '150px', color: getLastDataImportTextColor(row.dailyUploadTimestamp) }}>{row.dailyUploadTimestamp}</TableCell>
                          <TableCell style={{ display: 'flex', gap: '10px' }}>
                            <Button width="unset"
                              height="unset"
                              justifyContent="flex-start"
                              shrink="0"
                              size="small"
                              isDisabled={false}
                              variation="primary" onClick={() => handleUpdateClick(row)}>Update</Button>
                            <Button width="unset"
                              height="unset"
                              justifyContent="flex-start"
                              shrink="0"
                              size="small"
                              isDisabled={false}
                              variation="secondary" onClick={() => handleDeleteClick(row)}>Delete</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Flex
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="space-between"
                    alignItems="center"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 5px 0px"
                    {...getOverrideProps(overrides, "Frame 432")}>
                    <Button
                      width="144px"
                      height="unset"
                      shrink="0"
                      size="default"
                      isDisabled={currentPage === 0}
                      justifyContent="flex-start"
                      paddingLeft="10px"
                      variation="link" onClick={handlePrevPage}>
                      Previous
                    </Button>
                    <span>{`Page ${currentPage + 1} of ${totalPages}`}</span>
                    <Button
                      width="144px"
                      height="unset"
                      shrink="0"
                      size="default"
                      justifyContent="flex-end"
                      paddingRight="10px"
                      isDisabled={currentPage === totalPages - 1}
                      variation="link" onClick={handleNextPage} >
                      Next
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex className={editOrgProfile ? 'boxDisplay' : 'boxNone'} >
            <EditOrganizationProfile
              editData={editRowData}
              onEditOrgProfile={onEditOrgProfile}
              onClickCancel={onClickCancel}
            ></EditOrganizationProfile>
          </Flex>

          <Flex className={addDomain ? 'boxDisplay' : 'boxNone'}>
            <AddDomain
              clickSaveDomain={clickSaveDomain}
              clickCancel={clickCancel}
            ></AddDomain>
          </Flex>
        </Flex>
      </Flex>
    </Flex >
  );
}
